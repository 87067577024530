export const environment = {
    production: false,
    isSharpMode: false,
    isDebugMode: true,
    apiUrl: "",
    // apiUrl: '//smd.dev.w5host.com',
    requestTimeout: 30000,
    facebookAppID: "2538630596466621",
    linkedIn: {
        // linkedInRedirectUri: "https://test.esemdev.com/linkedin",
        linkedInRedirectUri: "https://dev.esemdev.com/linkedin",
        clientId: "77dhj69xlyegcm",
    },
    tinyMcePluginFolder: "/themes/smd/src/assets/tinymce/plugins",
    enableHelpCenter: false,
    cache: {
        enabled: true,
        maxAge: 10 * 60 * 1000,
    },
};
