import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FILE_TYPES} from '~/src/app/components/file/types/fileTypes';
import {FileBrowserComponent, FileBrowserSelectionChangeTypes} from '../../file-browser/file-browser.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SmdFile, SmdFileInterface} from '../../../services/file.class';
import {CarouselComponent} from '../../../modules/posts/carousel/carousel.component';
import {CarouselService} from '../../../modules/posts/carousel/carousel.service';
import {
    CarouselItemRequestInterface,
    CarouselItemResponseInterface
} from '../../../modules/posts/carousel/carousel.interface';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogLoaderComponent} from '../../dialog-loader/dialog-loader.component';
import {DialogSuccessComponent} from '../../dialog-success/dialog-success.component';
import {DialogErrorComponent} from '../../dialog-error/dialog-error.component';
import {Debounce, MyErrorStateMatcher} from '../../../services/helpers';
import {FormValidationService} from '../../../services/form.validation.service';
import {LanguageService} from '../../../services/language.service';
import {FALLBACK_IMAGE} from '../../../configs/configs';
import {url} from '@ng-validators/ng-validators';
import {Configs} from '~/src/app/configs/configs';
import obj2fd from 'obj2fd';
import {NotifyService} from '~/src/app/services/notify.service';
import {PartnerConfigService} from '~/src/app/shared/services/partner-config/partner-config.service';
import {PartnerPermissions} from '~/src/app/shared/services/partner-config/partner-config.options';
import Utils from '~/src/app/core/utils';
import {PostTemplateSystemTypesForCreateTemplate} from '~/src/app/modules/social-media-post/social-media-post.options';
import {CoreConfig} from '~/src/app/core/core.config';
import {Partner} from '~/src/app/shared/types/partners.model';
import {PartnersService} from '~/src/app/modules/administration/partners/partners.service';
import {OpenModalService} from '~/src/app/modules/social-media-post/open-modal.service';
import {SOCIAL_MEDIA_TYPE_FACEBOOK, SOCIAL_MEDIA_TYPE_TWITTER} from '~/src/app/core/constants';

interface CarouselItem {
    file: SmdFileInterface;
    formControlNames: {
        title: string,
        description?: string,
        url?: string,
        image?: string
    };
    index: number;
    socialChannels?: Array<string>;
}

@Component({
    selector: 'smd-create-carousel',
    templateUrl: './create-carousel.component.html',
    styleUrls: ['./create-carousel.component.scss']
})
export class CreateCarouselComponent extends CarouselComponent implements OnInit {
    readonly minCarouselItem: number = 2;
    @ViewChild(FileBrowserComponent, {static: true}) fileBrowser: FileBrowserComponent;
    @ViewChild('carouselForm', {static: true}) carouselForm: ElementRef<HTMLFormElement>;
    @Output('close') close: EventEmitter<any> = new EventEmitter<any>();
    @Input() isSocialChannelDisabled = false;
    @Input() defaultTypeDisabled;
    @Input('carousel') set setCurrentCarousel(value) {
        if (!this.currentCarousel && value) {
            this.currentCarousel = value;
        }
    }
    @Input() isAdminMode = false;

    carouselFormGroup: FormGroup;
    message: object = {};
    stateMatcher = new MyErrorStateMatcher();
    carouselItems: CarouselItem[] = [];
    carouselItemsCounter = 0;
    _loader: MatDialogRef<DialogLoaderComponent>;

    // When edit carousel
    private _currentCarousel: SmdFileInterface;
    editMode = false;
    // socialChannels = [...Configs.socials].filter(item => item.id == SOCIAL_MEDIA_TYPE_FACEBOOK); // Only facebook. It is also preselected below (there is a comment).    
    socialChannels = [...Configs.socials]; // Only facebook. It is also preselected below (there is a comment).    
    hasMediaTag = false;
    hasMediaCategory = false;
    partners: Partner[] = [];

    constructor(
        carouselService: CarouselService,
        public _dialog: MatDialog,
        public languageService: LanguageService,
        public partnerService: PartnersService,
        private partnerConfig: PartnerConfigService,
        private openModal: OpenModalService
    ) {
        super(carouselService);

        this.hasMediaTag = this.partnerConfig.hasConfig(PartnerPermissions.MediaTag);
        this.hasMediaCategory = this.partnerConfig.hasConfig(PartnerPermissions.MediaCategory);

        // this.initCarouselForm();

        this.carouselFormGroup = new FormGroup({
            name: new FormControl('', [
                Validators.required,
                Validators.minLength(3),
            ]),
            url: new FormControl('', [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(255),
                url
            ]),
            category: new FormControl(null),
            tags: new FormControl(null),
            systemType: new FormControl(null),
            organizationIDs: new FormControl([]),
            partnerIDs: new FormControl({value: [], disabled: true}),
            carouselNum: new FormControl(0, [
                Validators.min(this.minCarouselItem)
            ]),
            // socialChannel: new FormControl({disabled: (this.isSocialChannelDisabled), value: [SOCIAL_MEDIA_TYPE_FACEBOOK] }) // Facebook is preselected here!
            socialChannel: new FormControl({disabled: (this.isSocialChannelDisabled), value: [] }) // Facebook is preselected here!
        });

        this.partnerService.partners.subscribe(partners => this.partners = partners);
        this.partnerService.getPartners().then(() => {
            if (!!this.currentCarousel) {
                this.carouselFormGroup.get('partnerIDs').setValue(this.currentCarousel.partnerIDs || []);
            }
        });
    }

    ngOnInit() {

        if (this.isAdminMode) {
            this.carouselFormGroup.get('systemType').setValidators(Validators.required);
            this.carouselFormGroup.get('systemType').updateValueAndValidity({emitEvent: true});

            this.carouselFormGroup.get('systemType').valueChanges.subscribe((value) => {
                if (value === CoreConfig.getSystemTypes().Branded) {
                    this.carouselFormGroup.get('partnerIDs').setValidators(Validators.required);
                    this.carouselFormGroup.get('partnerIDs').enable();
                } else {
                    this.carouselFormGroup.get('partnerIDs').clearValidators();
                    this.carouselFormGroup.get('partnerIDs').disable();
                }

                this.carouselFormGroup.get('partnerIDs').updateValueAndValidity({emitEvent: true});
            });
        }

        if (!!this.currentCarousel) {
            this.initEditMode();
        }


        const isEditMode = (this.currentCarousel);

        /**
         * The left side carouselItems has no socialChannel(s), that is why we need the following lines
         */
        if (!isEditMode) {
            this.socialChannelValidate();
        }
    }

    initEditMode() {
        const files: SmdFileInterface[] = [];
        this.editMode = true;

        const defaultControlValues = {
            name: this.currentCarousel.name,
            url: this.currentCarousel.url,
            socialChannel: this.currentCarousel.socialChannels,
            category: this.currentCarousel.categories[0] ? this.currentCarousel.categories[0].name : null,
            tags: this.currentCarousel.tags,
            organizationIDs: this.currentCarousel.organizationIDs,
            carouselNum: 0
        };

        if (this.isAdminMode) {
            defaultControlValues['systemType'] = this.currentCarousel.systemType;
        }

        for (const controlName in defaultControlValues) {
            const value = defaultControlValues[controlName];

            this.carouselFormGroup.get(controlName).setValue(value);
        }

        this.currentCarousel.carouselItems.forEach(carouselItem => {
            const carouselFile = new SmdFile({
                name: this.currentCarousel.name,
                mediaID: carouselItem.imageFileID,
                url: carouselItem.imageUrl,
                mime: 'image/jpeg',
                type: FILE_TYPES.CAROUSEL,
                createDate: this.currentCarousel.createDate,
                socialChannel: carouselItem.socialChannels,
            });

            carouselFile.socialChannels = this.carouselFormGroup.get('socialChannel').value;

            files.push(carouselFile);

            this.addCarouselItem(carouselFile, carouselItem);
        });

        this.fileBrowser.setSelectedFiles(files);
        this.fileBrowser.service.selectedSocialTypesInCarouselCreateOrEdit =
            this.currentCarousel.socialChannels as Array<'facebook' | 'linkedIn'>;
    }

    saveCarousel = (callback: () => void = () => {}) => {
        const invalidItem = this.carouselItems.find(item => {
            return item['hasSocialChannelProblem'] === true;
        });

        if (invalidItem) {
            const title = this.languageService.getLine('carousel.error.social.channels.title');
            const message = this.languageService.getLine('carousel.error.social.channels.message');

            NotifyService.error(
                title,
                message
            );

            return;
        }

        this.carouselFormValidation();

        if (this.carouselFormGroup.invalid) {
            // Set the fields to red mark
            Object.keys(this.carouselFormGroup.controls).forEach(field => {
                const control = this.carouselFormGroup.get(field);

                control.markAsTouched({ onlySelf: true });
            });

            return;
        }

        if (this.editMode) {
            this.editCarousel();

            return;
        }

        this._loader = this.openModal.loader(DialogLoaderComponent);

        this._loader.afterClosed().subscribe(({isSuccess, response}: {isSuccess: boolean, response: any}) => {


            if (isSuccess) {
                this._dialog.open(DialogSuccessComponent, {
                    data: {
                        message: LanguageService.getLine('create.carousel.success')
                    }
                }).afterClosed().subscribe(() => {
                    this.close.emit(response);
                });
            } else {
                this._dialog.open(DialogErrorComponent, {
                    data: {
                        message: FormValidationService.readError(response).message || LanguageService.getLine('create.carousel.fail')
                    }
                });
            }
        });

        this.createItem(
            Utils.obj2fd(this.requestData)
        );
    }

    editCarousel() {
        const _loader = this.openModal.loader(DialogLoaderComponent);

        _loader.afterClosed().subscribe(({isSuccess, response}) => {
            if (isSuccess) {
                this.close.emit(true);
            } else {
                this._dialog.open(DialogErrorComponent, {
                    data: {
                        message: FormValidationService.readError(response).message || LanguageService.getLine('edit.carousel.fail')
                    }
                });
                FormValidationService.setFormControlsIncorrect(response, this.carouselFormGroup, this.message);
                this.message = FormValidationService.readError(response).formMessages;
            }
        });

        this.editItemWithPost(this.currentCarousel.mediaID, obj2fd(this.requestData), response => {
            _loader.close({
                isSuccess: true,
                response: response
            });
        }, error => {
            _loader.close({
                isSuccess: false,
                response: error
            });
        });
    }

    successCreate(response: any): void {
        super.successCreate(response);

        this._loader.close({
            isSuccess: true,
            response: response
        });

    }

    failedCreate(error: any): void {
        super.failedCreate(error, false);

        FormValidationService.setFormControlsIncorrect(error, this.carouselFormGroup, this.message);
        this.message = FormValidationService.readError(error).formMessages;

        this._loader.close({
            isSuccess: false,
            response: error
        });
    }

    carouselItemsChange(
        {selectedFiled, file, eventType}:
            { selectedFiled: SmdFileInterface[], file: SmdFileInterface, eventType: 'setSelectedFiles' | 'selectFile' }
    ) {

        if (eventType === FileBrowserSelectionChangeTypes.SetSelectedFiles) {
            return;
        }

        const index = this.fileIndexInCarousel(file);

        if (index === -1) {
            this.addCarouselItem(file);
        } else {
            this.removeCarouselItem(file);
        }
    }

    addCarouselItem(file: SmdFileInterface, carouselItem?: CarouselItemResponseInterface) {
        const index = this.fileIndexInCarousel(file);
        const isEditMode = (this.currentCarousel);

        if (index === -1) {
            this.carouselItemsCounter++;

            const titleControlName = `carouselItemTitle${this.carouselItemsCounter}`,
                descriptionControlName = `carouselItemDescription${this.carouselItemsCounter}`,
                urlControlName = `carouselItemUrl${this.carouselItemsCounter}`,
                imageControlName = `carouselItemImage${this.carouselItemsCounter}`;

            if (isEditMode) {
                // file.socialChannels = this.carouselFormGroup.get('socialChannel').value;
            }

            this.carouselItems.push({
                file: file,
                formControlNames: {
                    title: titleControlName,
                    description: descriptionControlName,
                    url: urlControlName,
                    image: imageControlName
                },
                index: this.carouselItemsCounter
            });

            this.carouselFormGroup.addControl(titleControlName, new FormControl(carouselItem ? carouselItem.name : '', [
                Validators.required,
                Validators.minLength(3)
            ]));
            this.carouselFormGroup.addControl(descriptionControlName, new FormControl(carouselItem ? carouselItem.description : ''));
            this.carouselFormGroup.addControl(urlControlName, new FormControl(carouselItem ? carouselItem.url : '', [url]));
            this.carouselFormGroup.addControl(imageControlName, new FormControl(''));

            this.carouselItemNumControlValue = this.carouselItemNumControlValue + 1;
        }
    }

    removeCarouselItem(file: SmdFileInterface) {
        const index = this.fileIndexInCarousel(file);

        if (index > -1) {
            const carouselItem = this.carouselItems[index];

            this.carouselItems.splice(index, 1);

            this.carouselFormGroup.removeControl(carouselItem.formControlNames.title);
            this.carouselFormGroup.removeControl(carouselItem.formControlNames.description);
            this.carouselFormGroup.removeControl(carouselItem.formControlNames.url);

            this.carouselItemNumControlValue = this.carouselItemNumControlValue - 1;
        }
    }

    fileIndexInCarousel(file: SmdFileInterface): number {
        return this.carouselItems.findIndex(value => {
            return Number(value.file.mediaID) === Number(file.mediaID);
        });
    }

    carouselFormValidation() {
        this.message = {};

        if (this.carouselFormGroup.invalid) {
            this.message = FormValidationService.getMessages(this.carouselFormGroup.controls);
        }
    }

    /**
     * @description when you change socialChannel(s) dropdown or change the the list of carousel
     * items we need a  validations for items to validate the socialChannels This validation is in the
     * file-browser.component too for example in selectFile method
     */
    @Debounce(600)
    socialChannelValidate() {
        const socialChannels = this.carouselFormGroup.get('socialChannel').value;
        const items =  Utils.lodash.cloneDeep(this.carouselItems);

        this.fileBrowser.service.setItemsSocialChannelsHasInvalid(socialChannels, items);
    }

    validateInput(): void {
        if (!this.carouselFormGroup.valid) {
            this.message = FormValidationService.getMessages(this.carouselFormGroup.controls);
        }
    }

    private clearForm() {
        for (const controlName in this.carouselFormGroup.controls) {
            this.carouselFormGroup.get(controlName).setValue(null);
        }
    }

    get requestData() {
        const items: CarouselItemRequestInterface[] = [];
        const data: { url: string, name: string, items: CarouselItemRequestInterface[], socialChannels: Array<string>, organizationIDs: number[] } = {
                url: this.carouselFormGroup.get('url').value,
                name: this.carouselFormGroup.get('name').value,
                socialChannels: this.carouselFormGroup.get('socialChannel').value,
                items: [],
                organizationIDs: this.carouselFormGroup.get('organizationIDs').value
            },
            categories = this.carouselFormGroup.get('category').value,
            tags = this.carouselFormGroup.get('tags').value;

        if (this.isAdminMode) {
            const systemType: string = this.carouselFormGroup.get('systemType').value;
            const partnerIDs: number[] = this.carouselFormGroup.get('partnerIDs').value;

            if (!!systemType) {
                data['systemType'] = systemType;
            }

            if (!!partnerIDs && !!partnerIDs.length) {
                data['partnerIDs'] = partnerIDs;
            }
        }

        if (!!categories) {
            data['categories'] = categories;
        }

        if (!!tags && !!tags.length) {
            data['tags'] = tags;
        }

        for (const item of this.carouselItems) {
            items.push({
                name: this.carouselFormGroup.get(item.formControlNames.title).value,
                description: this.carouselFormGroup.get(item.formControlNames.description).value || '',
                url: this.carouselFormGroup.get(item.formControlNames.url).value || '',
                fileID: item.file.mediaID
            });
        }

        data.items = items;

        return data;
    }

    get itemNeedMoreNum() {
        return this.minCarouselItem - this.carouselItemNumControlValue;
    }

    set carouselItemNumControlValue(value: number) {
        this.carouselFormGroup.controls['carouselNum'].setValue(value);
    }

    get carouselItemNumControlValue(): number {
        return this.carouselFormGroup.controls['carouselNum'].value;
    }

    get currentCarousel(): SmdFileInterface {
        return this._currentCarousel;
    }

    set currentCarousel(value: SmdFileInterface) {
        this._currentCarousel = value;
    }

    get systemTypes() {
        return PostTemplateSystemTypesForCreateTemplate;
    }

    get allFileType() {
        return FILE_TYPES;
    }

    get fallbackImage() {
        return FALLBACK_IMAGE;
    }
}
