import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SocialSiteService} from './social-site.service';
import {MatSelect} from '@angular/material/select';
import {SocialSiteComponent} from './social-site.component';
import {FormControl} from '@angular/forms';
import {LanguageService} from '../../services/language.service';
import {Debounce, Helpers} from '~/src/app/services/helpers';
import {SocialChannels} from '~/src/app/modules/analytics/widget/widget.interfaces';
import { socialSiteAppearance } from '../../modules/social-media-post/social-media-platforms-config';

export interface SocialSiteInterface {
    name: string;
    siteID: number;
    socialType?: SocialChannels;
    pageID?: string;
    timezone: string;
    pageType: string;
    organizationID?: string;
    pageAccessTokenExpire?: string;
    isExpireInFiveDays?: boolean;
    isGroup?: boolean;
    accessTokenIsExpired?: boolean;
    isRefreshableByCurrentUser?: boolean;
    tokenExpiredTooltipText?: string;
}

@Component({
    selector: 'smd-social-site-select',
    templateUrl: './social-site-select.component.html',
    styleUrls: ['./social-site-select.component.scss']
})
export class SocialSiteSelectComponent extends SocialSiteComponent implements OnInit {
    @ViewChild('socialSiteSelect', {static: true}) socialSiteSelect: MatSelect;
    @Output('selectionChange') selectionChange: EventEmitter<any> = new EventEmitter<any>();
    _organizationID: number | number[];
    inputValue: number;
    socialSelectFormControl = new FormControl({value: null, disabled: false});
    sitesGetting = false;
    @Input() socialPlatformId;
    @Input() multiple = true;
    @Input('loadedFrom') loadedFrom = null;

    @Input()
    set organizationID(value: number | number[]) {
        const removeOrganizationFilter = () => delete this.filters['organizationID'];

        if (value) {
            value = Array.isArray(value) ? value : [value];

            if (value.length) {
                this.filters['organizationID'] = value;
            } else {
                removeOrganizationFilter();
            }
        } else {
            removeOrganizationFilter();
        }

        this._organizationID = value;
        this.getSocialSites();

        //this.socialSites = this.socialSites.filter(item => socialSiteAppearance?.[item.id]?.[this.loadedFrom] !== false);
    }

    get organizationID(): number | number[] {
        return this._organizationID;
    }

    socialSites: SocialSiteInterface[] = [];

    constructor(
        public service: SocialSiteService
    ) {
        super(service);

        if (this.socialPlatformId) {
            this.filters['socialType'] = this.socialPlatformId;
        }
    }

    ngOnInit() {
        this.initSocialSites();
    }
    /**
     * @description for matSelects
     * @param dataArray
     * @param modelValueProperty
     */
    selectAll(dataArray, modelValueProperty) {
        const selectedValues = dataArray.map(data => data[modelValueProperty]);

        if (this.socialSiteSelect) {
            this.socialSiteSelect.writeValue(selectedValues);
            this.selectionChange.next({value: selectedValues});
        }
    }
    deselectAll() {
        this.socialSiteSelect.writeValue([]);
        this.selectionChange.next({value: []});
    }

    /**
     * Init social site component and get social sites
     */
    initSocialSites() {
        if (!this.organizationID) {
            this.getSocialSites();
        }
    }

    beforeGetItems(): void {
        super.beforeGetItems();
        this.sitesGetting = true;

        if (this.socialSiteSelect) {
            this.socialSiteSelect.setDisabledState(true);
        }
    }

    successGetItems(response: any): void {
        super.successGetItems(response);

        this.socialSites = Helpers.orderBy(response.socialSites, 'name');
        this.socialSites = this.socialSites.filter(item => socialSiteAppearance?.[item.socialType]?.[this.loadedFrom] !== false);
        
        if (this.socialSiteSelect.value) {
            const socialSiteIndexByCurrentValue = this.socialSites.findIndex(site => site.siteID === this.socialSiteSelect.value);

            if (socialSiteIndexByCurrentValue === -1) {
                this.socialSiteSelect.value = null;
            }
        }
    }

    afterGetItems(): void {
        super.afterGetItems();
        this.sitesGetting = false;

        if (this.socialSiteSelect) {
            this.socialSiteSelect.setDisabledState(false);
        }
    }

    public reSetItemsBySocialType(socialType: string) {
        if (socialType) {
            this.filters['socialType'] = socialType;
        } else {
            delete this.filters['socialType'];
        }

        this.selectionChange.next([]);
        this.getItems();
    }

    @Debounce()
    private getSocialSites() {
        this.getItems();
    }

    get languageService() {
        return LanguageService;
    }
}
