import { debounceTime } from "rxjs/operators";
import { Component, Inject, OnInit } from "@angular/core";
import { LanguageService } from "~/src/app/services/language.service";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { HttpClient } from "@angular/common/http";
import { Helpers } from "~/src/app/services/helpers";
import { BackendService } from "~/src/app/core/backend.service";
import { ComponentHelpers } from "~/src/app/core/services/component-helpers";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import { LoggedUser } from "~/src/app/services/logged-user";
import { FormControl } from "@angular/forms";
import Utils from "~/src/app/core/utils";

export interface NotificationEventType {
    type: string;
}

export interface ResourcesFromAction {
    commentOnUserEntity: string[];
    commentOnUserEntityAdmin: string[];
    partnerDeleteRequest: string;
    postApproval: string;
    postMessageFailed: string;
    postStatusChanged: string;
    subscriptionExpired: string;
    subscriptionExpiring: string;
    templateApproval: string;
    templateStatusChanged: string;
}

@Component({
    selector: "smd-email-notification-manager",
    templateUrl: "./email-notification-manager.component.html",
    styleUrls: ["./email-notification-manager.component.scss"],
    providers: [ComponentHelpers],
})
export class EmailNotificationManagerComponent implements OnInit {
    public events: any = {};
    public organizations: any[] = [];
    public notificationEvents: any[] = [];
    public organizationFilterKeyword = "";
    public organizationNamevalue = "";
    public isOrganizationFiltered: { [key: number]: boolean } = {};
    public notificationTypes: any[] = [
        "immediately",
        "daily",
        "weekly",
        "monthly",
    ];
    public days: any[] = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];
    public hours: any[] = [];
    public minutes: any[] = ["00", "30"];
    public daysOfMonth: any[] = [];
    public showLoader = true;

    public resourcesFromAction: ResourcesFromAction = {
        commentOnUserEntity: [
            "post.comment.comment",
            "post.comment.comments",
        ],
        commentOnUserEntityAdmin: [
            "post.comment.comment",
            "post.comment.comments",
        ],
        partnerDeleteRequest: "",
        postApproval: "post.post.approvement",
        postMessageFailed: "post.post.create",
        postStatusChanged: "post.post.statuses",
        subscriptionExpired: "",
        subscriptionExpiring: "",
        templateApproval: "post.template.bulkCreate",
        templateStatusChanged: "post.template.create",
    };

    organizationFilterFormControl: FormControl = new FormControl("");
    notificationFilterFormControl: FormControl = new FormControl("");

    constructor(
        private http: HttpClient,
        private backendService: BackendService,
        public language: LanguageService,
        private componentHelpers: ComponentHelpers,
        private openModal: OpenModalService,
        private dialogRef: MatDialogRef<EmailNotificationManagerComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}

    ngOnInit() {
        this.initIntervals();
        this.organizations = this.data.user.organizations.reduce(function (
            a,
            b
        ) {
            a[b.organizationID] = b.name;
            return a;
        },
        {}); // create an object with organizationID as key and name as value

        this.getEventTypes().then((response) => {
            this.notificationEvents = response;
            const types = this.buildEventTypeStructure(response);
            this.events = this.notificationSettings(types);
            this.showLoader = false;
        });

        this.isOrganizationFiltered = this.data.user.organizations.reduce(
            (a, b) => {
                a[b.organizationID] = false;
                return a;
            },
            {}
        );
        if (this.data.user.hasOwnProperty("organizationNamevalue")) {
            this.organizationNamevalue = this.data.user.organizationNamevalue;
        }
        this.organizationFilterFormControl.valueChanges
            .pipe(debounceTime(350))
            .subscribe((value) => {
                this.organizationFilterKeyword = value.toLowerCase().trim();
                for (const key in this.organizations) { // actually filter it
                    if (this.organizations.hasOwnProperty(key)) {
                        this.isOrganizationFiltered[key] = !this.filterOrganizationsByKeyword(
                            this.organizations[key]
                        );
                    }
                }
            });
    }

    /**
     * Get event types
     * */
    getEventTypes(): Promise<NotificationEventType[]> {
        return this.backendService.get<NotificationEventType[]>(
            "/users/notification-events",
            null
        );
    }

    /**
     * Toggle enabled state
     *
     * @params {string} state
     * */
    toggleEnabledState(state) {
        return state === "yes" ? "no" : "yes";
    }

    /**
     * Toggle in-app and email notifications' enabled state
     * @params organization
     * */
    toggleNotificationStates(organization) {
        this.events.emailNotification.organizationSettings[organization.key].enabled
            = this.toggleEnabledState(this.events.emailNotification.organizationSettings[organization.key].enabled);

        this.events.inAppNotification.organizationSettings[organization.key].enabled
            = this.toggleEnabledState(this.events.inAppNotification.organizationSettings[organization.key].enabled);
    }

    /**
     * Toggle In-app enabled state
     *
     * @params organization
     * @params action
     * */
    toggleInAppEnabledState(organization, action) {
        // check if action param is a string or a keyvalue object and toggle the value
        if (typeof action === "string") {
            this.events.inAppNotification.organizationSettings[organization.key].actions[action].enabled =
                this.toggleEnabledState(this.events.inAppNotification.organizationSettings[organization.key].actions[action].enabled
            );
        } else if (typeof action === "object") {
            this.events.inAppNotification.organizationSettings[organization.key].actions[action.key].enabled =
                this.toggleEnabledState(this.events.inAppNotification.organizationSettings[organization.key].actions[action.key].enabled
            );
        }
    }

    /**
     * Save changes to notification settings
     * */
    saveSettings(): void {
        this.componentHelpers.startApiAction(
            () => {
                this.data.user.settings.emailNotification = this.events.emailNotification;

                let tmpArray =
                    this.data.user.settings.emailNotification
                        .organizationSettings;
                this.data.user.settings.emailNotification.organizationSettings =
                    {};
                let tmpObj = {};

                if (tmpArray instanceof Array) {
                    tmpArray.forEach(function (value, index) {
                        tmpObj[index] = value;
                    });
                } else {
                    tmpObj = tmpArray;
                }

                this.data.user.settings.emailNotification.organizationSettings =
                    tmpObj;

                // set in-app notification settings
                this.data.user.settings.inAppNotification = this.events.inAppNotification;

                // remove interval settings from inAppNotification actions (we don't need them for in-app notifications)
                // organizationSettings is an object with organization keys as keys and an object with action keys as values
                Object.keys(this.data.user.settings.inAppNotification.organizationSettings).forEach(organizationKey => {
                    // iterate over the actions object
                    Object.keys(this.data.user.settings.inAppNotification.organizationSettings[organizationKey].actions).forEach(actionKey => {
                        // remove interval settings
                        delete this.data.user.settings.inAppNotification.organizationSettings[organizationKey].actions[actionKey].interval;
                    });
                });

                return this.postUserSettings({
                    settings: JSON.stringify(this.data.user.settings),
                });
            },
            {
                successMessageKey: "profile.settings.save.success.title",
                failedMessageKey: "profile.settings.save.fail.title",
                afterSuccessAction: (response: any) => {
                    LoggedUser.saveUser(this.data.user);
                },
            }
        );
    }

    /**
     * Update notification intervals based on type
     * @param event
     * @param {any} interval
     * */
    updateIntervals(event, interval: any) {
        const type = event.value;

        // set default values for selects
        interval["dayOfMonth"] = interval["dayOfMonth"]
            ? interval["dayOfMonth"]
            : this.daysOfMonth[0];
        interval["day"] = interval["day"] ? interval["day"] : this.days[0];
        interval["hour"] = interval["hour"] ? interval["hour"] : this.hours[0];
        interval["minute"] = interval["minute"]
            ? interval["minute"]
            : this.minutes[0];

        // update interval values based on type
        if (type === "weekly") {
            interval["dayOfMonth"] = null;
        } else if (type === "monthly") {
            interval["day"] = null;
        } else if (type === "daily") {
            interval["dayOfMonth"] = null;
            interval["day"] = null;
        } else {
            interval = {
                day: null,
                hour: null,
                minute: null,
                dayOfMonth: null,
            };
        }

        return interval;
    }

    filterOrganizationsByKeyword(name: string) {
        return name
            .toLowerCase()
            .trim()
            .includes(this.organizationFilterKeyword);
    }

    /**
     * Build notification settings
     *
     * @params eventTypes
     * */
    private notificationSettings(eventTypes: any) {
        // create emailNotification setting if not present
        if (!this.data.user.settings.hasOwnProperty("emailNotification")) {
            this.data.user.settings["emailNotification"] = {};
            this.data.user.settings.emailNotification["organizationSettings"] =
                {};
        }
        // create inAppNotification setting if not present
        if (!this.data.user.settings.hasOwnProperty("inAppNotification")) {
            this.data.user.settings["inAppNotification"] = {};
            this.data.user.settings.inAppNotification["organizationSettings"] =
                {};
        }

        const emailNotificationSettings = this.data.user.settings.emailNotification;
        const inAppNotificationSettings = this.data.user.settings.inAppNotification;

        // add missing organizations to notification settings
        this.data.user.organizations.forEach((organization) => {
            if (
                emailNotificationSettings.organizationSettings &&
                !emailNotificationSettings.organizationSettings.hasOwnProperty(
                    organization.organizationID
                )
            ) {
                emailNotificationSettings.organizationSettings[
                    organization.organizationID
                ] = {
                    // deep clone event types
                    actions: JSON.parse(JSON.stringify(eventTypes)),
                    enabled: "no",
                };
            }

            if (inAppNotificationSettings.organizationSettings
                && !inAppNotificationSettings.organizationSettings.hasOwnProperty(organization.organizationID)) {

                inAppNotificationSettings.organizationSettings[organization.organizationID] = {
                    // deep clone event types
                    actions: JSON.parse(JSON.stringify(eventTypes)),
                    enabled: "no",
                };
            }
        });
        if (this.organizationNamevalue) {
           
            Object.keys(emailNotificationSettings.organizationSettings).forEach(
                (organizationID) => {
                   
                    if (
                        organizationID == this.organizationNamevalue
                    ) {
                         emailNotificationSettings.organizationSettings[
                            organizationID
                         ];
                         this.data.user.organizations.forEach((item) => {
                       
                             if (item.organizationID == this.organizationNamevalue) {
                                 this.organizationFilterFormControl.setValue(item.name);
                             }
                        
                        });
                    } else {
                        delete emailNotificationSettings.organizationSettings[
                            organizationID
                        ];
                    }
                }
            );
        }
        // remove not existing organizations from notification settings (after deleting an org, for example)
        Object.keys(emailNotificationSettings.organizationSettings).forEach(
            (organizationID) => {
                if (
                    !this.data.user.organizations.find(
                        (organization) => organization.organizationID == organizationID
                    )
                ) {
                    delete emailNotificationSettings.organizationSettings[
                        organizationID
                    ];
                }
            }
        );

        Object.keys(inAppNotificationSettings.organizationSettings).forEach(
            (organizationID) => {
                if (
                    !this.data.user.organizations.find(
                        (organization) => organization.organizationID == organizationID
                    )
                ) {
                    delete inAppNotificationSettings.organizationSettings[
                        organizationID
                    ];
                }
            }
        );

        // add missing event types to organizations
        this.addMissingEventTypes(emailNotificationSettings, eventTypes);
        this.addMissingEventTypes(inAppNotificationSettings, eventTypes);

        return {
            emailNotification: emailNotificationSettings,
            inAppNotification: inAppNotificationSettings,
        };
    }

    private addMissingEventTypes(notificationSettings, eventTypes: any) {
        Object.keys(notificationSettings.organizationSettings).forEach(
            (setting) => {
                let actions =
                    notificationSettings.organizationSettings[setting].actions;

                actions = Object.keys(actions)
                    .filter((key) => this.notificationEvents.includes(key))
                    .reduce((obj, key) => {
                        obj[key] = actions[key];
                        return obj;
                    }, {});

                notificationSettings.organizationSettings[setting].actions =
                    Object.assign({}, eventTypes, actions);
            }
        );
    }

    /**
     * Build actions structure
     *
     * @params response
     * */
    private buildEventTypeStructure(response) {
        const types = {};
        response.forEach((item) => {
            types[item] = {
                enabled: "no",
                interval: {
                    day: null,
                    hour: null,
                    minute: null,
                    dayOfMonth: null,
                },
            };
        });

        return types;
    }

    /**
     * Post changes
     *
     * @params {any} data
     * */
    private postUserSettings(data: any): Promise<any> {
        return this.backendService.post(
            "/users/edit",
            Helpers.objectToFormData(data)
        );
    }

    /**
     * Initialize intervals for selects
     * */
    private initIntervals(): void {
        for (let i = 0; i <= 23; i++) {
            const hour = String(i).padStart(2, "0");
            this.hours.push(hour);
        }

        for (let i = 1; i <= 28; i++) {
            const day = String(i).padStart(2, "0");
            this.daysOfMonth.push(day);
        }
    }

    /**
     * Filter actions
     * */
    filterActions(actions) {
        return actions.filter(x => x.key != "systemNotifications");
    }
}
