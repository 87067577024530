import { AfterViewInit, Component, HostListener, Inject, ViewChild, ViewContainerRef } from '@angular/core';
import { LanguageService } from '~/src/app/services/language.service';
import { PartnersService } from '~/src/app/modules/administration/partners/partners.service';
import { Token } from '~/src/app/services/token';
import { AvailableLoaders, PreloaderComponent } from '~/src/app/components/preloader/preloader.component';
import { VERSION } from '~/src/environments/version';
import { CustomEvents } from '~/src/app/services/helpers';
import { LoggedUser } from '~/src/app/services/logged-user';
import * as moment from 'moment';
import { environment } from '~/src/environments/environment';
import Utils from '~/src/app/core/utils';
import { EmojiPickerService } from '~/src/app/shared/emoji-picker.service';
import { OpenModalService } from '../social-media-post/open-modal.service';
import { PostManagerComponent } from '../social-media-post/post-manager/post-manager.component';
import CommonPostHelpers from '../posts/common-post-helpers';
import { POST_SCHEDULE_DATE_DELAY_MINUTES } from '../social-media-post/social-media-post.constant';
import { BASE_DATETIME_FORMAT } from '../../configs/configs';

declare const $;

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements AfterViewInit {
    @ViewChild(PreloaderComponent) preLoader: PreloaderComponent;
    @ViewChild('rightSideBar', { read: ViewContainerRef }) rightSideBar: ViewContainerRef;
    version: string = VERSION.version;
    hash: string = VERSION.hash;
    user: object = {};
    token = '';

    // set role name
    roleName = '';

    constructor(
        private tokenObject: Token,
        private openModal: OpenModalService,
        public emojiPickerService: EmojiPickerService,
        @Inject(ViewContainerRef) public viewContainerRef: ViewContainerRef,
    ) {
        this.token = Token.getToken();
        this.tokenObject.startTimer();

        this._setRoleName();

        this.setUser();

        if (environment.enableHelpCenter) {
            this.addZohoHelpCenterScript();
        }
    }

    ngAfterViewInit() {
        this.baseSettings();
        this.setLayout();

        $(window).on('resize', this.baseSettings);

        this.preLoader.hide(AvailableLoaders.PAGE_LOADER);

        $(document).on('click', '.mega-' +
            'dropdown', function (e) {
                e.stopPropagation();
                e.preventDefault();
            });

        $(document).on('click', '.keep-open-on-click', function (e) {
            e.stopPropagation();
        });

        $(document).on('click', '.cdk-overlay-container', function (e) {
            e.stopPropagation();
            $(this).parents('.mega-dropdown').toggleClass('open');
        });

        $(document).on('click', function (e) {
            if (!$(e.target).parents('.mega-dropdown').length) {
                $(this).parents('.mega-dropdown').removeClass('show');
            }
        });
    }

    openQuickPost() {
        this.openModal.createPost(PostManagerComponent, {
            data: {
                scheduleDate: CommonPostHelpers.roundMinuteToFive(Utils.moment().add(POST_SCHEDULE_DATE_DELAY_MINUTES, 'minute').format(BASE_DATETIME_FORMAT)).dateTime
            }
        }).afterClosed()
            .subscribe((data) => {
                if (data) {
                    CustomEvents.postOrTemplateEdit(data);
                    if (data && data.length > 0 && data[0].createNewpost) {
                        // Call newPostSchedule if createNewpost is true
                        let postcreationtype = data[0].createNewpost;
                        if (postcreationtype === 'newPost') {
                            this.openQuickPost();
                            console.log('New Post selected');
                        } else if (postcreationtype === 'newPostFromTemplate') {
                            this.openQuickPostTemplate();
                        }

                    }
                }
            });
    }
    openQuickPostTemplate() {
        this.openModal.createPostFromTemplate(PostManagerComponent, {
            data: {
                scheduleDate: CommonPostHelpers.roundMinuteToFive(Utils.moment().add(POST_SCHEDULE_DATE_DELAY_MINUTES, 'minute').format(BASE_DATETIME_FORMAT)).dateTime
            }
        }).afterClosed()
            .subscribe((data) => {
                console.log('data', data);

                if (data) {
                    CustomEvents.postOrTemplateEdit(data);
                    if (data && data.length > 0 && data[0].createNewpost) {
                        // Call newPostSchedule if createNewpost is true
                        let postcreationtype = data[0].createNewpost;
                        if (postcreationtype === 'newPost') {
                            this.openQuickPost();
                            console.log('New Post selected');
                        } else if (postcreationtype === 'newPostFromTemplate') {
                            this.openQuickPostTemplate();
                        }
                    }
                }
            });
    }

    baseSettings = (): void => {
        const width = (window.innerWidth > 0) ? window.innerWidth : window.screen.width;
        const topOffset = 70;

        if (width < 1170) {
            $('body').addClass('mini-sidebar');
            $('.navbar-brand span').hide();
            $('.sidebartoggler i').addClass('ti-menu');
        } else {
            $('body').removeClass('mini-sidebar');
            $('.navbar-brand span').show();
        }

        let height = ((window.innerHeight > 0) ? window.innerHeight : window.screen.height) - 1;
        height = height - topOffset;
        if (height < 1) { height = 1; }
        if (height > topOffset) {
            $('.page-wrapper').css('min-height', (height) + 'px');
        }
    }

    setLayout(): void {
        $('.sidebartoggler').on('click', () => {
            if ($('body').hasClass('mini-sidebar')) {
                $('body').trigger('resize');
                $('body').removeClass('mini-sidebar');
                $('.navbar-brand span').show();
            } else {
                $('body').trigger('resize');
                $('body').addClass('mini-sidebar');
                $('.navbar-brand span').hide();
            }
        });

        $('.fix-header .topbar').stick_in_parent({});

        // this is for close icon when navigation open in mobile view
        $('.nav-toggler').click(function () {
            $('body').toggleClass('show-sidebar');
            $('.nav-toggler i').toggleClass('ti-menu');
            $('.nav-toggler i').addClass('ti-close');
        });
    }

    @HostListener(`document:${CustomEvents.USER_UPDATE}`, ['$event'])
    setUser() {
        this.user = LoggedUser.getUser();
    }

    get displayYear() {
        return moment().format('YYYY');
    }

    hasPartnerLogo() {
        return this.user && this.user['partnerLogoImage'];
    }

    get partnerLogo() {
        if (this.hasPartnerLogo()) {
            return environment.apiUrl + this.user['partnerLogoImage'];
        } else {
            return '/themes/esemdee-logo-symbol-01.png';
        }
    }

    /**
     * Set role name
     * @private
     */
    private _setRoleName(): void {
        if (Utils.lodash.get(LoggedUser.getMainOrganization(), 'roleName', null)) {
            this.roleName = LoggedUser.getMainOrganization().roleName;
        }
    }

    private addZohoHelpCenterScript(): void {

        if (document.getElementById('zohoHelpCenterScript')) {
            return;
        }

        window['ZohoHCAsap'] = window['ZohoHCAsap'] || function (a, b) {
            window['ZohoHCAsap'][a] = b;
        };
        const d = document;
        const s = d.createElement('script');
        s.type = 'text/javascript';
        s.id = 'zohoHelpCenterScript';
        s.defer = true;
        s.src = 'https://desk.zoho.com/portal/api/web/inapp/366288000002622015?orgId=683706641';
        d.getElementsByTagName('head')[0].appendChild(s);
    }
}
