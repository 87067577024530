import {
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    ViewChild,
} from "@angular/core";
import {
    PostActions,
    PostInterface,
} from "~/src/app/modules/posts/post-actions";
import { PostTemplateInterface } from "~/src/app/modules/posts/template.interface";
import Utils from "~/src/app/core/utils";
import { DialogPostsListComponent } from "~/src/app/modules/posts/schedule/dialog-posts-list/dialog-posts-list.component";
import { MatDialog } from "@angular/material/dialog";
import {
    PostSiteStatusConfig,
    PostStatusConfigs,
} from "~/src/app/modules/posts/post-card/post-card";
import { LanguageService } from "~/src/app/services/language.service";
import { ColorSupport } from "~/src/app/core/color-support";
import CommonPostHelpers from "~/src/app/modules/posts/common-post-helpers";
import { PLACEHOLDER_IMAGE } from "~/src/app/configs/configs";
import { PartnerConfigService } from "~/src/app/shared/services/partner-config/partner-config.service";
import {
    POST_STATUS_APPROVED,
    POST_STATUS_NOT_APPROVED,
    POST_STATUS_POSTED,
    POST_TEMPLATE_STATUS_ACTIVE,
    POST_TEMPLATE_STATUS_NOT_APPROVED,
    POST_STATUS_UNDER_POST,
} from "~/src/app/modules/social-media-post/social-media-post.constant";
import { PartnerPermissions } from "~/src/app/shared/services/partner-config/partner-config.options";
import { SocialMediaPostService } from "~/src/app/modules/social-media-post/social-media-post.service";
import { ClickDecorator } from "~/src/app/core/decorators";
import { StringSupport } from "~/src/app/core/helper/string-support";
import { LoggedUser } from "~/src/app/services/logged-user";
import { OpenModalService } from "../../social-media-post/open-modal.service";
import { DialogErrorComponent } from "~/src/app/components/dialog-error/dialog-error.component";
import { FILE_TYPES } from "~/src/app/components/file/types/fileTypes";
import { cloneDeep } from "lodash";

@Component({
    selector: "smd-post-card",
    templateUrl: "./post-card.component.html",
    styleUrls: ["./post-card.component.scss"],
})
export class PostCardComponent implements OnInit {
    @Output() deleteEntity: EventEmitter<any> = new EventEmitter();
    @Output() edit: EventEmitter<any> = new EventEmitter();
    @Output() edited: EventEmitter<any> = new EventEmitter();
    @Output() comment: EventEmitter<any> = new EventEmitter();
    @Output() copy: EventEmitter<any> = new EventEmitter<any>();
    @Output() share: EventEmitter<any> = new EventEmitter<any>();
    @Output() statusChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() showPreview: EventEmitter<any> = new EventEmitter<any>();
    @Output() tagClick: EventEmitter<string> = new EventEmitter<string>();
    @Output() createPost: EventEmitter<string> = new EventEmitter<string>(); // for templates
    @Output() createTemplate: EventEmitter<string> = new EventEmitter<string>(); // for posts
    @Output() addToFolder: EventEmitter<string> = new EventEmitter<string>(); // for templates

    @Output() suspend = new EventEmitter();
    @Output() activate = new EventEmitter();

    @Input() post: PostInterface;
    @Input() template: PostTemplateInterface;

    @Input() isAdminMode = false;
    @Input() declineButton = true;
    @Input() editable = false;
    @Input() shareable = false; // for templates
    @Input() deletable = false;
    @Input() copyable = false;
    @Input() commentable = undefined;
    @Input() clickable = false;
    @Input() previewable = false;
    @Input() isImageOpenable = true;
    @Input() customPreview = false;
    @Input() templateManagerDisplay = false; //for template manager cards
    @Input() postCreation = false; // for templates
    @Input() postManagerDisplay: boolean = false; // for template from post creation
    @Input() templateCreation: boolean = false; // for template from post creation

    @ViewChild("socialSiteListBtn", { read: ElementRef })
    socialSiteListBtn: ElementRef<HTMLButtonElement>;

    // entity is selectable
    @Input()
    @HostBinding("class.selectable")
    selectable = false;

    // entity (post or template) - use for get common properties
    entity: any;

    // category name
    categoryName;

    // category color
    categoryColor;

    // border color by category color
    borderColor;

    // post repeat number
    repeatNumber;

    // entity status properties
    status: {
        status?: string;
        statusMessage?: string;
        iconClass?: string;
        statusMessageKey?: string;
        color?: string;
    } = {};

    // enable action buttons
    disabledActions = false;

    // enable edit action buttons 
    disabledActionsInUnderPost = false;

    // status is active
    isActiveStatus = false;

    // social site type for view
    socialSites: {
        socialSiteID: number;
        socialType: string;
        socialSiteName: string;
        postStatus: string;
        postStatusMessage: string;
        iconClass: string;
    }[] = null;

    // uniq ID
    uuid = Utils.uuid.UUID();

    // post/template short message
    shortMessage: string;

    // active from/create date
    date: any;
    //template manager shows time in "X time ago" format
    dateAgo: string = null;

    // post source platform
    postSource: string;

    // truncated post content (max: 120 character)
    shortContent: string;

    timeZone: string;

    // full post content to tooltip
    fullTooltipContent: string;

    showSocialSiteList = false;
    socialSiteListStyle = {};
    placeholderImg = PLACEHOLDER_IMAGE;

    approvalInProgress = false;
    showApproveButton = false;
    showDeclineButton = false;
    isMainOrganization = true;

    gmbIcon: string = "";

    dateTooltip: string = "";
    isMediaMixed: boolean;
    mixedMediaTooltip: string;

    constructor(
        public openModal: OpenModalService,
        public language: LanguageService,
        private partnerConfigService: PartnerConfigService,
        private _dialog: MatDialog,
        private socialMediaPostService: SocialMediaPostService
    ) { }

    ngOnInit() {
        this.entity = this.post || this.template;

        // set category name
        this.categoryName = Utils.get(this.entity, "categories[0].name", null);

        // set category color
        this.categoryColor = Utils.get(
            this.entity,
            "categories[0].color",
            "#d4459e"
        );

        // set border color
        this.borderColor = ColorSupport.convertHexToRgba(
            this.categoryColor,
            0.2
        );

        // set post repeat number
        this.repeatNumber = Utils.get<PostInterface>(
            this.entity,
            ["repeats", "length"],
            1
        );

        // check actions buttons are disabled
        this.disabledActions =
            !this.editable &&
            !this.copyable &&
            !this.previewable &&
            !this.deletable;

        // check underPost actions edit buttons are disabled
        if (this.post?.status === POST_STATUS_UNDER_POST) {
            this.disabledActionsInUnderPost = true;
        }

        // set short message
        this.shortMessage = CommonPostHelpers.getShortMessage(this.entity, 235);

        // set active from/create date
        this.date =
            (<PostInterface>this.entity).activeFrom ||
            (<PostTemplateInterface>this.entity).createDate;

        if (this.templateManagerDisplay) {
            this.dateAgo = Utils.moment(new Date(this.date)).fromNow();
        }

        // set post source, if post published
        this.postSource =
            this.post && this.post?.status === POST_STATUS_POSTED
                ? CommonPostHelpers.getSource(this.post)
                : null;

        // if entity is post, set social sites
        if (this.post) {
            this.setSocialSites();
        }

        if (this.template) {
            this.isMainOrganization =
                LoggedUser.getMainOrganization()?.organizationID ===
                this.template.organizationID;
            /*  this.commentable = CommonPostHelpers.allowCommentOnTemplate({
                systemType: this.template.systemType,
                isAdminMode: this.isAdminMode
            }) && (this.isMainOrganization || this.isAdminMode); */
        }

        // this.entity = CommonPostHelpers.mergeDataWithDraftData(this.entity);

        if (this.post) {
            this.post = this.entity;
        }

        if (this.template) {
            this.template = this.entity;
        }

        this.fullTooltipContent = StringSupport.removeHtml(
            this.getFullContent()
        );
        this.shortContent = this.getFullContent();

        this.setStatus();

        this.timeZone =
            this.post && this.post.instantPosted
                ? Utils.moment
                    .tz(Utils.moment().utc(), this.post.timeZone)
                    .format("[GMT] Z")
                : null;


        if (this.entity && this.entity.gmbOptions && this.entity.gmbOptions.type) {
            this.gmbIcon = this.getIconForGmbType(this.entity.gmbOptions.type);
        }

        this.dateTooltip = this.getTooltipForDate();
        this.isMediaMixed = this.isMediaMixedFunc();
        this.mixedMediaTooltip = this.getMixedMediaTooltip();
    }

    @ClickDecorator()
    approvement(event: MouseEvent, isApprove: boolean) {
        if (this.post) {
            const status = isApprove
                ? POST_STATUS_APPROVED
                : POST_STATUS_NOT_APPROVED;
            this.approvalInProgress = true;
            this.socialMediaPostService
                .setPostStatus([this.post.postID], status)
                .then((response) => {
                    if (response.errors) {
                        let errorMessage = "";
                        for (const id of Object.keys(response.errors)) {
                            const message = response.errors[id];

                            errorMessage += `${id} - ${message}\n`;
                        }

                        this.openModal
                            .errorModal(DialogErrorComponent, {
                                message: errorMessage,
                            })
                            .afterClosed()
                            .subscribe(() => {
                                this.approvalInProgress = false;
                            });
                    } else {
                        this.post.status = status;

                        return this.socialMediaPostService
                            .getPosts({ postIDs: [this.post.postID] })
                            .then((res) => {
                                const post = res?.posts.find(
                                    (post) => post.postID === this.post.postID
                                );

                                if (post) {
                                    Object.assign(this.post, post);
                                    Object.assign(this.entity, post);
                                }

                                return Promise.resolve(res);
                            })
                            .finally(() => {
                                this.setStatus();
                                this.approvalInProgress = false;
                                this.emitStatusChange(this.entity);
                            });
                    }
                });
        }

        if (this.template) {
            const status = isApprove
                ? POST_TEMPLATE_STATUS_ACTIVE
                : POST_TEMPLATE_STATUS_NOT_APPROVED;
            this.approvalInProgress = true;
            this.socialMediaPostService
                .setPostTemplateStatus([this.template.templateID], status)
                .then(() => {
                    this.template.status = status;
                    this.setStatus();
                })
                .finally(() => {
                    this.approvalInProgress = false;
                    this.emitStatusChange(this.entity);
                });
        }
    }

    getFullContent(): string {
        const fields = [
            this.entity.headline,
            this.entity.subHeadline,
            this.entity.mainCopy,
            this.entity.signature,
        ].filter((field) => field && (field || "").trim() !== "");
        let content = "";

        fields.forEach((field, index) => {
            // csak akkor adunk hozzá br tag-et, ha nem az utolsó field-nél tartunk
            const addBr = index < fields.length - 1;

            content += field.replace(/<br ?\/?>/g, "\n");

            if (addBr) {
                content += `\n`;
            }
        });

        return content;
    }

    getTooltipForDate() {
        if (this.date) {
            let formattedDate = Utils.moment(new Date(this.date)).format("hh:mm A, DD MMM YYYY");
            return formattedDate;
        }
        return "";
    }

    getTooltipForTemplateTags(template) {
        if (template) {
            const tags = template.tags;
            const tooltipTags = [];

            for (const tag of tags.slice(5, tags.length)) {
                tooltipTags.push(tag);
            }

            return tooltipTags.join(", ");
        }

        return "";
    }

    /**
     * Set status properties
     */
    setStatus() {
        // tslint:disable-next-line:prefer-const
        let { status, statusMessage } = PostActions.getPostMainStatus(
            Utils.lodash.cloneDeep(this.entity)
        );
        let statusKey = status;

        if (this.entity.type === "draft") {
            const newStatusKey = status + "Draft";

            if (newStatusKey in PostStatusConfigs) {
                statusKey = newStatusKey;
            }

            statusMessage = this.entity["typeMessage"];
        }

        this.status = {
            status,
            statusMessage,
            ...PostStatusConfigs[statusKey],
        };

        this.isActiveStatus = this.entity.status === "active";

        this.showApproveButton = CommonPostHelpers.allowApprove({
            hasPartnerConfig: this.partnerConfigService.hasConfig(
                PartnerPermissions.ApprovementWorkflow
            ),
            isAdminMode: this.isAdminMode,
            status: this.status.status,
            type: this.entity?.type,
            systemType: this.entity?.systemType,
            templateOrganizationID: this.template?.organizationID,
            approveButtonVisible: this.entity.approveButtonVisible,
            declineButtonVisible: this.entity.declineButtonVisible,
        });
        if (this.declineButton) {
            this.showDeclineButton = CommonPostHelpers.allowDecline({
                hasPartnerConfig: this.partnerConfigService.hasConfig(
                    PartnerPermissions.ApprovementWorkflow
                ),
                isAdminMode: this.isAdminMode,
                status: this.status.status,
                type: this.entity?.type,
                systemType: this.entity?.systemType,
                templateOrganizationID: this.template?.organizationID,
                approveButtonVisible: this.entity.approveButtonVisible,
                declineButtonVisible: this.entity.declineButtonVisible,
            });
        } else {
            this.showDeclineButton = CommonPostHelpers.allowDecline({
                hasPartnerConfig: this.partnerConfigService.hasConfig(
                    PartnerPermissions.ApprovementWorkflow
                ),
                isAdminMode: this.isAdminMode,
                status: this.status.status,
                type: this.entity?.type,
                systemType: this.entity?.systemType,
                templateOrganizationID: this.template?.organizationID,
                approveButtonVisible: false,
                declineButtonVisible: false,
            });
        }
    }

    /**
     * Set social sites for view
     */
    setSocialSites() {
        const repeatIndex = this.post["repeatIndex"] || 0;

        this.socialSites = (this.post.socialSites || []).map((site) => {
            const result = {
                ...site,
                ...Utils.get(
                    this.post,
                    `socialSiteStatuses[${repeatIndex}][${site.socialSiteID}]`,
                    {
                        postStatus: "",
                        postStatusMessage: "",
                    }
                ),
            };

            return {
                ...result,
                ...(result.postStatus
                    ? PostSiteStatusConfig[result.postStatus]
                    : {}),
            };
        });
    }

    /**
     * Show post repeat entities
     */
    showRepeatPosts() {
        const posts: any[] = [];

        for (const repeatIndex in this.post.repeats) {
            const date = this.post.repeats[repeatIndex];
            const newPost = PostActions.getPostMainStatus({
                ...this.post,
                activeFrom: date,
                activeFromInput: date,
                calendarDate: date,
                isRepeat: true,
                repeatIndex: repeatIndex,
            });

            newPost["repeats"] = false;
            newPost["isCommentable"] = false;
            newPost["isDeleteable"] = false;
            newPost["templateCreation"] = false;
            newPost["approveButtonVisible"] = false;
            newPost["declineButtonVisible"] = false;

            newPost["repeats"] = false;

            posts.push(newPost);
        }

        this._dialog
            .open(DialogPostsListComponent, {
                data: {
                    date: this.post.calendarDate,
                    events: [],
                    posts: posts,
                    socialSites: [],
                    groupByParentID: true,
                },
                disableClose: true,
            })
            .afterClosed()
            .subscribe((isChanged) => {
                if (isChanged) {
                    this.emitEdited(this.post);
                }
            });
    }

    changeSocialSiteListVisibility(visible = true): void {
        if (this.socialSiteListBtn) {
            this.showSocialSiteList = visible;
            if (visible) {
                this.setSocialSiteListPosition();
            }
        }
    }

    setSocialSiteListPosition() {
        if (this.socialSiteListBtn) {
            const buttonPos =
                this.socialSiteListBtn.nativeElement.getBoundingClientRect();
            const isModal = document.querySelector("html > body > .cdk-overlay-container > .cdk-overlay-backdrop")

            if (isModal) {
                this.socialSiteListStyle = {
                    left: buttonPos.left + buttonPos.width / 2 + "px",
                    top: buttonPos.bottom + "px",
                    transform: `translateX(-50%)`,
                    //maxHeight: window.innerHeight - buttonPos.bottom + "px",
                    position: "fixed",
                    //zIndex: "10000",
                };
            } else {
                this.socialSiteListStyle = {};
            }
        }
    }

    /**
     * Emit edited output event
     *
     * @param data
     */
    emitEdited(data: any) {
        this.edited.emit(data);
    }

    /**
     * Emit edit output event
     *
     * @param data
     */
    emitEdit(data: any) {
        this.edit.emit(data);
    }

    /**
     * Emit copy output event
     *
     * @param data
     */
    emitCopy(data: any) {
        this.copy.emit(data);
    }

    /**
     * Emit share output event
     *
     * @param data
     */
    emitShare(data: any) {
        this.share.emit(data);
    }

    /**
     * Emit comment output event
     *
     * @param data
     */
    emitComment(data: any) {
        this.comment.emit(data);
    }

    /**
     * Emit suspend output event
     *
     * @param data
     */
    emitSuspend(data: any) {
        this.suspend.emit(data);
    }

    /**
     * Emit show preview output event
     *
     * @param data
     */
    emitShowPreview(data: any) {
        this.showPreview.emit(data);
    }

    /**
     * Emit status change output event
     *
     * @param data
     */
    emitStatusChange(data: any) {
        this.statusChange.emit(data);
    }

    /**
     * Emit delete entity output event
     *
     * @param data
     */
    emitDelete(data: any) {
        this.deleteEntity.emit(data);
    }

    getIconForGmbType(type) {
        switch (type) {
            case "event":
                return "event";
            case "offer":
                return "local_offer";
            default:
                return "new_releases";
        }
    }

    /**
     * Emit tag click event
     * 
     * @param tag 
     */
    emitTagClick(tag: string) {
        this.tagClick.emit(tag);
    }

    emitCreatePost(data: any) {
        this.createPost.emit(data);
    }

    emitCreateTemplate(data: any) {
        this.createTemplate.emit(data);
    }

    emitAddToFolder(data: any) {
        this.addToFolder.emit(data);
    }

    isMediaMixedFunc() {
        return this.entity?.medias?.some((media) => media.type === FILE_TYPES.VIDEO) &&
            this.entity?.medias?.some((media) => media.type === FILE_TYPES.IMAGE);
    }

    getMixedMediaTooltip() {
        const videoCount = this.entity?.medias?.filter((media) => media.type === FILE_TYPES.VIDEO).length;
        const imageCount = this.entity?.medias?.filter((media) => media.type === FILE_TYPES.IMAGE).length;

        return `${imageCount} image${imageCount > 1 ? "s" : ""} and ${videoCount} video${videoCount > 1 ? "s" : ""}`;
    }
}
