import {ModelAbstract} from '~/src/app/services/model.abstract';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {FileBrowserAbstract} from '~/src/app/services/file-browser.abstract';
import {CarouselController} from '~/src/app/modules/posts/carousel/carousel.component';
import {LanguageService} from '~/src/app/services/language.service';
import {SocialChannels} from '~/src/app/modules/analytics/widget/widget.interfaces';
import {forEach} from 'lodash';
import {BackendService} from '~/src/app/core/backend.service';
import { Helpers } from './helpers';
import { Token } from './token';

@Injectable({providedIn: 'root'})
export class FileBrowserService extends ModelAbstract {
    public fileBrowserError: { title: string; message: string; };
    private _selectedSocialTypesInCarouselCreateOrEdit: Array<SocialChannels> = [];

    constructor(
        public http: HttpClient,
        public dialog: MatDialog,
        public languageService: LanguageService,
        public carouselController: CarouselController,
        private backendService: BackendService
    ) {
        super(http, dialog);

        this.apiLink = '/api/media';

        this.fileBrowserError = {
            title: this.languageService.getLine('carousel.error.social.channels.file.browse.title'),
            message: this.languageService.getLine('carousel.error.social.channels.file.browse.message')
        };
    }

    /**
     * Upload file with progress
     * @param {object} item
     * @return {Observable<Object>}
     */
    uploadFile(item: object) {
        return this.backendService.uploadFiles('/media/file-upload', item);
    }

    /**
     * Upgrade file with progress
     * @param {object} item
     * @return {Observable<Object>}
     */
    replaceFile(data: {file: File, editorMeta: string, mediaID: number}) {
        return this.backendService.uploadFiles(`/media/edit/${data.mediaID}`, {
            file: data.file,
            meta: data.editorMeta
        });
    }

    /**
     * Edit media
     *
     * @param {{name: string; tags?: string; mediaID: number}} data
     * @return {Promise<any>}
     */
    editFile(data: {name: string, tags?: string, mediaID: number}): Promise<any> {
        return this.backendService.post(`/media/modify/${data.mediaID}`, data);
    }

    /**
     * Editor meta file
     *
     * @param editorMetaFile
     * @return {Promise<any>}
     */
    getEditorMeta(editorMetaFile) {
        return this.backendService.get('/media/editor-meta', {editorMetaFile: editorMetaFile});
    }

    /**
     * Edit item with post request
     *
     * @param {number} id
     * @param {object} item
     * @return {Promise<any>}
     */
    editWithPost(id: number, item: object): Promise<any> {
        return this.backendService.post(`/media/modify/${id}`, item);
    }

    /**
     * Set media(s) status
     * @param mediaIDs
     * @param status
     */
    setStatus(mediaIDs: number[], status: 'active' | 'inactive') {
        return this.backendService.post(`/media/status`, {mediaIDs: mediaIDs, status: status});
    }

    /**
     * Delete media elements
     *
     * @param {{fileIDs?: number[] | string; carouselIDs?: number}} data
     * @returns {Promise<any[]>}
     */
    deleteMedias(data: {fileIDs?: number[] | string, carouselIDs?: number[]} = {}) {
        const requests = [];

        if ('fileIDs' in data) {
            data.fileIDs = JSON.stringify(data.fileIDs);

            requests.push(this.deleteFiles(data));
        }

        if ('carouselIDs' in data) {
            for (const carouselID of data.carouselIDs) {
                requests.push(this.carouselController.deleteCarouselByID(carouselID));
            }
        }

        return Promise.all(requests);
    }

    /**
     * Delete files
     *
     * @param {object} data
     * @return {Promise<any>}
     */
    deleteFiles(data: object): Promise<any> {
        return this.backendService.post(`/media/file-delete`, data);
    }

    get selectedSocialTypesInCarouselCreateOrEdit(): Array<SocialChannels> {
        return this._selectedSocialTypesInCarouselCreateOrEdit;
    }

    set selectedSocialTypesInCarouselCreateOrEdit(value: Array<SocialChannels>) {
        this._selectedSocialTypesInCarouselCreateOrEdit = value;
    }

    setItemsSocialChannelsHasInvalid(socialChannels: Array<SocialChannels>, items) {
        items.map(item => {
            delete item['hasSocialChannelProblem'];
        });
        forEach(items, (item, idx) => {
            const selectedSocialTypes = socialChannels;
            let isAddableToCarousel = true;

            /**
             * @description
             * If the social channels set when somebody uploads
             * it and the social channels is not in the enabled channels, put a validation red message under the image
             */
            forEach(selectedSocialTypes, type => {
                if (item.file.socialChannels.indexOf(type) === -1 && item.file.socialChannels.length) {
                    isAddableToCarousel = false;
                }
            });

            const isNeedAbortToAdd = !isAddableToCarousel && selectedSocialTypes.length !== 0;
            items[idx]['hasSocialChannelProblem'] = isNeedAbortToAdd;
        });
        this.selectedSocialTypesInCarouselCreateOrEdit = socialChannels;
    }
}

@Injectable({providedIn: 'root'})
export class MediasService extends FileBrowserAbstract {
    constructor(
        public service: FileBrowserService,
        public backendService: BackendService,
        carouselController: CarouselController
    ) {
        super(service, carouselController);
    }

    getItems(filters?) {

        if (!!filters) {
            this.filters = filters;
        }

        return new Promise((resolve, reject) => {
            super.getItems(
                response => {
                    super.initFiles(response).then(medias => resolve(medias)).catch(err => reject(err));
                },
                error => reject(error)
            );
        });
    }

    getItemCount(): Promise<{ count: number }> {
        return this.backendService.get('/media/media-count');
    }
}
