import { Injectable } from "@angular/core";
import { SocialPlatform } from "~/src/app/shared/types";
import Utils from "~/src/app/core/utils";
import { StringSupport } from "~/src/app/core/helper/string-support";
import { environment } from "~/src/environments/environment";
import {
    SOCIAL_MEDIA_TYPE_FACEBOOK,
    SOCIAL_MEDIA_TYPE_LINKEDIN,
    SOCIAL_MEDIA_TYPE_TWITTER,
    SOCIAL_MEDIA_TYPE_INSTAGRAM,
    SOCIAL_MEDIA_TYPE_GMB,
} from "~/src/app/core/constants";
import {
    AUTOCOMPLETE_TYPE_HASHTAG,
    AUTOCOMPLETE_TYPE_MENTION,
} from "~/src/app/modules/posts/autocomplete.constants";
import { IAutocompletePlatform } from "~/src/app/modules/posts/autocomplete.interface";
import { MENTION_TAG_ELEMENT_CLASS } from "~/src/app/modules/mention/mention.contant";

export interface SocialSite {
    id: string;
    name: string;
    iconClasses: string;
}

export const DEFAULT_TIMEZONE = "Europe/London";

export const NO_AVATAR_IMAGE_PATH =
    "/themes/smd/src/assets/images/no-avatar.png";

export const LOADING_IMAGE = "/themes/smd/src/assets/images/image-loading.gif";

export const FALLBACK_IMAGE =
    "/themes/smd/src/assets/images/social-media-fallback.jpg";
export const PLACEHOLDER_IMAGE =
    "/themes/smd/src/assets/images/placeholder-image.jpg";

export const FILTERS_DEBOUNCE_MS = 1000;

export const Configs = {
    keys: {
        tinyMce: "ldm76uo1y2cmbxoe47feqtlr3b97t9923pm8seon14qgvc9e",
        canva_dev: "obCCCe1cNs4tVGOD0XHy9J7K",
        canva_prod: "GAgYnK201GvogFOJs4jvE7jg",
        zoho_client_id: "1000.BR30ILJO9ZBMOD5IZO1MLWC7RZ74MK",
    },
    socials: [
        {
            id: "facebook",
            name: "Facebook",
            iconClasses: "fa-brands fa-square-facebook color-facebook",
        },
        {
            id: "linkedIn",
            name: "LinkedIn",
            iconClasses: "fa-brands fa-linkedin color-linkedin",
        },
        {
            id: SOCIAL_MEDIA_TYPE_TWITTER,
            name: "Twitter",
            iconClasses: "fa-brands fa-square-x-twitter color-twitter",
        },
        {
            id: SOCIAL_MEDIA_TYPE_INSTAGRAM,
            name: "Instagram",
            iconClasses: "fa-brands fa-instagram color-instagram",
        },
        {
            id: SOCIAL_MEDIA_TYPE_GMB,
            name: "Google My Business",
            shortName: "GMB",
            iconClasses: "mdi mdi-gmb color-gmb",
        },
    ],
    templatePlaceholder: "[placeholder]",
    templateDatePlaceholder: "[placeholder_date]",
    templateAddressPlaceholder: "[placeholder_address]",
};

export const TOKEN_KEY = "token";

export const SOCIAL_PLATFORMS_DEFAULT_CONFIG: Array<SocialPlatform> = [
    {
        id: SOCIAL_MEDIA_TYPE_FACEBOOK,
        name: "Facebook",
        isActive: false,
        isDisabled: false,
        classes: "color-facebook",
        activeClasses: "bg-facebook active",
        iconClass: "mdi mdi-facebook",
        iconclassbg:"fa-brands fa-square-facebook color-facebook",
    },
    {
        id: SOCIAL_MEDIA_TYPE_LINKEDIN,
        name: "LinkedIn",
        isActive: false,
        isDisabled: false,
        classes: "color-linkedIn",
        activeClasses: "bg-linkedIn active",
        iconClass: "fa-brands fa-linkedin",
        iconclassbg:"fa-brands fa-linkedin color-linkedin",
    },
    {
        id: SOCIAL_MEDIA_TYPE_TWITTER,
        name: "Twitter",
        isActive: false,
        isDisabled: false,
        classes: "color-twitter",
        activeClasses: "bg-twitter active",
        iconClass: "fa-brands fa-x-twitter",
        iconclassbg:"fa-brands fa-x-twitter color-twitter",
    },
    {
        id: SOCIAL_MEDIA_TYPE_INSTAGRAM,
        name: "Instagram",
        isActive: false,
        isDisabled: false,
        classes: "color-instagram",
        activeClasses: "bg-instagram active",
        iconClass: "fa-brands fa-instagram",
        iconclassbg:"fa-brands fa-instagram color-instagram",
    },
    {
        id: SOCIAL_MEDIA_TYPE_GMB,
        name: "Google My Business",
        isActive: false,
        isDisabled: false,
        classes: "color-gmb",
        activeClasses: "bg-gmb active",
        iconClass: "mdi mdi-gmb",
        iconclassbg:"mdi mdi-gmb color-gmb",
    },
];

export const PostSources = {
    Esemdee: {
        id: "esemdee",
        label: "Esemdee",
    },
    Facebook: {
        id: SOCIAL_MEDIA_TYPE_FACEBOOK,
        label: "Facebook",
    },
    LinkedIn: {
        id: SOCIAL_MEDIA_TYPE_LINKEDIN,
        label: "LinkedIn",
    },
    Twitter: {
        id: SOCIAL_MEDIA_TYPE_TWITTER,
        label: "Twitter",
    },
    Instagram: {
        id: SOCIAL_MEDIA_TYPE_INSTAGRAM,
        label: "Instagram",
    },
    Gmb: {
        id: SOCIAL_MEDIA_TYPE_GMB,
        label: "Google My Business",
    },
};

export const TINYMCE_PLUGIN_FOLDER = environment.tinyMcePluginFolder;

export const LINK_SHORTEN_PLUGIN_NAME = "linkShorten";
export const TINYMCE_DELIMITER_MENTION = "@";
export const TINYMCE_DELIMITER_HASHTAG = "#";
export const ICON_PATH = "/themes/smd/src/assets/images/icon/";
let no_id=0;
export let TemplateTinyMceBaseOptions = {
    plugins: "paste mention",

    // paste plugin options
    paste_as_text: true,
    paste_preprocess: function (plugin, args) {
        const removeLineBreaks = (content: string) => {
            content = StringSupport.trimLineBreaks(content);

            content = Utils.htmlEntities.decode(content);

            return content;
        };

        args.content = removeLineBreaks(args.content);

        args.content = args.content.replace(
            /<placeholder>/g,
            Utils.htmlEntities.encode("<placeholder>")
        );

        return args;
    },

    skin_url: "/themes/smd/src/assets/tinymce/skins/lightgray",
    entity_encoding: "raw",
    force_br_newlines: false,
    force_p_newlines: false,
    forced_root_block: "",
    external_plugins: {
        smileys: `${TINYMCE_PLUGIN_FOLDER}/smileys/plugin.js?version=1.0`,
        [LINK_SHORTEN_PLUGIN_NAME]: `${TINYMCE_PLUGIN_FOLDER}/linkShorten/plugin.js?version=1.3`,
        paste: `${TINYMCE_PLUGIN_FOLDER}/paste/plugin.min.js`,
        "emoji-picker": `${TINYMCE_PLUGIN_FOLDER}/emoji-picker/plugin.js`,
        autocomplete: `${TINYMCE_PLUGIN_FOLDER}/mention/autocomplete.js`,
        mention: `${TINYMCE_PLUGIN_FOLDER}/mention/plugin.js?version=1.3`,
    },
    mentions: {
        typeConfig: {
            [TINYMCE_DELIMITER_MENTION]: {
                multiple: true,
                oneItemPerList: true,
                closeOnSelect: false,
                minSearchLength: 2,
            },
            [TINYMCE_DELIMITER_HASHTAG]: {
                multiple: false,
                oneItemPerList: false,
                closeOnSelect: true,
                closeOnSpace: true,
            },
        },
        insert: function (item) {
            const uid = +new Date();

            if (item.type === AUTOCOMPLETE_TYPE_MENTION) {
                const linkAttrs = item.link
                    ? `href="${item.link}" target="_blank"`
                    : `href="javascript:void()"`;
                return {
                    html: `<a class="autocomplete-item ${MENTION_TAG_ELEMENT_CLASS}" ${linkAttrs} data-uid="${uid}" data-item-uids="${
                        item.uid
                    }">${TINYMCE_DELIMITER_MENTION + item.name}</a>&nbsp;`,
                    uid,
                };
            }

            if (item.type === AUTOCOMPLETE_TYPE_HASHTAG) {
                let name = item.name || "";

                if (name.substr(0, 1) !== TINYMCE_DELIMITER_HASHTAG) {
                    name = TINYMCE_DELIMITER_HASHTAG + name;
                }

                return {
                    html: name + "&nbsp;",
                    uid,
                };
            }
        },
        render: function (item: IAutocompletePlatform) {
            const hasMessage = !!(item.items || []).find(
                (_item) => !!_item.message
            );
            if(no_id==2){
                no_id=0;
            }
            no_id=no_id+1;
            let html = `
                <li class="dropdown-item${hasMessage ? " inactive" : ""}" id="hashtag${no_id}">
                    <div class="autocomplete-tab">
                        <span class="m-r-15">
                            <i class="${item.iconClass}"></i>&nbsp;${item.name}
                        </span>
                        ${
                            item.type === AUTOCOMPLETE_TYPE_MENTION
                                ? `
                            <i class="fa fa-times-circle text-danger autocomplete-tab__icon autocomplete-tab__icon--invalid"></i>
                            <i class="fa fa-check-circle text-success autocomplete-tab__icon autocomplete-tab__icon--valid"></i>
                        `
                                : ""
                        }
                    </div>
            `;

            if (item.items) {
                html += '<ul class="dropdown-menu autocomplete-list">';

                item.items.forEach((mention) => {
                    let attrs = "";

                    for (const key in mention) {
                        if (
                            ["number", "string"].includes(typeof mention[key])
                        ) {
                            attrs += ` data-${key}="${mention[key]}"`;
                        }
                    }

                    if (mention.type === AUTOCOMPLETE_TYPE_MENTION) {
                        if (mention.message) {
                            html += `
                                <p class="text-info m-0 p-10"><i class="fa fa-exclamation-circle m-r-10"></i>${mention.message}</p>
                            `;
                        } else {
                            html += `
                                <li class="dropdown-item" ${attrs}>
                                    <div class="mce-thumbnail">
                                        <img src="${mention.image}" alt="${mention.name}">
                                        <i class="${item.iconClass}"></i>
                                    </div>
                                    <a href="${mention.link}" target="_blank"><span>${mention.name}</span></a>
                                </li>
                            `;
                        }
                    }

                    if (mention.type === AUTOCOMPLETE_TYPE_HASHTAG) {
                        if (mention.message) {
                            html += `
                            <p class="text-info m-0 p-10"><i class="fa fa-exclamation-circle m-r-10"></i>${mention.message}</p>
                            `;
                        } else {
                            let title = "";

                            if (mention.subItems && mention.subItems) {
                                title = ` title="${mention.subItems
                                    .map((subItem) => subItem.name)
                                    .join(", ")}"`;
                            }
    
                            html += `
                                <li class="dropdown-item" ${attrs + title}>
                                    <a href="javascript:;"><span>${
                                        mention.name
                                    }</span></a>
                                </li>
                            `;
                        }
                    }
                });

                html += "</ul>";
            }

            html += "</li>";

            return html;
        },
        renderDropdown: function () {
            return `
                <div class="mention-autocomplete dropdown-menu" tabindex="-1">
                    <div class="p-10">
                        <input id="autocompleteSearchField" class="form-control" type="text" placeholder="Type here...">
                    </div>
                    <ul></ul>
                </div>
            `;
        },
    },
    content_css: "/themes/smd/src/assets/css/base-tiny-mce-content.css",
    auto_convert_smileys: true,
    toolbar: `emoji-picker ${LINK_SHORTEN_PLUGIN_NAME} hashtag instagramLocation2 | highlight`,
    //toolbar: `emoji-picker ${LINK_SHORTEN_PLUGIN_NAME} hashtag instagramLocation2`,
    menubar: "tools",
    setup: function (editor) {
        editor.addMenuItem("insertAddressPlaceholder", {
            text: "Insert Address placeholder",
            context: "tools",
            onclick: function () {
                editor.insertContent(Configs.templateAddressPlaceholder);
            },
        });

        editor.addMenuItem("insertDatePlaceholder", {
            text: "Insert Date placeholder",
            context: "tools",
            onclick: function () {
                editor.insertContent(Configs.templateDatePlaceholder);
            },
        });
        editor.addMenuItem("insertPlaceholder", {
            text: "Insert Text placeholder",
            context: "tools",
            onclick: function () {
                editor.insertContent(Configs.templatePlaceholder);
            },
        });
        editor.addButton('highlight', {
            text: false,
            image: ICON_PATH + 'marker.svg',
            onclick: function () {
                editor.focus();

                // highlight or remove if already present (span with marked class)
                if (editor.selection.getNode().tagName === 'MARK') {
                    editor.selection.getNode().outerHTML = editor.selection.getNode().innerHTML;
                } else {
                    if (editor.selection.getContent().length) {
                        editor.selection.setContent('<mark>' + editor.selection.getContent() + '</mark>');
                    }
                }
            }
          });
    },
    height: "100",
    statusbar: false,
};

export const BASE_DATETIME_FORMAT = "Y-MM-DD HH:mm:ss";

export const BASE_DATE_FORMAT = "Y-MM-DD";

export const BASE_TIME_FORMAT = "HH:mm:ss";

export const BASE_HOUR_FORMAT = "HH";

export const BASE_MINUTES_FORMAT = "mm";

export const BASE_SECONDS_FORMAT = "ss";

export const VIEW_MOMENT_BASE_DATETIME_FORMAT = "D MMMM YYYY, hh:mm A";

export const VIEW_BASE_DATETIME_FORMAT = "d MMM yyyy, h:mm a";

export const VIEW_BASE_DATE_FORMAT = "DD MMMM YYYY";

@Injectable()
export class EditorConfig {
    constructor() {
        return TemplateTinyMceBaseOptions;
    }
}

export const LINKEDIN_SUCCESS_LOGIN_EVENT_NAME = "smd.linkedin.login.success";
export const LINKEDIN_FAILED_LOGIN_EVENT_NAME = "smd.linkedin.login.error";
export const GOOGLE_CLOUD_STORAGE_BASE_URI = "https://storage.googleapis.com";