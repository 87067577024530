import { debounceTime } from "rxjs/operators";
import {
    AfterViewInit,
    Component,
    ComponentRef,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    ViewChild,
    ViewChildren,
} from "@angular/core";
import {
    BASE_DATE_FORMAT,
    BASE_DATETIME_FORMAT,
    ICON_PATH,
    LINK_SHORTEN_PLUGIN_NAME,
    TemplateTinyMceBaseOptions,
    TINYMCE_DELIMITER_HASHTAG,
    TINYMCE_DELIMITER_MENTION,
} from "~/src/app/configs/configs";
import { AbstractControl, FormControl, FormGroup, Validators } from "@angular/forms";
import { EditorComponent } from "@tinymce/tinymce-angular";
import { Subject, Subscription } from "rxjs";
import { MatExpansionPanel } from "@angular/material/expansion";
import { cloneDeep, forEach, has, includes, isSet, keys } from "lodash";
//import * as moment from 'moment';
import * as moment from "moment-timezone";
import * as getUrls from "get-urls";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import { LanguageService } from "~/src/app/services/language.service";
import { FileBrowserComponent, FileBrowserSelectionChange, FileBrowserSelectionChangeTypes } from "~/src/app/components/file-browser/file-browser.component";
import { LoggedUser } from "~/src/app/services/logged-user";
import { Debounce, Helpers, LoggedUserInterface, MyErrorStateMatcher } from "~/src/app/services/helpers";
import { FILE_TYPES } from "~/src/app/components/file/types/fileTypes";
import { OPEN_GRAPH_TYPE_TWITTER, UrlOpenGraphService } from "~/src/app/services/urlopengraph.service";
import { OPENGRAPH_FILE_MIMETYPE, SmdFile } from "~/src/app/services/file.class";
import { NotifyService } from "~/src/app/services/notify.service";
import { FormValidationService } from "~/src/app/services/form.validation.service";
import {
    PeriodType,
    PostContentPrepareConfig,
    PostSkeletonGetDataForRequestOptions,
} from "~/src/app/modules/social-media-post/post-skeleton/post-skeleton.interfaces";
import { DialogConfirmComponent } from "~/src/app/components/dialog-confirm/dialog-confirm.component";
import { ScrollToService } from "@nicky-lenaers/ngx-scroll-to";
import { minDate } from "@ng-validators/ng-validators";
import { MediaPostRequestKeys, MediaPostTemplateRequestKeys } from "~/src/app/modules/social-media-post/post-skeleton/post-skeleton.options";
import { CubeParentComponent } from "~/src/app/modules/posts/cube/components/cube-parent/cube-parent.component";
import { ComponentInjector } from "~/src/app/services/component.injector";
import Utils from "~/src/app/core/utils";
import { PartnerPermissions } from "~/src/app/shared/services/partner-config/partner-config.options";
import { PartnerConfigService } from "~/src/app/shared/services/partner-config/partner-config.service";
import { EventObj } from "~/node_modules/@tinymce/tinymce-angular/editor/Events";
import { StringSupport } from "~/src/app/core/helper/string-support";
import { DateSupport } from "~/src/app/core/helper/date-support";
import CommonPostHelpers from "~/src/app/modules/posts/common-post-helpers";
import { CoreConfig } from "~/src/app/core/core.config";
import { SOCIAL_MEDIA_TYPE_TWITTER, SOCIAL_MEDIA_TYPE_INSTAGRAM, SOCIAL_MEDIA_TYPE_GMB, SOCIAL_MEDIA_TYPE_FACEBOOK } from "~/src/app/core/constants";
import { EmojiPickerService } from "~/src/app/shared/emoji-picker.service";
import { AutocompleteService } from "~/src/app/modules/posts/autocomplete.service";
import { IMentionViewItem, MentionSelectionChangeEvent } from "~/src/app/modules/social-media-post/post-skeleton/mention-list/mention-list";
import { HashtagCollectionManagerModalComponent } from "~/src/app/modules/hashtag/components/hashtag-collection-manager-modal/hashtag-collection-manager-modal.component";
import { InstagramLocationCollectionManagerModalComponent } from "~/src/app/modules/instagram-location/instagram-location-collection-manager-modal/instagram-location-collection-manager-modal.component";
import { PostFormControlNames, PostFormExpansionPanels } from "~/src/app/modules/social-media-post/social-media-post.constant";
import { SocialMediaPlatform } from "~/src/app/modules/social-media-post/social-media-post.interface";
import { SocialMediaPlatforms } from "~/src/app/modules/social-media-post/social-media-platforms-config";
import { MentionHelpers } from "~/src/app/modules/posts/mention-helpers";
import { ResourceService } from "~/src/app/directives/resource-checker/resource.service";
import { PostInterface } from "~/src/app/modules/posts/post-actions";
import { LinkShorteningService } from "~/src/app/modules/social-media-post/link-shortening.service";
import { MatRadioModule, MatRadioChange } from '@angular/material/radio';

export interface LinkshareSetterConfig {
    clearSelectedMedias?: boolean;
    addOGImageToList?: boolean;
    ogImageIsSelected?: boolean;
    modifyMedias?: boolean;
}

@Component({
    selector: "smd-post-skeleton",
    templateUrl: "./post-skeleton.component.html",
    styleUrls: ["./post-skeleton.component.scss"],
})
export class PostSkeletonComponent implements OnInit, OnDestroy, AfterViewInit {
    /**
     * Active tab index
     * @type {number}
     */
    @Input() currentTabIndex = 0;

    /**
     * This form position in tab group
     * @type {number}
     */
    @Input() tabPosition = 0;

    /**
     * Platform ID
     */
    @Input() socialMediaType;

    /**
     * Post object for edit
     */
    @Input() currentPost;

    /**
     * Form data for copy
     */
    @Input() formData;

    /**
     * Object type
     */
    @Input() objectType: "post" | "postTemplate";

    /**
     * Trigger submit event
     */
    @Input() submit: Subject<any>;

    /**
     * Trigger form setting from form data
     */
    @Input() readFormData: Subject<any>;

    /**
     * Get is comment mode
     */
    @Input() isEntityCommentMode;

    /**
     * Get is comment mod
     */
    @Input() expiryTabHidden: boolean;

    /**
     * Initial schedule date
     */
    @Input() initialScheduleDate;

    /**
     * Set server error messages
     * @param value
     */
    @Input() set serverErrorMessages(value: any) {
        if (!!value) {
            this.postFormErrorMessages = {
                ...this.postFormErrorMessages,
                ...value,
            };

            forEach(this.postFormGroup.controls, (control, controlName) => {
                if (includes(keys(value), controlName)) {
                    this.postFormGroup.get(controlName).setErrors({ incorrect: false });
                }
            });

            if (this.isActiveTab()) {
                this.scrollToFirstWrongPanel();
            }

            this.changePanelExpandedState(null, true);
        }
    }

    /**
     * Default selected medias
     */
    @Input() initialMedias: SmdFile[];

    /**
     * Get is create post from template mode
     */
    @Input() isCreateFromTemplate;

    @Input() selectedTemplate: PostInterface = null;

    /**
     * Get post template filters on create post from template
     */
    @Input() postTemplateFilters;

    @Input() cubeTemplate;

    @Input() selectedSocialSites = [];

    @Input() set socialSites(values) {
        if (!!values && !!values.length) {
            this._socialSites = values;
        }
    }

    @Input() isAdminMode = false;

    /**
     * @type {boolean}
     */
    @Input() isCopyMode = false;

    /**
     * Selected organizationIDs
     *
     * @type {any[]}
     */
    @Input() organizationIDs: number[] = [];

    /**
     * Enable mention usages
     * @type {boolean}
     */
    @Input() enableMention = true;

    /**
     * Change form validation status event
     * @type {EventEmitter<any>}
     */
    @Output() validityChange = new EventEmitter();

    /**
     * Change form value event
     * Emit raw form data
     * @type {EventEmitter<any>}
     */
    @Output() valueChange = new EventEmitter();

    /**
     * Emit form data for request
     * @type {EventEmitter<any>}
     */
    @Output() getData = new EventEmitter();

    /**
     * Emit form data for request
     * @type {EventEmitter<any>}
     */
    @Output() setErrorMessages = new EventEmitter();

    /**
     * Emit setting info messages
     * @type {EventEmitter<any>}
     */
    @Output() setInfoMessages = new EventEmitter();

    @Output() setSelectedMedias = new EventEmitter();

    /**
     * Emit form data for request
     * @type {EventEmitter<any>}
     */
    @Output() setErrorTitle = new EventEmitter();

    /**
     * Post copied event
     * @type {EventEmitter<any>}
     */
    @Output() postReaded = new EventEmitter();

    /**
     * Change auto feed checker value event
     * @type {EventEmitter<any>}
     */
    @Output() isAutoFeedChange = new EventEmitter();

    /**
     * File browser component in view
     */
    @ViewChild(FileBrowserComponent, { static: true })
    fileBrowser: FileBrowserComponent;

    /**
     * Auto feed checker in view
     */
    @ViewChild("isAutoFeed") autoFeedCheckbox: ElementRef;

    /**
     * Editor components in view
     */
    @ViewChildren(EditorComponent) editors: QueryList<EditorComponent>;

    /**
     * Material Expansion Panels in view
     */
    @ViewChildren(MatExpansionPanel) panels: QueryList<MatExpansionPanel>;

    /**
     * Post form control names
     */
    postControlNames = { ...PostFormControlNames };

    /**
     * Material Expansion Panel names
     */
    expansionPanelNames = { ...PostFormExpansionPanels };

    excludedExpansionPanels = [];
    excludedPanelByKey: { [key: string]: boolean } = {}; // to help rendering, we use a variable instead a function

    targetingOptions; // to help rendering...
    feedTargetingInitialValue; // to help rendering...

    mediaError = false;

    messageTitle: string = "schedule.message";
    headlineTitle: string = "schedule.headline";

    /**
     * Config for Expansion Panels/Post Form Controls relationship
     * @type {{[p: string]: string[] | string[] | string[] | string[] | string[] | string[]}}
     */
    expansionPanelHasFormControls = {
        [this.expansionPanelNames.Headline]: [this.postControlNames.Headline],
        [this.expansionPanelNames.SubHeadline]: [this.postControlNames.SubHeadline],
        [this.expansionPanelNames.Message]: [this.postControlNames.Message],
        [this.expansionPanelNames.FirstComment]: [this.postControlNames.FirstComment],
        [this.expansionPanelNames.LinkShare]: [
            this.postControlNames.LinkShareTitle,
            this.postControlNames.LinkShareDescription,
            this.postControlNames.LinkShareURL,
            this.postControlNames.LinkShareImageURL,
        ],
        [this.expansionPanelNames.Signature]: [this.postControlNames.Signature],
        [this.expansionPanelNames.NewsFeedTargeting]: [this.postControlNames.FeedTargetingOptions],
        [this.expansionPanelNames.PostRepeat]: [this.postControlNames.ExpiryPeriod, this.postControlNames.ExpiryEndDate],
        [this.expansionPanelNames.Schedule]: [
            this.postControlNames.ActiveFromDate,
            this.postControlNames.ActiveFromHour,
            this.postControlNames.ActiveFromMinute,
            this.postControlNames.AutoFeed,
        ],
        [this.expansionPanelNames.InstagramLocation]: [this.postControlNames.InstagramLocationID, this.postControlNames.InstagramLocationName],
    };

    /**
     * Post form group setting
     * @type {FormGroup}
     */
    postFormGroup = new FormGroup({
        [this.postControlNames.Headline]: this.getNewFormControl(this.postControlNames.Headline),
        [this.postControlNames.SubHeadline]: this.getNewFormControl(this.postControlNames.SubHeadline),
        [this.postControlNames.Message]: this.getNewFormControl(this.postControlNames.Message),
        [this.postControlNames.FirstComment]: this.getNewFormControl(this.postControlNames.FirstComment),
        [this.postControlNames.Signature]: this.getNewFormControl(this.postControlNames.Signature),
        [this.postControlNames.LinkShareTitle]: this.getNewFormControl(this.postControlNames.LinkShareTitle),
        [this.postControlNames.LinkShareDescription]: this.getNewFormControl(this.postControlNames.LinkShareDescription),
        [this.postControlNames.LinkShareURL]: this.getNewFormControl(this.postControlNames.LinkShareURL),
        [this.postControlNames.LinkShareImageURL]: this.getNewFormControl(this.postControlNames.LinkShareImageURL),
        [this.postControlNames.FeedTargetingOptions]: this.getNewFormControl(this.postControlNames.FeedTargetingOptions),
        [this.postControlNames.ExpiryPeriod]: this.getNewFormControl(this.postControlNames.ExpiryPeriod),
        [this.postControlNames.ExpiryEndDate]: this.getNewFormControl(this.postControlNames.ExpiryEndDate),
        [this.postControlNames.ActiveFromDate]: this.getNewFormControl(this.postControlNames.ActiveFromDate),
        [this.postControlNames.ActiveFromHour]: this.getNewFormControl(this.postControlNames.ActiveFromHour),
        [this.postControlNames.ActiveFromMinute]: this.getNewFormControl(this.postControlNames.ActiveFromMinute),
        [this.postControlNames.AutoFeed]: this.getNewFormControl(this.postControlNames.AutoFeed),
        [this.postControlNames.Medias]: this.getNewFormControl(this.postControlNames.Medias),
        [this.postControlNames.IsCopy]: this.getNewFormControl(this.postControlNames.IsCopy),
        [this.postControlNames.SeparateRepeat]: this.getNewFormControl(this.postControlNames.SeparateRepeat),
        [this.postControlNames.InstagramLocationID]: this.getNewFormControl(this.postControlNames.InstagramLocationID),
        [this.postControlNames.InstagramLocationName]: this.getNewFormControl(this.postControlNames.InstagramLocationName),
    });

    mediaFormGroup = new FormGroup({
        [this.postControlNames.InstagramPostType]: this.getNewFormControl(this.postControlNames.InstagramPostType),
        [this.postControlNames.FacebookPostType]: this.getNewFormControl(this.postControlNames.FacebookPostType),
        [this.postControlNames.Size]: this.getNewFormControl(this.postControlNames.Size),
        [this.postControlNames.Duration]: this.getNewFormControl(this.postControlNames.Duration),
    });

    /**
     * Default Expansion Panel statuses
     * @type {{[p: string]: boolean}}
     */
    expansionPanelStates = {
        [this.expansionPanelNames.Message]: true,
        [this.expansionPanelNames.GmbOptions]: true,
    };
    /**
     * Post form error messages
     * @type {{}}
     */
    postFormErrorMessages = {};

    /**
     * Subscriptions
     * Unsubscribe on ngOnDestroy
     * @type {any[]}
     */
    subscriptions: Subscription[] = [];

    /**
     * Component onInited status
     * @type {boolean}
     */
    onInited = false;

    /**
     * Logged user data
     * @type {LoggedUserInterface}
     */
    user: LoggedUserInterface = LoggedUser.getUser();

    /**
     * Form controls error matcher
     * @type {MyErrorStateMatcher}
     */
    errorMatcher = new MyErrorStateMatcher();

    autoLinkshareEnabled = false;

    /**
     * Has link share status
     * @type {boolean}
     */
    _hasLinkShare = false;

    /**
     * Instagram location plugin propertis
     */
    allowInstaLocationPlugin: boolean = false;
    instaLocationValue = null;

    get hasLinkShare(): boolean {
        return this._hasLinkShare;
    }

    set hasLinkShare(value: boolean) {
        this._hasLinkShare = value;

        if (this.socialMediaType === SOCIAL_MEDIA_TYPE_TWITTER) {
            this.disableMediaBrowser = value;
        }
    }

    get selectedTemplateIsGeneric(): boolean {
        return this.selectedTemplate && this.selectedTemplate["systemType"] === CoreConfig.getSystemTypes().Generic;
    }

    get selectedTemplateID() {
        return this.selectedTemplate ? this.selectedTemplate.templateID : null;
    }

    /**
     * Meta data loading status
     * @type {boolean}
     */
    isMetaDataLoading = false;

    /**
     * Was form value change status
     * @type {boolean}
     */
    wasFormChange = false;

    /**
     * When false, not set link share properties on edit
     * @type {boolean}
     */
    setLinkShareAvailable = true;

    /**
     * Number of post characters
     * @type {number}
     */
    characterNumber = 0;

    /**
     * @type {boolean}
     */
    characterNumberIsInvalid = false;

    openedComponent: ComponentRef<any>;

    partnerPermissions = PartnerPermissions;

    originPostContentHash: string;
    currentPostContentHash: string;

    editorInstances: { [key: string]: EditorComponent } = {};

    hour;
    minute;

    showMedia = false;

    /**
     * Check current post source is rss
     *
     * @type {boolean}
     */
    isPostFromRss = false;

    /**
     * Social media platform config
     *
     * @type {null}
     */
    socialMediaConfig: SocialMediaPlatform = null;

    /**
     * Post content max length
     *
     * @type {null}
     */
    postContentMaxLength: number = null;

    /**
     * @type {boolean}
     */
    enableSeparateRepeatCheckbox = true;

    /**
     * @type {boolean}
     */
    isCustomSystemType = false;

    selectedMentions: { [key: string]: IMentionViewItem[] } = {};

    hoursOptions = CommonPostHelpers.getHourSelectOptions();
    minutesOptions = CommonPostHelpers.getMinuteSelectOptions();
    availableHours = this.hoursOptions.map((item) => {
        return { value: item, isEnabled: true };
    });
    availableMinutes = this.minutesOptions.map((item) => {
        return { value: item, isEnabled: true };
    });
    availableInterval = null;
    todaysDate = new Date();

    /**
     * Disable media selection
     * @type {boolean}
     */
    disableMediaBrowser = false;

    tinyMCEInitConfigs = {
        [this.postControlNames.Headline]: {},
        [this.postControlNames.SubHeadline]: {},
        [this.postControlNames.Message]: {},
        [this.postControlNames.Signature]: {},
        [this.postControlNames.FirstComment]: {},
    };
    tinyMCEInitCompleted: boolean = false;

    postFormGroupContains: { [key: string]: boolean } = {};

    private _originLinkShareDatas = {
        [this.postControlNames.LinkShareTitle]: "",
        [this.postControlNames.LinkShareDescription]: "",
        [this.postControlNames.LinkShareImageURL]: "",
    };

    private _socialSites = [];

    instaPostTypeConfig = [
        {
            name: "Feed",
            value: "feed",
            disabled: true,
        },
        {
            name: "Story",
            value: "story",
            disabled: true,
        },
        {
            name: "Reel",
            value: "reel",
            disabled: true,
        },
        {
            name: "Reel & Feed",
            value: "reelAndFeed",
            disabled: true,
        },
    ];
    facebookPostTypeConfig = [
        {
            name: "Feed",
            value: "feed",
            disabled: true,
        },
        {
            name: "Story",
            value: "story",
            disabled: true,
        },
        {
            name: "Reel",
            value: "reel",
            disabled: true,
        },
        {
            name: "Reel & Feed",
            value: "reelAndFeed",
            disabled: true,
        },
    ];

    constructor(
        public languageService: LanguageService,
        public openModalService: OpenModalService,
        private urlOpenGraphService: UrlOpenGraphService,
        private scrollTo: ScrollToService,
        private componentInjector: ComponentInjector,
        public partnerConfig: PartnerConfigService,
        public emojiPickerService: EmojiPickerService,
        public autocompleteService: AutocompleteService,
        public resourceService: ResourceService,
        public linkShorteningService: LinkShorteningService,
        public ngZone: NgZone,

    ) {
        this.initialize();
    }

    /**
     * Prepare post content before send backend
     * @param content
     */
    public static postContentPrepareBeforeSend(content: string) {
        if (typeof content !== "string") {
            return content;
        }

        // content = StringSupport.trimLineBreaks(content);

        // replace 'br' and close tags with '\n'
        content = content.replace(/<br(.*?)>/g, "\n");

        // remove div elements
        content = StringSupport.removeSpecifyTags(content, ["div"]);

        // remove whitespaces and line breaks from both ends of string
        content = content.trim();

        content = Utils.htmlEntities.decode(content);

        // remove p html tags
        content = StringSupport.removeSpecifyTags(content, ["p"]);

        return content;
    }

    ngOnInit() {
        this.initializeOnInit();
    }

    ngAfterViewInit(): void { }

    ngOnDestroy(): void {
        this.editors.forEach((editor) => editor.ngOnDestroy());
        this.subscriptions.forEach((sub) => sub.unsubscribe());
        if (this.availableInterval) {
            clearInterval(this.availableInterval);
        }
    }

    @Debounce(500)
    parseMessage(event, force = false, socialType: string = null) {
        if (!this.autoLinkshareEnabled) {
            return;
        }

        let text = this.postFormGroup.get(this.postControlNames.Message).value || "";

        // not set link share properties on edit when linkshareIncluded is false
        if (!this.setLinkShareAvailable) {
            this.setLinkShareAvailable = true;
            return;
        }

        text = text.replace(/(<(a|span)(.*?)>|<\/(a|span)>)/g, "");

        const urls = getUrls(text);

        if (urls.size > 0) {
            if (!this.postFormGroup.get(this.postControlNames.LinkShareURL).value || force) {
                const lastUrl = CommonPostHelpers.getLastUrlFrom(urls);

                this.isMetaDataLoading = true;
                this.removeLinkshareProperties();
                socialType = socialType || this.socialMediaType;

                const requests = [];
                const ogRequest = () => this.urlOpenGraphService.getData(lastUrl);
                const twitterRequest = () => this.urlOpenGraphService.getData(lastUrl, OPEN_GRAPH_TYPE_TWITTER);

                switch (socialType) {
                    case SOCIAL_MEDIA_TYPE_TWITTER:
                        requests.push(twitterRequest());

                        break;

                    case "default":
                        requests.push(...[ogRequest(), twitterRequest()]);

                        break;

                    default:
                        requests.push(ogRequest());

                        break;
                }

                Promise.all(requests)
                    .then((responses) => {
                        const isTwitter = socialType === SOCIAL_MEDIA_TYPE_TWITTER;
                        let ogResponse, twitterResponse;
                        if (requests.length === 2) {
                            [ogResponse, twitterResponse] = responses;
                        } else {
                            if (isTwitter) {
                                [twitterResponse] = responses;
                            } else {
                                [ogResponse] = responses;
                            }
                        }

                        const formData = isTwitter ? twitterResponse["og"] : ogResponse["og"];

                        this._setOriginLinkShare({
                            ...Utils.get(ogResponse, "og", Utils.get(twitterResponse, "og", {})),
                            url: lastUrl,
                            twitter: {
                                ...Utils.get(twitterResponse, "og", {}),
                                url: lastUrl,
                            },
                        });
                        this.setLinkshareDataToFormInput(formData, lastUrl);
                        this.isMetaDataLoading = false;
                    })
                    .catch(() => {
                        this.isMetaDataLoading = false;
                    });
            }
        } else {
            if (!this.hasLinkShare) {
                this.removeLinkshareProperties();
            }
        }
    }

    public refreshAvailableTimes() {
        const siteMoments = [];
        this.selectedSocialSites.forEach((site) => {
            if (this.socialMediaType !== "default" && site.socialType !== this.socialMediaType) {
                return;
            }
            // Hack, hogy más időzónákhoz más timestampeket kapjunk, és könnyen össze tudjuk hasonlítani őket.
            siteMoments.push(moment(moment.tz(moment().utc(), site.timezone).format("YYYY-MM-DD HH:mm").toString()));
        });
        let currentTime = moment.max(siteMoments).unix(); // Ha nincs site választva, local time lesz.

        if (siteMoments.length > 0) {
            this.todaysDate = moment.unix(currentTime).toDate();
        } else {
            // If no social sites are selected, use the user's default timezone setting to change the minimum day & time.
            currentTime = moment(
                moment
                    .tz(moment().utc(), this.user.settings.defaultTimeZone || DateSupport.getClientTimeZoneName())
                    .format("YYYY-MM-DD HH:mm")
                    .toString()
            ).unix();
            this.todaysDate = moment(
                moment
                    .tz(moment().utc(), this.user.settings.defaultTimeZone || DateSupport.getClientTimeZoneName())
                    .format("YYYY-MM-DD HH:mm")
                    .toString()
            ).toDate();
        }

        this.availableMinutes.forEach((_, index) => {
            this.availableMinutes[index].isEnabled = true;
        });
        this.availableHours.forEach((_, index) => {
            this.availableHours[index].isEnabled = true;
        });

        this.postFormGroup.get(this.postControlNames.ActiveFromDate)
            ? this.postFormGroup
                .get(this.postControlNames.ActiveFromDate)
                .setErrors(moment(this.getActiveFrom()).isBefore(moment.unix(currentTime), "day") ? { invalid: true } : null)
            : null;
        this.postFormGroup.get(this.postControlNames.ActiveFromHour) ? this.postFormGroup.get(this.postControlNames.ActiveFromHour).setErrors(null) : null;
        this.postFormGroup.get(this.postControlNames.ActiveFromMinute) ? this.postFormGroup.get(this.postControlNames.ActiveFromMinute).setErrors(null) : null;

        const currentHour = this.postFormGroup.get(this.postControlNames.ActiveFromHour);
        const currentMinute = this.postFormGroup.get(this.postControlNames.ActiveFromMinute);
        for (let index = 0; index < this.availableHours.length; index++) {
            const element = this.availableHours[index];
            if (moment(this.getActiveFrom(element.value.toString(), "55")).unix() < currentTime + 600) {
                // 10 perc
                if (currentHour.value == element.value) {
                    currentHour.setErrors({ invalid: true });
                }
                element.isEnabled = false;
            } else {
                element.isEnabled = true;
            }
        }
        for (let index = 0; index < this.availableMinutes.length; index++) {
            const element = this.availableMinutes[index];
            if (moment(this.getActiveFrom(null, element.value.toString())).unix() < currentTime + 600) {
                if (currentMinute.value == element.value) {
                    currentMinute.setErrors({ invalid: true });
                }
                element.isEnabled = false;
            } else {
                element.isEnabled = true;
            }
        }
    }

    /**
     * @param {FileBrowserSelectionChange} event
     * @param {string} socialType
     */
    mediaSelectionChange(event: FileBrowserSelectionChange, socialType: string = null) {
        this.postFormGroup.get(this.postControlNames.Medias).setValue(event.selectedFiles);
        this.setSelectedMedias.emit(event.selectedFiles);

        socialType = socialType || this.socialMediaType;

        if (socialType === SOCIAL_MEDIA_TYPE_TWITTER) {
            const isFileSelect = event.eventType === FileBrowserSelectionChangeTypes.SelectFile;
            const isFileUnselect = event.eventType === FileBrowserSelectionChangeTypes.UnselectFile;
            const isOGImage = event.file.type === FILE_TYPES.OG;

            if (isFileUnselect && isOGImage) {
                this.removeLinkshareProperties({
                    modifyMedias: false,
                });
            }

            if (isFileSelect && isOGImage) {
                this.setLinkshareDataToFormInput(this._originLinkShareDatas, "", {
                    modifyMedias: false,
                });
            }
        }

        // Enable/disable instagram post type options based on selected medias
        if (
            socialType === SOCIAL_MEDIA_TYPE_INSTAGRAM
        ) {
            if (event.selectedFiles.length === 1) {
                const media = event.selectedFiles[0];
                if (media.type === "video") {
                    // set each option enabled
                    this.instaPostTypeConfig.forEach((config) => {
                        config.disabled = config.value !== "reelAndFeed" && config.value !== "reel" && config.value !== "story";;
                    });
                    this.mediaFormGroup.get(this.postControlNames.InstagramPostType).setValue("reel");
                } else if (media.type === "image") {
                    // make each insta post type config disabled except feed and story
                    // check image ratio too for setting story and feed enabled
                    this.instaPostTypeConfig.forEach((config) => {
                        config.disabled = config.value !== "feed" && config.value !== "story";
                    });
                    this.mediaFormGroup.get(this.postControlNames.InstagramPostType).setValue("feed");
                }
                // set feed as default

            } else if (event.selectedFiles.length > 1) {
                // make each insta post type config disabled except feed
                this.instaPostTypeConfig.forEach((config) => {
                    config.disabled = config.value !== "feed";
                });
                // set feed as default
                this.mediaFormGroup.get(this.postControlNames.InstagramPostType).setValue("feed");
            } else {
                // make each insta post type config disabled
                this.instaPostTypeConfig.forEach((config) => {
                    config.disabled = true;
                });
                // set value to null
                this.mediaFormGroup.get(this.postControlNames.InstagramPostType).setValue(null);
            }
        }
        // Enable/disable facebook post type options based on selected medias
        if (
            this.socialMediaType === SOCIAL_MEDIA_TYPE_FACEBOOK
        ) {
            if (event.selectedFiles.length === 1) {
                const media = event.selectedFiles[0];
                if (media.type === "video") {
                    // set each option enabled
                    this.facebookPostTypeConfig.forEach((config) => {
                        config.disabled = false;
                    });
                } else if (media.type === "image") {
                    // make each insta post type config disabled except feed and story
                    // check image ratio too for setting story and feed enabled
                    this.facebookPostTypeConfig.forEach((config) => {
                        config.disabled = config.value !== "feed" && config.value !== "story";
                    });
                }
                // set feed as default
                this.mediaFormGroup.get(this.postControlNames.FacebookPostType).setValue("feed");
            } else if (event.selectedFiles.length > 1) {
                // make each insta post type config disabled except feed
                this.facebookPostTypeConfig.forEach((config) => {
                    config.disabled = config.value !== "feed";
                });
                // set feed as default
                this.mediaFormGroup.get(this.postControlNames.FacebookPostType).setValue("feed");
            } else {
                // make each insta post type config disabled
                this.facebookPostTypeConfig.forEach((config) => {
                    config.disabled = true;
                });
                // set value to null
                this.mediaFormGroup.get(this.postControlNames.FacebookPostType).setValue(null);
            }
        }
        // Instagram media ratio check
        if (
            socialType === SOCIAL_MEDIA_TYPE_INSTAGRAM ||
            (socialType === "default" && this.selectedSocialSites.some((site) => site.socialType === SOCIAL_MEDIA_TYPE_INSTAGRAM))
        ) {
            const isFileSelect = event.eventType === FileBrowserSelectionChangeTypes.SelectFile;
            const isFileUnselect = event.eventType === FileBrowserSelectionChangeTypes.UnselectFile;

            if (isFileSelect && event.file.type === "image") {
                const ratioSplit = event.file.ratio.split(":");
                const ratio = Number(ratioSplit[0]) / Number(ratioSplit[1]);
                const postType = this.mediaFormGroup.get(this.postControlNames.InstagramPostType).value;

                if ((ratio < 0.8 || ratio > 1.91) && postType !== "story") {
                    const error = {
                        instagram: {
                            medias: LanguageService.getLine("post.template.error.image.ratio"),
                        },
                    };
                    this.mediaError = true;
                    this.setErrorTitle.emit("Validation error(s)");
                    this.setErrorMessages.emit(error);
                }
            }

            if (isFileUnselect) {
                this.mediaError = false;
                this.setErrorTitle.emit(null);
                this.setErrorMessages.emit(null);
            }
        }
        // facebook media ratio check
        if (
            this.socialMediaType === SOCIAL_MEDIA_TYPE_FACEBOOK ||
            (socialType === "default" && this.selectedSocialSites.some((site) => site.socialType === SOCIAL_MEDIA_TYPE_FACEBOOK))
        ) {
            const isFileSelect = event.eventType === FileBrowserSelectionChangeTypes.SelectFile;
            const isFileUnselect = event.eventType === FileBrowserSelectionChangeTypes.UnselectFile;

            if (isFileSelect && event.file.type === "image") {
                const ratioSplit = event.file.ratio.split(":");
                const ratio = Number(ratioSplit[0]) / Number(ratioSplit[1]);
                const postType = this.mediaFormGroup.get(this.postControlNames.FacebookPostType).value;

                if (postType !== "story") {

                    const mime = event.file.rawMedia.mime;
                    // const size = event.file.rawMedia.bytes;

                    console.log('event.file', event.file);

                    const ratio = Number(ratioSplit[0]) / Number(ratioSplit[1]);
                    const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/bmp', 'image/gif', 'image/tiff'];
                    if (!allowedMimeTypes.includes(mime)) {
                        const error = {
                            facebook: {
                                medias: LanguageService.getLine("post.facebook.validation.invalidFileType"),
                            },
                        };
                        this.mediaError = true;
                        this.setErrorTitle.emit("Validation error(s)");
                        this.setErrorMessages.emit(error);
                    } else {
                        this.mediaError = false;
                        this.setErrorTitle.emit(null);
                        this.setErrorMessages.emit(null);
                    }

                }
            }
            if (isFileSelect && event.file.type === "video") {

                const ratioSplit = event.file.ratio.split(":");
                const ratio = Number(ratioSplit[0]) / Number(ratioSplit[1]);
                const postType = this.mediaFormGroup.get(this.postControlNames.FacebookPostType).value;

                const image = new Image();
                image.src = event.file.rawMedia.previewFileUrl;
                image.onload = function () {
                }
                const width = image.width;
                const height = image.height;
                const aspectRatio = width / height;
                const videoratio = aspectRatio.toFixed(2);

                const videoUrl = event.file.rawMedia.url;
                const video = document.createElement('video');
                video.src = videoUrl;
                video.addEventListener('loadedmetadata', () => {


                    this.mediaFormGroup.get(this.postControlNames.Duration).setValue(video.duration);
                });


                if (width < 540 || height < 960 && width > 1080 || height > 1920) {
                    if (postType == "story") {
                        const error = {
                            facebook: {
                                medias: LanguageService.getLine("post.adapter.facebook.story.video.format"),
                            },
                        };
                        this.mediaError = true;
                        this.setErrorTitle.emit("Validation error(s)");
                        this.setErrorMessages.emit(error);
                    } else if (postType == "reel" || postType == "reelAndFeed") {
                        const error = {
                            facebook: {
                                medias: LanguageService.getLine("post.adapter.facebook.reel.video.format"),
                            },
                        };
                        this.mediaError = true;
                        this.setErrorTitle.emit("Validation error(s)");
                        this.setErrorMessages.emit(error);
                    } else {
                        this.mediaError = false;
                        this.setErrorTitle.emit(null);
                        this.setErrorMessages.emit(null);
                    }
                }

                if (video.duration < 3 || video.duration > 90) {

                    if (postType == "story") {
                        const error = {
                            facebook: {
                                medias: LanguageService.getLine("post.adapter.facebook.story.video.format"),
                            },
                        };
                        this.mediaError = true;
                        this.setErrorTitle.emit("Validation error(s)");
                        this.setErrorMessages.emit(error);
                    } else if (postType == "reel" || postType == "reelAndFeed") {
                        const error = {
                            facebook: {
                                medias: LanguageService.getLine("post.adapter.facebook.reel.video.format"),
                            },
                        };
                        this.mediaError = true;
                        this.setErrorTitle.emit("Validation error(s)");
                        this.setErrorMessages.emit(error);
                    } else {
                        this.mediaError = false;
                        this.setErrorTitle.emit(null);
                        this.setErrorMessages.emit(null);
                    }

                }
                if (videoratio !== "0.56") {

                    if (postType == "story") {
                        const error = {
                            facebook: {
                                medias: LanguageService.getLine("post.adapter.facebook.story.video.format"),
                            },
                        };
                        this.mediaError = true;
                        this.setErrorTitle.emit("Validation error(s)");
                        this.setErrorMessages.emit(error);
                    } else if (postType == "reel" || postType == "reelAndFeed") {
                        const error = {
                            facebook: {
                                medias: LanguageService.getLine("post.adapter.facebook.reel.video.format"),
                            },
                        };
                        this.mediaError = true;
                        this.setErrorTitle.emit("Validation error(s)");
                        this.setErrorMessages.emit(error);
                    } else {
                        this.mediaError = false;
                        this.setErrorTitle.emit(null);
                        this.setErrorMessages.emit(null);
                    }

                }


            }

            if (isFileUnselect) {
                this.mediaError = false;

                this.setErrorTitle.emit(null);
                this.setErrorMessages.emit(null);
            }
        }
        const selectedFiles = event.selectedFiles || [];

        if (
            socialType === SOCIAL_MEDIA_TYPE_GMB ||
            (socialType === "default" && this.selectedSocialSites.some((site) => site.socialType === SOCIAL_MEDIA_TYPE_GMB))
        ) {
            const isFileSelect = event.eventType === FileBrowserSelectionChangeTypes.SelectFile;
            const isFileUnselect = event.eventType === FileBrowserSelectionChangeTypes.UnselectFile;

            const isVideo = event.file.type === "video";
            const multipleFilesSelected = selectedFiles.length > 1;

            if (isFileSelect) {
                if (isVideo) {
                    this.setInfoMessages.emit({
                        gmb: {
                            message: "Currently GMB API doesn't support video attachments. Only single images allowed. The selected video is removed from the GMB tab. If you want to attach a media element, please add a single image instead on the GMB tab."
                        }
                    });

                } else if (multipleFilesSelected) {
                    this.setInfoMessages.emit({
                        gmb: {
                            message: "Currently GMB API doesn't support video attachments. Only single images allowed. The selected video is removed from the GMB tab. If you want to attach media element please add a single image instead on the GMB tab."
                        }
                    });

                } else {
                    this.setInfoMessages.emit(null);
                }
            }

            if (isFileUnselect) {
                this.setInfoMessages.emit(null);
            }
        }
    }

    /**
     * @param {MentionSelectionChangeEvent} event
     * @param {string} controlName
     */
    mentionSelectionChange(event: MentionSelectionChangeEvent, controlName: string): void {
        this.selectedMentions[controlName] = event.items;
    }

    // force linkshare
    checkMetaData(e?) {
        if (e) {
            e.stopPropagation();
        }

        this.autoLinkshareEnabled = true;

        const messageData = Utils.replaceHTMLToSymbol(this.postFormGroup.get(this.postControlNames.Message).value);

        if (messageData && messageData !== "") {
            this.parseMessage(
                {
                    event: {
                        target: {
                            innerText: Helpers.strip(messageData),
                        },
                    },
                },
                true
            );
        }
    }

    removeLinkshare(e) {
        e.stopPropagation();
        this.openModalService
            .confirmModal(DialogConfirmComponent, {
                message: LanguageService.getLine("post.linkShare.confirmDelete.message"),
                yesButtonText: LanguageService.getLine("post.linkShare.confirmDelete"),
            })
            .afterClosed()
            .subscribe((doIt) => {
                if (doIt) {
                    // after removing a linkshare, remove automatic linkshare detection
                    this.autoLinkshareEnabled = false;

                    // only remove the properties after setting autolinkshare to false, so it emits the valueChange event correctly > so copying tabs will be fine
                    this.removeLinkshareProperties();
                }
            });
    }

    /**
     * Set news feed targeting options on post form group
     * @param event
     */
    setTargetingOptions(event) {
        this.postFormGroup.get(this.postControlNames.FeedTargetingOptions).setValue(event);
    }

    /**
     * Get new feed targeting options
     * @return {string}
     */
    getTargetingOptions() {
        return this.formData && this.formData[this.postControlNames.FeedTargetingOptions] ? this.formData[this.postControlNames.FeedTargetingOptions] : "";
    }

    /**
     * Change panel expanded state event
     * @param {string} controlName
     */
    changePanelExpandedState(currentPanelName?: string, isValidity = false) {
        // Megadom a kivételeket, amelyeknél nem szükséges az összecsúkás
        const panelExceptions = [this.expansionPanelNames.Message, this.expansionPanelNames.GmbOptions];

        if (currentPanelName) {
            panelExceptions.push(currentPanelName);
        }

        for (const panelName in this.expansionPanelHasFormControls) {
            const controlNames = this.expansionPanelHasFormControls[panelName];
            let isFilled = false;
            let currentPanel;

            if (this.panels) {
                // Nem szép, de máshogy nem sikerült elkérnem az ID-t
                currentPanel = this.panels.find((panel) => panel["_viewContainerRef"].element.nativeElement.id === panelName);
            }

            // Ha a panel, amelyikre klikkeltem nem tartozik a kivételek közé, megvizsglálom, hogy összecsúkható-e
            if (!includes(panelExceptions, panelName)) {
                for (const controlName of controlNames) {
                    if (this.isFilledFormControl(controlName)) {
                        isFilled = true;
                    }
                }

                // Ha a panel nem tartalmaz kitöltött input-ot, összecsúkom
                if (!isFilled) {
                    if (currentPanelName && currentPanel) {
                        currentPanel.close();
                    }

                    this.expansionPanelStates[panelName] = false;
                } else if (!currentPanelName) {
                    this.expansionPanelStates[panelName] = true;
                }
            } else if (!currentPanelName) {
                this.expansionPanelStates[panelName] = true;
            }

            if (isValidity) {
                let hasError = false;
                for (const controlName of controlNames) {
                    const formControl = this.postFormGroup.get(controlName);

                    if (formControl && formControl.invalid) {
                        hasError = true;
                    }
                }

                if (hasError) {
                    if (currentPanel) {
                        currentPanel.open();
                    }
                    this.expansionPanelStates[panelName] = true;
                }
            }
        }
    }

    /**
     * This form is visible
     * @return {boolean}
     */
    isActiveTab() {
        return this.currentTabIndex === this.tabPosition;
    }

    /**
     * Check form control is filled by form control name
     * @param {string} controlName
     * @return {boolean}
     */
    isFilledFormControl(controlName: string) {
        return !!this.postFormGroup.get(controlName) && !!this.postFormGroup.get(controlName).value;
    }

    /**
     * Change auto feed checked event
     * If checked, set activeFromDate, activeFromHour, activeFromMinute controls state for disable
     */
    changeAutoFeedChecked(isClick?) {
        if (this.isEntityCommentMode) {
            return;
        }

        if (isClick) {
            this.autoFeedCheckbox.nativeElement.click();
        }

        let checked = this.postFormGroup.get(
            // const?
            this.postControlNames.AutoFeed
        ).value;
        this.isAutoFeedChange.emit(checked);
        const state = checked ? "disable" : "enable";
        const controlNames = [this.postControlNames.ActiveFromDate, this.postControlNames.ActiveFromHour, this.postControlNames.ActiveFromMinute];

        controlNames.forEach((controlName) => {
            this.postFormGroup.get(controlName)[state]();
            this.postFormGroup.get(controlName).updateValueAndValidity({ emitEvent: true });
        });
    }

    /**
     * Inject cube component and prepare
     * @param event
     * @param controlName
     * @param postProperty
     */
    openCube(event, controlName, postProperty) {
        event.preventDefault();
        event.stopPropagation();

        this.openedComponent = this.componentInjector.insertComponent(this.cubeTemplate, CubeParentComponent);

        (<CubeParentComponent>this.openedComponent.instance).elemFilter = postProperty || "headline";
        (<CubeParentComponent>this.openedComponent.instance).elementRef = this.openedComponent;
        (<CubeParentComponent>this.openedComponent.instance).filters = this.postTemplateFilters;
        (<CubeParentComponent>this.openedComponent.instance).inputElementRef = this.postFormGroup.controls[controlName];
    }

    /**
     * Get current entity
     * @return {null}
     */
    getCurrentEntityID() {
        const IDKeys = {
            post: "postID",
            postTemplate: "templateID",
        };
        return this.currentPost ? this.currentPost[IDKeys[this.objectType]] : null;
    }

    fileBrowserInitialized() {
        const buttonElement = document.querySelector("#checkHasLinkButton");

        if (!!buttonElement && this.isPostFromRss) {
            buttonElement.dispatchEvent(new Event("click"));
        }
    }

    /**
     * Get data of post form for request
     * @param {PostSkeletonGetDataForRequestOptions} options
     * @returns {{type: string; activeFrom: string}}
     */
    getDataForRequest(options: PostSkeletonGetDataForRequestOptions = {}) {
        const formData = this.postFormGroup.getRawValue();
        // add mediaFormGroup to postFormGroup
        if (this.mediaFormGroup) {
            formData.extraMediaData = this.mediaFormGroup.getRawValue();
        }
        if (this.mediaFormGroup) {
            formData.extraMediaData = this.mediaFormGroup.getRawValue();
        }
        const result = {
            type: "customFeed",
            activeFrom: this.getActiveFrom(),
        };
        const removeAbleControlNames = [this.postControlNames.ActiveFromDate, this.postControlNames.ActiveFromHour, this.postControlNames.ActiveFromMinute];

        options = {
            removeMedias: true,
            replaceLineBreakSign: true,
            ...options,
        };

        if (has(options, "removeMedias") && !!options.removeMedias) {
            removeAbleControlNames.push(this.postControlNames.Medias);
        }

        for (const controlName in formData) {
            let value = formData[controlName];

            if (controlName === this.postControlNames.AutoFeed) {
                if (value) {
                    result["type"] = "autoFeed";
                    delete result["activeFrom"];
                }

                continue;
            }

            if (controlName === this.postControlNames.ExpiryPeriod && value) {
                value = this.periodConvert(value);
            }

            if (controlName === this.postControlNames.ExpiryEndDate && value) {
                value = value ? moment(value).format(BASE_DATE_FORMAT) : value;
            }

            if (controlName === this.postControlNames.Medias) {
                const filesIDKey = this.objectType === "post" ? MediaPostRequestKeys.fileIDs : MediaPostTemplateRequestKeys.fileIDs;
                const carouselIDKey = this.objectType === "post" ? MediaPostRequestKeys.carouselIDs : MediaPostTemplateRequestKeys.carouselIDs;
                const medias = this.fileBrowser.getSelectedFiles();
                const ogImages = medias.filter((item) => item.type === FILE_TYPES.OG);
                const addedLinkShareData: boolean = !!this.postFormGroup.get(this.postControlNames.LinkShareTitle).value;

                if (medias && medias.length && !ogImages.length) {
                    const fileIDs = medias.filter((media) => media.type !== FILE_TYPES.CAROUSEL).map((media) => media.mediaID);
                    const carouselIDs = medias.filter((media) => media.type === FILE_TYPES.CAROUSEL).map((media) => media.mediaID);

                    if (!!carouselIDs.length) {
                        result[carouselIDKey] = carouselIDs[0];
                    }

                    if (!!fileIDs.length) {
                        result[filesIDKey] = fileIDs;
                    }

                    result[this.postControlNames.LinkShareImageURL] = "";
                }

                if (medias?.length === 0 && ogImages?.length === 0) {
                    result[this.postControlNames.LinkShareImageURL] = "";
                }

                if (!!medias && !!medias.length && !addedLinkShareData) {
                    value = medias.filter((media) => media.type !== FILE_TYPES.OG);
                }

                if (ogImages.length && addedLinkShareData && !!medias && !!medias.length) {
                    const url = Utils.lodash.get(medias, "[0].rawMedia.url", null);
                    const type = Utils.get(medias, "[0].rawMedia.type", null);

                    if (url) {
                        if (!(type === FILE_TYPES.OG)) {
                            result[this.postControlNames.LinkShareImageURL] = SmdFile.addDomainToMediaUrl(url);
                        } else {
                            result[this.postControlNames.LinkShareImageURL] = url;
                        }
                    } else {
                        result[this.postControlNames.LinkShareImageURL] = null;
                    }
                }
            }

            if (includes(removeAbleControlNames, controlName)) {
                continue;
            }

            if (value instanceof Array) {
                result[controlName] = value;
            } else {
                result[controlName] = !!value ? (options.replaceLineBreakSign ? PostSkeletonComponent.postContentPrepareBeforeSend(value) : value) : null;
            }

            // if feedTargetingOptions is empty, remove it from request
            if (controlName === this.postControlNames.FeedTargetingOptions && (!result[controlName] || result[controlName] === "{}")) {
                delete result[controlName];
            }
        }

        if (this.originPostContentHash && this.currentPostContentHash) {
            result[this.postControlNames.OriginPostContentHash] = this.originPostContentHash;
            result[this.postControlNames.CurrentPostContentHash] = this.currentPostContentHash;
        }

        result[PostFormControlNames.LinkShareIncluded] = this.hasLinkShare;
        result[PostFormControlNames.AutoLinkshareEnabled] = this.autoLinkshareEnabled;

        if (!options.forPreview) {
            result[PostFormControlNames.SelectedMentions] = this.getPreparedMentionsForSend();
            [PostFormControlNames.Headline, PostFormControlNames.SubHeadline, PostFormControlNames.Message, PostFormControlNames.Signature].forEach(
                (controlName) => {
                    if (result[controlName]) {
                        result[controlName] = MentionHelpers.formatMention(
                            result[controlName],
                            this.socialMediaType,
                            this.socialMediaConfig ? this.socialMediaConfig.mentionFormat || null : null
                        );
                    }
                }
            );
        } else {
            result[PostFormControlNames.SelectedMentions] = Utils.lodash.cloneDeep(this.selectedMentions);
        }

        if (this.currentPost && this.currentPost.isRepeat && !this.enableSeparateRepeatCheckbox) {
            result["repeatIndex"] = this.currentPost["repeatIndex"] || null;
        }

        return result;
    }

    /**
     * Prepare selected mentions for save in backend
     *
     * @param {{[p: string]: IMentionViewItem[]}} selectedMentions
     * @return {any}
     */
    getPreparedMentionsForSend(selectedMentions: { [key: string]: IMentionViewItem[] } = null) {
        let result = null;
        selectedMentions = selectedMentions || this.selectedMentions;

        if (selectedMentions) {
            for (const controlName in selectedMentions) {
                result = result || {};
                const mentions = Utils.lodash.cloneDeep(selectedMentions[controlName]);

                if (mentions && mentions.length) {
                    result[controlName] = mentions
                        .filter((mention) => mention.items && mention.items.length)
                        .map((mention) => {
                            const mentionItem = mention.items[0];
                            return {
                                label: mention.label,
                                id: mentionItem.id,
                                name: mentionItem.name,
                                platform: mentionItem.platform,
                                link: mentionItem.link,
                                image: mentionItem.image,
                            };
                        });
                }
            }
        }

        return result;
    }

    getSocialSite() {
        const linkedInSocialSites = this.socialSites.filter((site) => site.socialType === "linkedIn");
        let linkedInSocialSite = null;
        console.log('this.currentPost', this.currentPost);
        if (!!linkedInSocialSites && !!linkedInSocialSites.length) {
            linkedInSocialSite = linkedInSocialSites[linkedInSocialSites.length - 1];
        }

        return !!this.currentPost
            ? Utils.lodash.get(this.currentPost, ["socialSites", "0"], !!linkedInSocialSite ? { socialSiteID: linkedInSocialSite.siteID } : null)
            : !!linkedInSocialSite
                ? { socialSiteID: linkedInSocialSite.siteID }
                : null;
    }

    /**
     * Get TinyMCE config
     * @return {{skin_url: string; entity_encoding: string; force_br_newlines: boolean; force_p_newlines: boolean; forced_root_block: string; external_plugins: {smileys: string; bitly: string}; auto_convert_smileys: boolean; toolbar: string; menubar: string; setup: (editor) => void; height: string; statusbar: boolean}}
     */
    editorConfig(controlName: string) {
        const config = Utils.lodash.cloneDeep(TemplateTinyMceBaseOptions);
        const platformsHasMention = SocialMediaPlatforms.filter((platform) => platform.enableMention).map((platform) => platform.platform);
        const mentionDelimiters = [];

        if (this.resourceService.checkResource("hashtag.tag.list")) {
            mentionDelimiters.push(TINYMCE_DELIMITER_HASHTAG);
        }

        if (!this.partnerConfig.allowLinkShorten()) {
            if (Utils.lodash.has(config, "toolbar")) {

                // config["toolbar"] = config["toolbar"].replace(LINK_SHORTEN_PLUGIN_NAME, "");
            }
        }

        // enable mention usage by platform
        if ((!this.socialMediaType || this.socialMediaType === "default" || platformsHasMention.includes(this.socialMediaType)) && this.enableMention) {
            mentionDelimiters.push(TINYMCE_DELIMITER_MENTION);
        }

        const mentionsConfig = {
            ...TemplateTinyMceBaseOptions.mentions,
            delimiter: mentionDelimiters,
            source: (query, process, delimiter, showError) => {
                if (delimiter === TINYMCE_DELIMITER_MENTION) {
                    this.autocompleteService
                        .getMentions(query, this.socialMediaType, this.selectedSocialSites)
                        .then((results) => process(results))
                        .catch((error) => showError(FormValidationService.readError(error).message || "No mentions found!"));
                }

                if (delimiter === TINYMCE_DELIMITER_HASHTAG) {
                    this.autocompleteService
                        .getHashTags(query, [], this.organizationIDs)
                        .then((results) => process(results))
                        .catch((error) => showError(FormValidationService.readError(error).message || "No hashtag items found!"));
                }
            },
        };

        return {
            ...config,
            linkShortenFn: (url: string, callback: (shortUrl: string) => void, errorCallback: (message: string) => void) => {
                this.linkShorteningService
                    .getShortLink(url)
                    .then((response) => callback(Utils.get(response, "data.shortUrl", null)))
                    .catch((error) => errorCallback(FormValidationService.readError(error).message || null));
            },
            mentions: mentionsConfig,
            setup: (editor) => {
                if (this.resourceService.checkResource("hashtag.collection.list")) {
                    editor.addButton("hashtag", {
                        text: false,
                        image: ICON_PATH + "hashtag.svg",
                        tooltip: "Hashtag Collection Manager",
                        onclick: () => {
                            this.ngZone.run(() => {
                                this.openModalService.hashtagCollectionManager(HashtagCollectionManagerModalComponent);
                            });
                            return false;
                        },
                    });
                }

                if (this.socialMediaType === "default" || this.socialMediaType === SOCIAL_MEDIA_TYPE_INSTAGRAM) {
                    editor.addButton("instagramLocation2", {
                        text: false,
                        image: ICON_PATH + "instagramLocation/locationActive2.svg",
                        tooltip: "Available for Instagram posts only",
                        classes: "btn-tinymce-btn-instagram_location",
                        onclick: () => {
                            if (this.socialMediaType === SOCIAL_MEDIA_TYPE_INSTAGRAM || this.allowInstaLocationPlugin) {
                                this.ngZone.run(() => {
                                    const initValue = {
                                        locationID: this.postFormGroup.controls.instagramLocationID.value,
                                        name: this.postFormGroup.controls.instagramLocationName.value,
                                    };
                                    this.openModalService
                                        .instagramLocationModalForm(InstagramLocationCollectionManagerModalComponent, initValue)
                                        .afterClosed()
                                        .subscribe((result) => {
                                            if (result) {
                                                if (result.save && result.save === true) {
                                                    if (result.location && typeof result.location.id !== "undefined") {
                                                        this.instaLocationValue = result.location;
                                                        this.setInstagramLocationPluginIcon(true);

                                                        this.postFormGroup.patchValue({
                                                            instagramLocationID: this.instaLocationValue.id,
                                                            instagramLocationName: this.instaLocationValue.name,
                                                        });
                                                    } else {
                                                        this.instaLocationValue = undefined;
                                                        this.setInstagramLocationPluginIcon(false);

                                                        this.postFormGroup.patchValue({
                                                            instagramLocationID: "",
                                                            instagramLocationName: "",
                                                        });
                                                    }
                                                }
                                            }
                                        });
                                });
                            }
                        },
                    });
                }
                editor.on("init", () => {
                    if (this.isEntityCommentMode) {
                        editor.setMode("readonly");
                    }

                    this.editorInstances[controlName] = editor;
                });

                editor.on("Remove", (event) => {
                    this.editorInstances[controlName] = null;
                });

                editor.on("emojiPickerOpen", (event) => {
                    this.ngZone.run(() => {
                        this.emojiPickerService.show(editor, event.target);
                    });
                });
                editor.on("PostRender", (event) => {
                    this.activateInstagramLocationPlugin(this.socialMediaType === SOCIAL_MEDIA_TYPE_INSTAGRAM || this.allowInstaLocationPlugin);
                    if (
                        (this.currentPost && this.currentPost.instagramLocationID) ||
                        (this.instaLocationValue && this.instaLocationValue.id) ||
                        (this.formData && this.formData.instagramLocationID)
                    ) {
                        this.setInstagramLocationPluginIcon(true);
                    }
                });

                TemplateTinyMceBaseOptions.setup(editor);
            },
        };
    }

    public activateInstagramLocationPlugin(activate: boolean) {
        const instagramLocationButton = document.querySelectorAll(".mce-btn-tinymce-btn-instagram_location");

        if (activate) {
            // console.log('Instagram selected, need to activate instagram plugin in TyniMCE');
            instagramLocationButton.forEach((x) => {
                x.classList.remove("disabled");
                // x.querySelectorAll('button').forEach(y => { y.disabled = false; });
            });
        } else {
            // console.log('Instagram is not selected, need to deactivate instagram plugin in TyniMCE');
            instagramLocationButton.forEach((x) => {
                x.classList.add("disabled");
                // x.querySelectorAll('button').forEach(y => { y.disabled = true; });
                // x.disabled = true;
            });
        }
    }

    public setInstagramLocationPluginIcon(added: boolean) {
        if (added) {
            document.querySelectorAll(".mce-btn-tinymce-btn-instagram_location").forEach((x) => {
                x.classList.add("added");
            });
        } else {
            document.querySelectorAll(".mce-btn-tinymce-btn-instagram_location").forEach((x) => {
                x.classList.remove("added");
            });
        }
    }

    /**
     * Get this entity is post template
     * @return {boolean}
     */
    get isPostTemplate() {
        return !!this.objectType ? this.objectType === "postTemplate" : false;
    }

    get showCopyCheckbox() {
        return this.selectedSocialSites.filter((site) => site.socialType === this.socialMediaType).length > 1;
    }

    get socialSites() {
        return this._socialSites;
    }

    get fileTypes() {
        return FILE_TYPES;
    }

    private getFormGroupValues() {
        const postFormGroupValues = this.postFormGroup.getRawValue();
        const mediaFormGroupValues = this.mediaFormGroup.getRawValue();

        return {
            ...postFormGroupValues,
            ...mediaFormGroupValues,
        };
    }

    /**
     * Initialize in constructor
     */
    private initialize() {
        this.postFormGroup.valueChanges.subscribe((values) => {
            this.wasFormChange = true;

            this.setPostCharacterNumber();
            this.validityChange.emit(this.postFormGroup.valid);
            this.valueChange.emit({
                ...this.postFormGroup.getRawValue(),
                ...this.mediaFormGroup.getRawValue(),
                autoLinkshareEnabled: this.autoLinkshareEnabled,
                linkshare: this.getCurrentLinkShareData() || {},
                backupLinkshare: this.getCurrentLinkShareData() || {},
                [PostFormControlNames.SelectedMentions]: Utils.lodash.cloneDeep(this.selectedMentions),
            });

            this.currentPostContentHash = Utils.md5(JSON.stringify(this.getFormGroupValues()));
        });

        this.mediaFormGroup.valueChanges.subscribe((values) => {
            this.wasFormChange = true;

            this.currentPostContentHash = Utils.md5(JSON.stringify(this.getFormGroupValues()));
        });

        this.postFormGroup
            .get(this.postControlNames.LinkShareTitle)
            .valueChanges.pipe(debounceTime(300))
            .subscribe((value) => {
                this.hasLinkShare = !!value;
                if (!!value) {
                    this.setInfoMessages.emit({
                        linkshare: {
                            message: "The selected OpenGraph Image cant be used on the following platforms: "
                                + "Instagram, Google My Business"
                                + "<br>If you want to attach image to your post you need to add it on the "
                                + '<strong class="font-bold">Instagram</strong> and <strong class="font-bold">GMB</strong> '
                                + "platform specific tabs!",
                        }
                    });
                } else {
                    this.setInfoMessages.emit(null);
                }
            });

        // Post repeat expiry date validation
        // [
        //     this.postControlNames.ExpiryPeriod,
        //     this.postControlNames.ExpiryEndDate,
        //     this.postControlNames.ActiveFromDate,
        //     this.postControlNames.ActiveFromMinute,
        //     this.postControlNames.ActiveFromHour,
        // ].forEach(controlName => {
        //     this.postFormGroup.get(controlName)
        //     .valueChanges
        //     .debounceTime(350)
        //     .subscribe(() => {
        //         this.changeExpiryDate();
        //     });
        // });
    }

    /**
     * Initializes in onInit method
     */
    protected initializeOnInit() {
        if (this.socialMediaType) {
            this.socialMediaConfig = {
                ...SocialMediaPlatforms.find((platform) => this.socialMediaType === platform.platform),
            };

            if (this.socialMediaConfig) {
                this.postContentMaxLength = this.socialMediaConfig.maxLength;

                if (this.socialMediaConfig.fileBrowserRule) {
                    this.fileBrowser.setMediaRules(this.socialMediaConfig.fileBrowserRule);
                }

                if (this.socialMediaConfig.disabledControlNames && this.socialMediaConfig.disabledControlNames.length) {
                    for (const controlName of this.socialMediaConfig.disabledControlNames) {
                        if (this.postFormGroup.contains(controlName)) {
                            this.postFormGroup.get(controlName).disable();
                        }
                    }
                }
            }
        }

        this.excludedExpansionPanels = [...Utils.get(this.socialMediaConfig, "excludedExpansionPanels", [])];
        this.excludedPanelByKey = this.excludedExpansionPanels.reduce((acc, panelKey) => {
            acc[panelKey] = true;
            return acc;
        }, {});

        const removeAbleControls = [...Utils.get(this.socialMediaConfig, "excludedControlNames", [])];
        const removeAbleExpansionPanelNames = this.excludedExpansionPanels;

        if (this.isPostTemplate) {
            removeAbleControls.push(
                ...[
                    // this.postControlNames.FeedTargetingOptions,
                    this.postControlNames.ActiveFromDate,
                    this.postControlNames.ActiveFromMinute,
                    this.postControlNames.ActiveFromHour,
                    // this.postControlNames.ExpiryPeriod,
                    // this.postControlNames.ExpiryEndDate,
                    // this.postControlNames.AutoFeed,
                ]
            );
            removeAbleExpansionPanelNames.push(
                ...[this.expansionPanelNames.NewsFeedTargeting, this.expansionPanelNames.PostRepeat, this.expansionPanelNames.Schedule]
            );
            if (this.currentPost) {
                this.isCustomSystemType = this.currentPost.systemType === CoreConfig.getSystemTypes().Custom;
            }
        }

        forEach(removeAbleControls, (controlName) => {
            if (has(this.postFormGroup.controls, controlName)) {
                this.postFormGroup.removeControl(controlName);
            }
        });

        for (const panelName of removeAbleExpansionPanelNames) {
            if (has(this.expansionPanelHasFormControls, panelName)) {
                delete this.expansionPanelHasFormControls[panelName];
            }
        }

        this.setPostFormValidators();
        this.validityChange.emit(false);
        this.setPostFormControlsDisableStates();

        setTimeout(() => {
            this.onInited = true;
        });

        if (!!this.initialScheduleDate) {
            this.setScheduleDateControls(this.initialScheduleDate);
            this.changePanelExpandedState();
        }

        if (this.currentPost) {
            this.setPostFormGroup(this.currentPost);
            this.changePanelExpandedState();

            this.enableSeparateRepeatCheckbox = !!Utils.lodash.get(this.currentPost, "repeats", []).length;

            this._saveOriginPostContentHash();
        }

        if (this.submit) {
            this.subscriptions.push(
                this.submit.subscribe((config: any) => {
                    this.postFormGroup
                        .get(this.postControlNames.FirstComment)
                        .setValue(Helpers.strip(Utils.replaceHTMLToSymbol(this.postFormGroup.get(this.postControlNames.FirstComment).value)));
                    const controls = !!config && config.isInstantPost ? this.getInstantPostFormControls() : this.postFormGroup.controls;

                    if (this.validatePostForm(controls)) {
                        this.getData.emit(Utils.lodash.cloneDeep(this.getDataForRequest()));
                    }
                })
            );
        }

        if (this.readFormData) {
            this.subscriptions.push(
                this.readFormData.subscribe(({ tabIndexes, emit }) => {
                    if (this.formData && tabIndexes && tabIndexes.length && includes(tabIndexes, this.tabPosition)) {
                        this.setPostFormGroup(
                            this.formData,
                            () => {
                                if (emit) {
                                    this.postReaded.emit();
                                }
                            },
                            {
                                modifyMedias: !Utils.get(this.currentPost, "backupLinkshare", null),
                            }
                        );
                        this.changePanelExpandedState();
                    }
                })
            );
        }

        if (!!this.initialMedias) {
            // szebben nem tudtam megoldani, hogy be szettelje az active/inactive fájlokat
            setTimeout(() => {
                this.fileBrowser.selectedFiles = this.initialMedias;
                this.postFormGroup.get(this.postControlNames.Medias).setValue(this.initialMedias);

                this._saveOriginPostContentHash();
            }, 4000);
        }

        /* Handle instagram post type if we work from a saved post/template (edit or copy) */
        if (this.currentPost && this.socialMediaType === SOCIAL_MEDIA_TYPE_INSTAGRAM) {
            if (this.currentPost.instagramOptions) {
                // Set formcontrol value based on instagram options (when template options are not parsed yet)
                const options = this.currentPost.instagramOptions instanceof Object ? this.currentPost.instagramOptions : JSON.parse(this.currentPost.instagramOptions);
                if (
                    has(options, "reel.enabled") &&
                    has(options, "shareToFeed.enabled")
                ) {
                    if (options.reel.enabled && options.shareToFeed.enabled) {
                        this.mediaFormGroup.get(this.postControlNames.InstagramPostType).setValue("reelAndFeed");
                    } else if (options.reel.enabled && !options.shareToFeed.enabled) {
                        this.mediaFormGroup.get(this.postControlNames.InstagramPostType).setValue("reel");
                    }
                }

                if (has(options, "story.enabled") && options.story.enabled) {
                    this.mediaFormGroup.get(this.postControlNames.InstagramPostType).setValue("story");
                }
            }
        }
        /* Handle facebook post type if we work from a saved post/template (edit or copy) */
        if (this.currentPost && this.socialMediaType === SOCIAL_MEDIA_TYPE_FACEBOOK) {
            if (this.currentPost.instagramOptions) {
                // Set formcontrol value based on instagram options (when template options are not parsed yet)
                const options = this.currentPost.instagramOptions instanceof Object ? this.currentPost.instagramOptions : JSON.parse(this.currentPost.instagramOptions);
                if (
                    has(options, "reel.enabled") &&
                    has(options, "shareToFeed.enabled")
                ) {
                    if (options.reel.enabled && options.shareToFeed.enabled) {
                        this.mediaFormGroup.get(this.postControlNames.FacebookPostType).setValue("reelAndFeed");
                    } else if (options.reel.enabled && !options.shareToFeed.enabled) {
                        this.mediaFormGroup.get(this.postControlNames.FacebookPostType).setValue("reel");
                    }
                }

                if (has(options, "story.enabled") && options.story.enabled) {
                    this.mediaFormGroup.get(this.postControlNames.FacebookPostType).setValue("story");
                }
            }
        }
        /* End of handling instagram post type */

        if (!!this.isEntityCommentMode) {
            const controls = cloneDeep(this.postFormGroup.controls);

            forEach(controls, (control, controlName) => {
                this.postFormGroup.get(controlName).disable();
            });
        }

        // Set available minutes and hours dynamically
        // TODO: Call this when date is changed?
        if (!this.availableInterval) {
            this.refreshAvailableTimes();
            this.availableInterval = setInterval(this.refreshAvailableTimes.bind(this), 60000);
        }

        /********************** Helpers for rendering / reducing change detection **********************/
        // Set TinyMCE Editors init configs
        // foreach tinyMCEInitConfigs keys
        for (const key in this.tinyMCEInitConfigs) {
            this.tinyMCEInitConfigs[key] = this.editorConfig(key);
        }
        this.tinyMCEInitCompleted = true;


        // check which postformgroup controls we have (this.postFormGroupContains)
        this.postFormGroupContains = {};
        for (const key in this.postFormGroup.controls) {
            if (this.postFormGroup.contains(key)) {
                this.postFormGroupContains[key] = true;
            }
        }

        this.targetingOptions = this.getTargetingOptions();

        if (this.currentPost && this.currentPost.feedTargetingOptions) {
            this.feedTargetingInitialValue = JSON.stringify(this.currentPost.feedTargetingOptions);
        }
    }

    private changeExpiryDate() {
        let period = this.postFormGroup.get(this.postControlNames.ExpiryPeriod).value;
        const scheduleDate = this.getActiveFrom();

        if (!this.socialMediaType || this.socialMediaType === "default" || !period || !scheduleDate) {
            return;
        }

        if (typeof period === "string") {
            period = this.periodConvert(period as PeriodType);
        }

        this.postFormGroup.get(this.postControlNames.ExpiryEndDate).clearValidators();

        const expiryDateMin = new Date(moment(scheduleDate).add(period, "seconds").format());
        this.postFormGroup.get(this.postControlNames.ExpiryEndDate).setValidators([Validators.required, minDate(expiryDateMin)]);

        setTimeout(() => {
            this.postFormGroup.get(this.postControlNames.ExpiryEndDate).updateValueAndValidity({ emitEvent: true });
            this.postFormErrorMessages = FormValidationService.getMessages(this.postFormGroup.controls);
        });
    }

    /**
     * Set post characters number
     */
    //@Debounce(100) // if we use debounce the parameter is not always undefined
    setPostCharacterNumber(tabChange?: boolean) {
        const results = CommonPostHelpers.setPostCharacterNumber({
            formGroup: this.postFormGroup,
            postContentMaxLength: this.postContentMaxLength,
            platform: this.socialMediaType,
            currentCharacterNumber: this.characterNumber || 0,
        });

        if (typeof tabChange === 'undefined') {
            tabChange = false;
        }

        if (results.doNotUpdate && !tabChange) {
            return;
        }

        this.characterNumber = results.characterNumber;
        this.characterNumberIsInvalid = results.characterNumberIsInvalid;

        this.validatePostCharacterLength();
    }

    /**
     * Get first wrong panel name
     * @return {string}
     */
    private getFirstWrongPanel() {
        for (const panelName in this.expansionPanelHasFormControls) {
            if (panelName === "gmbOptions") {
                continue;
            }
            const controlNames = this.expansionPanelHasFormControls[panelName];

            for (const controlName of controlNames) {
                if (this.postFormGroup.get(controlName).invalid) {
                    return panelName;
                }
            }
        }
    }

    /**
     * Validate post form
     * @return {boolean}
     */
    validatePostForm(specifyControls: { [key: string]: AbstractControl } = null) {
        this.postFormErrorMessages = FormValidationService.getMessages(specifyControls || this.postFormGroup.controls);
        // console.log('postFormErrorMessages',this.postFormErrorMessages);
        this.validatePostCharacterLength();

        if (!!keys(this.postFormErrorMessages).length) {
            this.onInvalidFormGroup();

            return false;
        }

        return true;
    }

    /**
     * Validate instant post form
     * @return {boolean}
     */
    validateInstantPostForm(): boolean {
        const controls = this.getInstantPostFormControls();

        this.postFormErrorMessages = FormValidationService.getMessages(controls);

        this.validatePostCharacterLength();

        this.onInvalidFormGroup();

        return !keys(this.postFormErrorMessages).length;
    }

    /**
     * Validate post content character length
     *
     * @returns {boolean} - Post content character length is valid
     */
    validatePostCharacterLength(): boolean {
        if (this.characterNumberIsInvalid) {
            this.postFormErrorMessages[this.postControlNames.Message] = LanguageService.getLine("post.contentLength.error", {
                max: this.postContentMaxLength,
            });

            this.postFormGroup.get(this.postControlNames.Message).setErrors({ invalid: true });

            return false;
        } else {
            // this.postFormErrorMessages = FormValidationService.getMessages(this.postFormGroup.controls);
            //  this.postFormErrorMessages[this.postControlNames.Message]=''; 
            delete this.postFormErrorMessages[this.postControlNames.Message];
            this.postFormGroup.get(this.postControlNames.Message).setErrors(null);
            // this.mediaError = false;
            this.setErrorTitle.emit(null);
            this.setErrorMessages.emit(null);
            delete this.postFormErrorMessages[this.postControlNames.Message];
        }

        return true;
    }

    formChanged(): boolean {
        return this.originPostContentHash !== this.currentPostContentHash;
    }

    private onInvalidFormGroup() {
        if (!keys(this.postFormErrorMessages).length) {
            return true;
        }

        for (const controlName in this.postFormErrorMessages) {
            const formControl = this.getFormControlByName(controlName, this.postFormGroup.controls);
            formControl ? formControl.markAsTouched() : null;
        }

        if (this.isActiveTab()) {
            this.scrollToFirstWrongPanel();
        }

        this.changePanelExpandedState(null, true);
    }

    private getFormControlByName(controlName: string, controls: object): FormControl {
        for (const inputName in controls) {
            if ("controls" in controls[inputName]) {
                const returnControl = this.getFormControlByName(controlName, controls[inputName].controls);
                if (returnControl) {
                    return returnControl;
                }
            }
            if (controls[inputName].get(controlName) && controls[inputName].get(controlName).status === "INVALID") {
                return controls[inputName].get(controlName);
            }
        }
        return null;
    }

    /**
     * Get instant post form controls
     * @return {any}
     */
    getInstantPostFormControls() {
        const controls = {};
        const activeFromControls = [this.postControlNames.ActiveFromDate, this.postControlNames.ActiveFromHour, this.postControlNames.ActiveFromMinute];

        // Ha instant posztolok, eltávolítom a validálandó form control-ok közül
        // az active from date control-kat
        for (const controlName in this.postFormGroup.controls) {
            const control = this.postFormGroup.get(controlName);

            if (!activeFromControls.includes(controlName)) {
                controls[controlName] = control;
            }
        }

        return controls;
    }

    /**
     * Scroll to first wrong panel
     */
    private scrollToFirstWrongPanel() {
        this.scrollTo.scrollTo({
            target: this.getFirstWrongPanel(),
        });
    }

    /**
     * Convert the selected post repeat period to ms and reverse
     * @param {PeriodType | number} period
     * @return {number | string}
     */
    private periodConvert(period: PeriodType | number): number | string {
        const periods = {
            daily: 60 * 60 * 24,
            weekly: 60 * 60 * 24 * 7,
            monthly: 60 * 60 * 24 * 30,
        };

        if (typeof period === "number") {
            let periodName;

            for (const key in periods) {
                const value = periods[key];

                if (value === period) {
                    periodName = key;
                }
            }

            return periodName;
        }

        return periods[period];
    }

    /**
     * Clear active from date controls validators
     */
    private clearActiveFromValidators() {
        this.postFormGroup.get(this.postControlNames.ActiveFromDate).clearValidators();
        this.postFormGroup.get(this.postControlNames.ActiveFromHour).clearValidators();
        this.postFormGroup.get(this.postControlNames.ActiveFromMinute).clearValidators();
    }

    /**
     * Set active from date controls validators
     */
    private setActiveFromValidators() {
        this.postFormGroup.get(this.postControlNames.ActiveFromDate).setValidators(this.getValidators(this.postControlNames.ActiveFromDate));

        this.postFormGroup.get(this.postControlNames.ActiveFromHour).setValidators(this.getValidators(this.postControlNames.ActiveFromHour));

        this.postFormGroup.get(this.postControlNames.ActiveFromMinute).setValidators(this.getValidators(this.postControlNames.ActiveFromMinute));
    }

    /**
     * Set post form validators
     */
    private setPostFormValidators() {
        for (const controlName in this.postFormGroup.controls) {
            this.postFormGroup.get(controlName).setValidators(this.getValidators(controlName));
        }
    }

    /**
     * Set post form controls disable states
     */
    private setPostFormControlsDisableStates() {
        if (!this.isEntityCommentMode) {
            return;
        }

        for (const controlName in this.postFormGroup.controls) {
            this.postFormGroup.get(controlName).disable();
        }
    }

    /**
     * Get post form valid state
     * @return {boolean}
     */
    private postFormIsValid(): boolean {
        return this.postFormGroup.valid;
    }

    /**
     * Get post active from value
     * @return {string}
     */
    private getActiveFrom(overrideHour: string = null, overrideMinute: string = null) {
        const controlNames = [this.postControlNames.ActiveFromDate, this.postControlNames.ActiveFromHour, this.postControlNames.ActiveFromMinute];

        for (const controlName of controlNames) {
            if (!has(this.postFormGroup.controls, controlName)) {
                return null;
            }
        }

        let date: string = this.postFormGroup.get(this.postControlNames.ActiveFromDate).value;
        let hour: string = overrideHour ?? this.postFormGroup.get(this.postControlNames.ActiveFromHour).value;
        let minute: string = overrideMinute ?? this.postFormGroup.get(this.postControlNames.ActiveFromMinute).value;

        if (!hour) {
            hour = "0";
        }
        if (!minute) {
            minute = "0";
        }
        if (!date) {
            date = moment().format(BASE_DATE_FORMAT);
        }

        if (typeof hour === "number") {
            hour = hour + "";
        }
        if (typeof minute === "number") {
            minute = minute + "";
        }

        if (hour.length === 1) {
            hour = "0" + hour;
        }
        if (minute.length === 1) {
            minute = "0" + minute;
        }

        date = moment(date).format(BASE_DATE_FORMAT);
        date = `${date} ${hour}:${minute}:00`;

        return DateSupport.moment(date).format(BASE_DATETIME_FORMAT);
    }

    /**
     * Clear link share inputs
     *
     * @param config
     */
    private emptyLinkshareInputs(config: LinkshareSetterConfig = {}) {
        [
            this.postControlNames.LinkShareTitle,
            this.postControlNames.LinkShareDescription,
            this.postControlNames.LinkShareURL,
            this.postControlNames.LinkShareImageURL,
        ].forEach((controlName) => {
            this.postFormGroup.get(controlName).setValue("");
        });

        config = {
            addOGImageToList: true,
            clearSelectedMedias: true,
            ogImageIsSelected: true,
            modifyMedias: true,
            ...config,
        };

        if (config.modifyMedias) {
            this.fileBrowser.deleteFileByPropertyValue("type", FILE_TYPES.OG);
            this.fileBrowser.setSelectedFilesInBrowser();
            this.fileBrowser.setInactiveFiles();
        }
    }

    /**
     * Set link share data to form inputs
     * @param object
     * @param urlIfObjectDoesNotContain
     * @param config
     */
    private setLinkshareDataToFormInput(object, urlIfObjectDoesNotContain = "", config: LinkshareSetterConfig = {}) {
        if (urlIfObjectDoesNotContain && !object["url"]) {
            object["url"] = urlIfObjectDoesNotContain;
        }

        if (!object["url"] && !urlIfObjectDoesNotContain) {
            NotifyService.error("Linkshare", LanguageService.getLine("post.linkShare.nodata.error"));
            return;
        }
        const disabledControlNames = this.socialMediaConfig ? this.socialMediaConfig.disabledControlNames : null;

        config = {
            addOGImageToList: true,
            clearSelectedMedias: true,
            ogImageIsSelected: true,
            modifyMedias: true,
            ...config,
        };

        // Enable link share inputs
        [
            this.postControlNames.LinkShareTitle,
            this.postControlNames.LinkShareDescription,
            this.postControlNames.LinkShareURL,
            this.postControlNames.LinkShareImageURL,
        ].forEach((controlName) => {
            if (!disabledControlNames || (disabledControlNames.length && !disabledControlNames.includes(controlName))) {
                this.postFormGroup.get(controlName).enable();
            }
        });

        this.emptyLinkshareInputs(config);

        if (object["title"]) {
            this.postFormGroup.get(this.postControlNames.LinkShareTitle).setValue(object["title"]);
        }

        if (object["description"]) {
            this.postFormGroup.get(this.postControlNames.LinkShareDescription).setValue(object["description"]);
        }

        if (object["image"] && config.modifyMedias) {
            this.postFormGroup.get(this.postControlNames.LinkShareImageURL).setValue(object["image"]);

            // kép injektálás
            this.addLinkshareImage(object["image"], config);
        }

        if (this.socialMediaType === SOCIAL_MEDIA_TYPE_TWITTER && !object["image"] && object["title"]) {
            this.fileBrowser.clearSelectedFiles();
            this.postFormGroup.get(this.postControlNames.Medias).setValue([]);
        }

        let url = object["url"];

        if (url) {
            url = (url + "").replace(/\/+$/, "");
        }

        if (object["title"]) {
            this.postFormGroup.get(this.postControlNames.LinkShareURL).setValue(url || urlIfObjectDoesNotContain);
            this.hasLinkShare = true;

            this.changePanelExpandedState();
        }
    }

    /**
     * Add OG image
     * @param url
     * @param config
     * @param timeout
     */
    private addLinkshareImage(url, config: LinkshareSetterConfig = {}, timeout = true) {
        config = {
            addOGImageToList: true,
            clearSelectedMedias: true,
            ogImageIsSelected: true,
            modifyMedias: true,
            ...config,
        };

        if (this.socialMediaType !== SOCIAL_MEDIA_TYPE_INSTAGRAM) {
            if (config.clearSelectedMedias) {
                this.fileBrowser.clearSelectedFiles();
            }

            const addNewMedia = () => {
                const newFile = new SmdFile(
                    {
                        name: "OpenGraph Image",
                        mediaID: Date.now(),
                        url: url,
                        mime: OPENGRAPH_FILE_MIMETYPE,
                        type: FILE_TYPES.OG,
                        createDate: moment().format(BASE_DATETIME_FORMAT),
                        selected: config.ogImageIsSelected,
                    },
                    true
                );

                if (config.addOGImageToList) {
                    this.fileBrowser.addNewFile(newFile, config.ogImageIsSelected);
                }

                if (config.ogImageIsSelected) {
                    this.postFormGroup.get(this.postControlNames.Medias).setValue([newFile]);
                }

                this._saveOriginPostContentHash();
            };

            if (timeout) {
                // clear-el async futott meg az add ezért bekerült egy
                // settimeoutba hogy a következő futási ciklusba történjen meg.
                setTimeout(() => addNewMedia());
            } else {
                addNewMedia();
            }
        }
    }

    /**
     * Remove link share properties
     *
     * @param config
     */
    private removeLinkshareProperties(config: LinkshareSetterConfig = {}) {
        const disableControlNames = [
            this.postControlNames.LinkShareTitle,
            this.postControlNames.LinkShareDescription,
            this.postControlNames.LinkShareURL,
            this.postControlNames.LinkShareImageURL,
        ];
        disableControlNames.forEach((controlName) => {
            this.postFormGroup.get(controlName).disable();
        });

        config = {
            addOGImageToList: true,
            clearSelectedMedias: true,
            ogImageIsSelected: true,
            modifyMedias: true,
            ...config,
        };

        this.emptyLinkshareInputs(config);
        this._setOriginLinkShare({});
        this.hasLinkShare = false;
        this.changePanelExpandedState();

        this.valueChange.emit({
            ...this.postFormGroup.getRawValue(),
            autoLinkshareEnabled: this.autoLinkshareEnabled,
            linkshare: {},
            backupLinkshare: {},
            [PostFormControlNames.SelectedMentions]: Utils.lodash.cloneDeep(this.selectedMentions),
        });
    }

    /**
     * @title Set post form values by currentPost
     * @description This is required for post edit mode
     */
    protected setPostFormGroup(data: any, callback = () => { }, linkshareConfig: LinkshareSetterConfig = {}) {
        let wasCallback = false;

        linkshareConfig = {
            addOGImageToList: true,
            clearSelectedMedias: true,
            ogImageIsSelected: true,
            modifyMedias: true,
            ...linkshareConfig,
        };

        if (this.socialMediaConfig && this.socialMediaConfig.copyFormDataPreparer) {
            data = this.socialMediaConfig.copyFormDataPreparer(data || {});
        }

        if (data[PostFormControlNames.SelectedMentions]) {
            this.selectedMentions = data[PostFormControlNames.SelectedMentions];
        }

        const linkshareImage = Utils.get(data, "backupLinkshare.image", null);

        if (linkshareImage) {
            linkshareConfig.modifyMedias = false;

            if (this.fileBrowser) {
                this.fileBrowser.deleteFileByPropertyValue("type", FILE_TYPES.OG);
            }

            this.addLinkshareImage(
                linkshareImage,
                {
                    ogImageIsSelected: !!(data[PostFormControlNames.Medias] || []).filter((media) => media.type === FILE_TYPES.OG).length,
                },
                false
            );
            this._setOriginLinkShare(data.linkshare ? data.linkshare : data.backupLinkshare);

            // remove OG image from selected medias
            data[PostFormControlNames.Medias] = data[PostFormControlNames.Medias].filter((media) => media.type !== FILE_TYPES.OG);
        }

        if (!!data.type && data.type === "draft") {
            const archive = has(data, "archive") ? data.archive : null;
            const draftSettings = !!archive && has(archive, "draftSettings") ? archive.draftSettings : data.draftSettings || null;

            if (draftSettings && has(draftSettings, this.socialMediaType)) {
                const currentPlatformData = draftSettings[this.socialMediaType];

                data = {
                    ...currentPlatformData,
                    activeFromInput: currentPlatformData.activeFrom || data.activeFromInput || data.activeFrom || null,
                    [this.postControlNames.Medias]: data.medias,
                };

                if (has(data, "linkshareTitle")) {
                    data["linkshare"] = {
                        title: data["linkshareTitle"],
                        image: data["linkshareImageURL"],
                        description: data["linkshareDescription"],
                        url: data["linkshareURL"],
                    };
                }

                data = Utils.initLineBreaksOnPostEntity(data);
            }
        }

        this.setLinkShareAvailable = Utils.lodash.get(data, PostFormControlNames.LinkShareIncluded, "true") !== "false";

        // if it is set, its a copy from a tab. if it is not, its a post being opened
        this.autoLinkshareEnabled = data.autoLinkshareEnabled === true ? data.autoLinkshareEnabled : data?.linkShare?.autoLinkshareEnabled === true || false;

        // data['expiryDate']=null;
        // data['expiryPeriod']=null;
        for (const valueName in data) {
            const value = data[valueName];

            if (valueName === this.postControlNames.Medias && !!value) {
                this.fileBrowser.selectedFiles = value;
                this.postFormGroup.get(this.postControlNames.Medias).setValue(value);

                if (this.socialMediaType === SOCIAL_MEDIA_TYPE_GMB && value && value.length == 1) {
                    const media = value[0];
                    // remove video if GMB post (GMB currently not supporting video)
                    if (media.type === "video") {
                        this.fileBrowser.clearSelectedFiles();
                        this.postFormGroup.get(this.postControlNames.Medias).setValue([]);
                    }
                }
                if (this.socialMediaType === SOCIAL_MEDIA_TYPE_GMB && value && value.length > 1) {
                    this.setInfoMessages.emit({
                        gmb: {
                            message: "Currently GMB API doesn't support video attachments. Only single images allowed. The selected video is removed from the GMB tab. If you want to attach a media element, please add a single image instead on the GMB tab."
                        }
                    });
                }
                /* Set instagram post type settings */
                if (
                    this.socialMediaType === SOCIAL_MEDIA_TYPE_INSTAGRAM &&
                    value &&
                    this.mediaFormGroup.get(this.postControlNames.InstagramPostType).value === null
                ) {
                    if (value.length === 1) {
                        const media = value[0];
                        if (media.type === "video") {
                            // set each option enabled
                            this.instaPostTypeConfig.forEach((config) => {
                                config.disabled = config.value !== "reelAndFeed" && config.value !== "reel" && config.value !== "story";;
                            });
                            this.mediaFormGroup.get(this.postControlNames.InstagramPostType).setValue("reel");
                        } else if (media.type === "image") {
                            // make each insta post type config disabled except feed and story
                            // check image ratio too for setting story and feed enabled
                            this.instaPostTypeConfig.forEach((config) => {
                                config.disabled = config.value !== "feed" && config.value !== "story";
                            });
                            this.mediaFormGroup.get(this.postControlNames.InstagramPostType).setValue("feed");
                        }
                        // if (media.type === "video") {
                        //     // set each option enabled
                        //     this.instaPostTypeConfig.forEach((config) => {
                        //         config.disabled = false;
                        //     });
                        // } else if (media.type === "image") {
                        //     // make each insta post type config disabled except feed and story
                        //     this.instaPostTypeConfig.forEach((config) => {
                        //         config.disabled = config.value !== "feed" && config.value !== "story";
                        //     });
                        // }
                        // set feed as default
                        // this.mediaFormGroup.get(this.postControlNames.InstagramPostType).setValue("feed");
                        if (this.currentPost && this.currentPost.instagramOptions) {

                            const options = this.currentPost.instagramOptions instanceof Object
                                ? this.currentPost.instagramOptions
                                : JSON.parse(this.currentPost.instagramOptions);

                            const postControl = this.mediaFormGroup.get(this.postControlNames.InstagramPostType);

                            if (options) {
                                const isReelEnabled = options.reel && options.reel.enabled;
                                const isShareToFeedEnabled = options.shareToFeed && options.shareToFeed.enabled;
                                const isStoryEnabled = options.story && options.story.enabled;

                                if (isReelEnabled && isShareToFeedEnabled) {
                                    postControl.setValue("reelAndFeed");
                                } else if (isReelEnabled && !isShareToFeedEnabled) {
                                    postControl.setValue("reel");
                                } else if (isStoryEnabled) {
                                    postControl.setValue("story");
                                } else {
                                    postControl.setValue("feed");
                                }

                                if (isStoryEnabled && isReelEnabled && isShareToFeedEnabled) {
                                    postControl.setValue("story");
                                }
                            }
                        }
                        // else {
                        //     this.mediaFormGroup.get(this.postControlNames.InstagramPostType).setValue("feed");
                        // }
                    } else if (value.length > 1) {
                        // make each insta post type config disabled except feed
                        this.instaPostTypeConfig.forEach((config) => {
                            config.disabled = config.value !== "feed";
                        });
                        // set feed as default
                        this.mediaFormGroup.get(this.postControlNames.InstagramPostType).setValue("feed");
                    } else {
                        // make each insta post type config disabled
                        this.instaPostTypeConfig.forEach((config) => {
                            config.disabled = true;
                        });
                        // set value to null
                        this.mediaFormGroup.get(this.postControlNames.InstagramPostType).setValue(null);
                    }
                }
                /* Set instagram post type settings */
                if (
                    this.socialMediaType === SOCIAL_MEDIA_TYPE_FACEBOOK &&
                    value &&
                    this.mediaFormGroup.get(this.postControlNames.FacebookPostType).value === null
                ) {
                    if (value.length === 1) {
                        const media = value[0];
                        console.log('media', media);
                        if (media.type === "video") {
                            // set each option enabled
                            const ratioSplit = media.ratio.split(":");
                            const ratio = Number(ratioSplit[0]) / Number(ratioSplit[1]);
                            const postType = this.mediaFormGroup.get(this.postControlNames.FacebookPostType).value;
                            const image = new Image();
                            image.src = media.rawMedia.previewFileUrl;
                            image.onload = function () {
                            }
                            const width = image.width;
                            const height = image.height;
                            const aspectRatio = width / height;
                            const videoratio = aspectRatio.toFixed(2);

                            const videoUrl = media.rawMedia.url;
                            const video = document.createElement('video');
                            video.src = videoUrl;
                            video.addEventListener('loadedmetadata', () => {

                                this.mediaFormGroup.get(this.postControlNames.Duration).setValue(video.duration);
                            });


                            if (width < 540 || height < 960 && width > 1080 || height > 1920) {
                                if (postType == "story") {
                                    const error = {
                                        facebook: {
                                            medias: LanguageService.getLine("post.adapter.facebook.story.video.format"),
                                        },
                                    };
                                    this.mediaError = true;
                                    this.setErrorTitle.emit("Validation error(s)");
                                    this.setErrorMessages.emit(error);
                                } else if (postType == "reel" || postType == "reelAndFeed") {
                                    const error = {
                                        facebook: {
                                            medias: LanguageService.getLine("post.adapter.facebook.reel.video.format"),
                                        },
                                    };
                                    this.mediaError = true;
                                    this.setErrorTitle.emit("Validation error(s)");
                                    this.setErrorMessages.emit(error);
                                } else {
                                    this.mediaError = false;
                                    this.setErrorTitle.emit(null);
                                    this.setErrorMessages.emit(null);
                                }
                            }

                            if (video.duration < 3 || video.duration > 90) {

                                if (postType == "story") {
                                    const error = {
                                        facebook: {
                                            medias: LanguageService.getLine("post.adapter.facebook.story.video.format"),
                                        },
                                    };
                                    this.mediaError = true;
                                    this.setErrorTitle.emit("Validation error(s)");
                                    this.setErrorMessages.emit(error);
                                } else if (postType == "reel" || postType == "reelAndFeed") {
                                    const error = {
                                        facebook: {
                                            medias: LanguageService.getLine("post.adapter.facebook.reel.video.format"),
                                        },
                                    };
                                    this.mediaError = true;
                                    this.setErrorTitle.emit("Validation error(s)");
                                    this.setErrorMessages.emit(error);
                                } else {
                                    this.mediaError = false;
                                    this.setErrorTitle.emit(null);
                                    this.setErrorMessages.emit(null);
                                }

                            }
                            if (videoratio !== "0.56") {

                                if (postType == "story") {
                                    const error = {
                                        facebook: {
                                            medias: LanguageService.getLine("post.adapter.facebook.story.video.format"),
                                        },
                                    };
                                    this.mediaError = true;
                                    this.setErrorTitle.emit("Validation error(s)");
                                    this.setErrorMessages.emit(error);
                                } else if (postType == "reel" || postType == "reelAndFeed") {
                                    const error = {
                                        facebook: {
                                            medias: LanguageService.getLine("post.adapter.facebook.reel.video.format"),
                                        },
                                    };
                                    this.mediaError = true;
                                    this.setErrorTitle.emit("Validation error(s)");
                                    this.setErrorMessages.emit(error);
                                } else {
                                    this.mediaError = false;
                                    this.setErrorTitle.emit(null);
                                    this.setErrorMessages.emit(null);
                                }

                            }
                            this.facebookPostTypeConfig.forEach((config) => {
                                config.disabled = false;
                            });
                        } else if (media.type === "image") {
                            // make each insta post type config disabled except feed and story
                            this.facebookPostTypeConfig.forEach((config) => {
                                config.disabled = config.value !== "feed" && config.value !== "story";
                            });
                        }
                        // set feed as default
                        // this.mediaFormGroup.get(this.postControlNames.FacebookPostType).setValue("feed");
                        if (this.currentPost && this.currentPost.instagramOptions) {
                            console.log('option', this.currentPost.instagramOptions);

                            const options = this.currentPost.instagramOptions instanceof Object
                                ? this.currentPost.instagramOptions
                                : JSON.parse(this.currentPost.instagramOptions);

                            const postControl = this.mediaFormGroup.get(this.postControlNames.FacebookPostType);

                            if (options) {
                                const isReelEnabled = options.reel && options.reel.enabled;
                                const isShareToFeedEnabled = options.shareToFeed && options.shareToFeed.enabled;
                                const isStoryEnabled = options.story && options.story.enabled;

                                if (isReelEnabled && isShareToFeedEnabled) {
                                    postControl.setValue("reelAndFeed");
                                } else if (isReelEnabled && !isShareToFeedEnabled) {
                                    postControl.setValue("reel");
                                } else if (isStoryEnabled) {
                                    postControl.setValue("story");
                                } else {
                                    postControl.setValue("feed");
                                }

                                if (isStoryEnabled && isReelEnabled && isShareToFeedEnabled) {
                                    postControl.setValue("story");
                                }
                            }
                        } else {
                            this.mediaFormGroup.get(this.postControlNames.FacebookPostType).setValue("feed");
                        }
                    } else if (value.length > 1) {
                        // make each insta post type config disabled except feed
                        this.facebookPostTypeConfig.forEach((config) => {
                            config.disabled = config.value !== "feed";
                        });
                        // set feed as default
                        this.mediaFormGroup.get(this.postControlNames.FacebookPostType).setValue("feed");
                    } else {
                        // make each insta post type config disabled
                        this.facebookPostTypeConfig.forEach((config) => {
                            config.disabled = true;
                        });
                        // set value to null
                        this.mediaFormGroup.get(this.postControlNames.FacebookPostType).setValue(null);
                    }
                }
            }
            if (!this.isCopyMode) {


                if (valueName === "activeFromInput" && value) {
                    this.setScheduleDateControls(value);
                    continue;
                }
            }
            if (this.isCopyMode) {
                // const datetimeObject = moment.tz(moment().utc(), this.user.settings.defaultTimeZone).format("YYYY-MM-DD HH:mm").toString();
                // const datetime = moment(datetimeObject).add(10, 'minutes').format(BASE_DATETIME_FORMAT);
                // this.setScheduleDateControls(datetime);
                if (valueName === "activeFromInput" && value) {
                    if (value) {
                        const datetimeObject = moment.tz(moment().utc(), this.user.settings.defaultTimeZone).format("YYYY-MM-DD HH:mm").toString();
                        const datetime = moment(datetimeObject).add(10, 'minutes').format(BASE_DATETIME_FORMAT);
                        this.setScheduleDateControls(datetime);
                    }
                }
            }
            // console.log('expiry',this.postControlNames.ExpiryEndDate);
            if (valueName === "expiryDate" && value) {
                this.postFormGroup.get(this.postControlNames.ExpiryEndDate).setValue(moment(value).format(BASE_DATE_FORMAT));
                continue;
            }

            if (valueName === this.postControlNames.ExpiryPeriod && value && !!parseInt(value, null)) {
                this.postFormGroup.get(this.postControlNames.ExpiryPeriod).setValue(this.periodConvert(parseInt(value, null)));
                continue;
            }

            if (valueName === "linkshare" && value && value.title && this.setLinkShareAvailable) {
                if (value["fromRss"]) {
                    this.isPostFromRss = true;
                } else {
                    this._setOriginLinkShare(value);
                    this.setLinkshareDataToFormInput(value, "", linkshareConfig);
                }
            }

            // Remove linkshare image from selected medias if gmb or instagram
            if (valueName === "linkshare" && (value.title || value.linkshareTitle) && value && this.setLinkShareAvailable && (this.socialMediaType === SOCIAL_MEDIA_TYPE_INSTAGRAM || this.socialMediaType === SOCIAL_MEDIA_TYPE_GMB)) {
                this.fileBrowser.clearSelectedFiles();
                this.postFormGroup.get(this.postControlNames.Medias).setValue([]);
                console.log('linksahre', this.postControlNames.Medias);
            }

            if (valueName === "type" && !!value && value === "autoFeed") {
                // console.log('check controler',this.postControlNames.AutoFeed);
                this.postFormGroup.get(this.postControlNames.AutoFeed).setValue(true);
            }

            if (has(this.postFormGroup.controls, valueName)) {
                this.postFormGroup.get(valueName).setValue(value);
            }

            if (valueName === this.postControlNames.AutoFeed || (valueName === "type" && !!value && value === "autoFeed")) {
                wasCallback = true;
                this.changeAutoFeedChecked(false);
            }

            if (valueName == this.postControlNames.FeedTargetingOptions) {
                if (value) {
                    this.postFormGroup.get(this.postControlNames.FeedTargetingOptions).setValue(value);
                }
            }
        }

        const tabChange = true;
        this.setPostCharacterNumber(tabChange);

        this.postFormGroup.updateValueAndValidity({ emitEvent: true });
        this.validatePostForm();

        callback();
    }

    /**
     * Set schedule date controls
     * @param {string} scheduleDate
     */
    protected setScheduleDateControls(scheduleDate: string) {
        const dateObject = CommonPostHelpers.roundMinuteToFive(scheduleDate);

        this.postFormGroup.get(this.postControlNames.ActiveFromDate).setValue(dateObject.date);
        this.postFormGroup.get(this.postControlNames.ActiveFromHour).setValue(dateObject.hour);
        this.postFormGroup.get(this.postControlNames.ActiveFromMinute).setValue(dateObject.minute);
    }

    /**
     * Get new form control by form control name
     * @param controlName
     * @return {FormControl}
     */
    private getNewFormControl(controlName): FormControl {
        const formState = null;
        return new FormControl(formState, []);
    }

    /**
     * Get post form control validators from options by fromControlName and socialMediaPlatform
     * @param {string} controlName
     * @return {any[]}
     */
    private getValidators(controlName: string): any[] {
        if (!this.socialMediaType) {
            return [];
        }

        const platformConfig = SocialMediaPlatforms.find((platform) => platform.platform === this.socialMediaType && !!platform.validators);

        if (platformConfig && platformConfig.validators[controlName]) {
            return platformConfig.validators[controlName];
        }

        return [];
    }

    /**
     * Link share datas is changed
     * @param {string} name
     * @returns {boolean}
     * @private
     */
    private _linkShareChanged(name: string): boolean {
        return this._originLinkShareDatas[name] !== this.postFormGroup.get(name).value;
    }

    /**
     * Set origin link share datas
     * @param datas
     * @private
     */
    private _setOriginLinkShare(datas: any): void {
        this._originLinkShareDatas = datas || {};
        this._originLinkShareDatas[this.postControlNames.LinkShareTitle] = Utils.get(datas, "title", "");
        this._originLinkShareDatas[this.postControlNames.LinkShareDescription] = Utils.get(datas, "description", "");
        this._originLinkShareDatas[this.postControlNames.LinkShareImageURL] = Utils.get(datas, "image", "");
        this._originLinkShareDatas[this.postControlNames.LinkShareURL] = Utils.get(datas, "url", "");
    }

    /**
     * Save origin post content hash
     *
     * @private
     */
    private _saveOriginPostContentHash(): void {
        if (this.objectType === "post") {
            this.originPostContentHash = Utils.md5(JSON.stringify(this.getFormGroupValues()));
        }
    }

    /**
     * Remove blacklist html tags and attributes from string
     * @param {string} content
     * @param {PostContentPrepareConfig} config
     * @returns {string}
     */
    private postContentPrepare(content: string, config?: PostContentPrepareConfig) {
        if (typeof content !== "string") {
            return content;
        }

        // merge config with defaults
        config = {
            htmlToSign: false,
            htmlWhiteList: ["a", "br"],
            ...(config || {}),
        };

        // replace close tags with '<br>'
        content = content.replace(/<\/p>|<\/div>|( +)?<br ?\/?>|\n/g, "<br/>");

        // remove unavailable html tags
        content = Utils.striptags(content, config.htmlWhiteList);

        // replace three or more line break with two line break
        const whiteSpaceRegex = `( +|${String.fromCharCode(160)}+)?`;
        const lineBreaks = new RegExp(`${whiteSpaceRegex}<br ?\/?>${whiteSpaceRegex}<br ?\/?>${whiteSpaceRegex}<br ?\/?>`, "g");
        content = this.replaceMultiWords(content, lineBreaks, "<br/><br/>");

        // replace two whitespace with one whitespace
        const whiteSpace = String.fromCharCode(160);
        content = this.replaceMultiWords(content, new RegExp(whiteSpace + whiteSpace, "g"), whiteSpace);

        // remove attributes
        content = content.replace(/( ?style| ?class| ?id| ?data-(.*?)| ?rel| ?itemprop| ?target)="(.*?)"/g, "");

        // add blank target to link elements
        content = content.replace(/<a/g, '<a target="_blank"');

        // before send data to backend
        if (!!config.htmlToSign) {
            // replace 'br' and close tags with '\n'
            content = content.replace(/<br(.*?)>|<\/?p(.*?)>|<\/?div(.*?)>/g, "\n");

            // remove whitespaces and line breaks from both ends of string
            content = content.trim();
        }

        return content;
    }

    private getCurrentLinkShareData() {
        return {
            linkshareDescription: this.postFormGroup.get(this.postControlNames.LinkShareDescription).value,
            linkshareImageURL: this.postFormGroup.get(this.postControlNames.LinkShareImageURL).value,
            linkshareTitle: this.postFormGroup.get(this.postControlNames.LinkShareTitle).value,
        };
    }

    private replaceMultiWords(content: string, regex: RegExp, replaceValue: string) {
        while (!!content.match(regex)) {
            content = content.replace(regex, replaceValue);
        }

        return content;
    }

    changeMessageTitle(event) {
        this.messageTitle = "post.gmb.message.title." + event;
        this.headlineTitle = "post.gmb.headline.title." + event;
    }

    loadMedia() {
        this.showMedia = true;
    }
    //facebook posttypechange
    facebookostTypeChange(data: MatRadioChange) {
        const selectedFiles = this.postFormGroup.get(this.postControlNames.Medias).value;
        if (selectedFiles.length === 1) {
            const file = selectedFiles[0];

            const postType = this.mediaFormGroup.get(this.postControlNames.FacebookPostType).value;
            if (file.type === "image" || (file.type === "image" && postType === "story")) {
                const mime = file.rawMedia.mime;
                const size = file.rawMedia.bytes;

                const ratioSplit = file.ratio.split(":");
                const ratio = Number(ratioSplit[0]) / Number(ratioSplit[1]);

                const allowedMimeTypes = ['image/jpeg', 'image/png', 'image/bmp', 'image/gif', 'image/tiff'];
                if (!allowedMimeTypes.includes(mime)) {
                    const error = {
                        facebook: {
                            medias: LanguageService.getLine("post.facebook.validation.invalidFileType"),
                        },
                    };
                    this.mediaError = true;
                    this.setErrorTitle.emit("Validation error(s)");
                    this.setErrorMessages.emit(error);
                } else {
                    this.mediaError = false;
                    this.setErrorTitle.emit(null);
                    this.setErrorMessages.emit(null);
                }
                if (size > 4000000) {
                    const error = {
                        facebook: {
                            medias: LanguageService.getLine("post.facebook.validation.invalidFileType"),
                        },
                    };
                    this.mediaError = true;
                    this.setErrorTitle.emit("Validation error(s)");
                    this.setErrorMessages.emit(error);
                } else {
                    this.mediaError = false;
                    this.setErrorTitle.emit(null);
                    this.setErrorMessages.emit(null);
                }
            }
            if (file.type === "video") {

                const ratioSplit = file.ratio.split(":");
                const ratio = Number(ratioSplit[0]) / Number(ratioSplit[1]);
                const postType = this.mediaFormGroup.get(this.postControlNames.FacebookPostType).value;
                const image = new Image();
                image.src = file.rawMedia.previewFileUrl;
                image.onload = function () {
                }
                const width = image.width;
                const height = image.height;
                const aspectRatio = width / height;
                const videoratio = aspectRatio.toFixed(2);

                const videoUrl = file.rawMedia.url;
                const video = document.createElement('video');
                video.src = videoUrl;
                video.addEventListener('loadedmetadata', () => {

                    this.mediaFormGroup.get(this.postControlNames.Duration).setValue(video.duration);
                });


                if (width < 540 || height < 960 && width > 1080 || height > 1920) {
                    if (postType == "story") {
                        const error = {
                            facebook: {
                                medias: LanguageService.getLine("post.adapter.facebook.story.video.format"),
                            },
                        };
                        this.mediaError = true;
                        this.setErrorTitle.emit("Validation error(s)");
                        this.setErrorMessages.emit(error);
                    } else if (postType == "reel" || postType == "reelAndFeed") {
                        const error = {
                            facebook: {
                                medias: LanguageService.getLine("post.adapter.facebook.reel.video.format"),
                            },
                        };
                        this.mediaError = true;
                        this.setErrorTitle.emit("Validation error(s)");
                        this.setErrorMessages.emit(error);
                    } else {
                        this.mediaError = false;
                        this.setErrorTitle.emit(null);
                        this.setErrorMessages.emit(null);
                    }
                }

                if (video.duration < 3 || video.duration > 90) {

                    if (postType == "story") {
                        const error = {
                            facebook: {
                                medias: LanguageService.getLine("post.adapter.facebook.story.video.format"),
                            },
                        };
                        this.mediaError = true;
                        this.setErrorTitle.emit("Validation error(s)");
                        this.setErrorMessages.emit(error);
                    } else if (postType == "reel" || postType == "reelAndFeed") {
                        const error = {
                            facebook: {
                                medias: LanguageService.getLine("post.adapter.facebook.reel.video.format"),
                            },
                        };
                        this.mediaError = true;
                        this.setErrorTitle.emit("Validation error(s)");
                        this.setErrorMessages.emit(error);
                    } else {
                        this.mediaError = false;
                        this.setErrorTitle.emit(null);
                        this.setErrorMessages.emit(null);
                    }

                }
                if (videoratio !== "0.56") {

                    if (postType == "story") {
                        const error = {
                            facebook: {
                                medias: LanguageService.getLine("post.adapter.facebook.story.video.format"),
                            },
                        };
                        this.mediaError = true;
                        this.setErrorTitle.emit("Validation error(s)");
                        this.setErrorMessages.emit(error);
                    } else if (postType == "reel" || postType == "reelAndFeed") {
                        const error = {
                            facebook: {
                                medias: LanguageService.getLine("post.adapter.facebook.reel.video.format"),
                            },
                        };
                        this.mediaError = true;
                        this.setErrorTitle.emit("Validation error(s)");
                        this.setErrorMessages.emit(error);
                    } else {
                        this.mediaError = false;
                        this.setErrorTitle.emit(null);
                        this.setErrorMessages.emit(null);
                    }

                }
            }
        }
    }
    instagramPostTypeChange(data: MatRadioChange) {
        const selectedFiles = this.postFormGroup.get(this.postControlNames.Medias).value;
        if (selectedFiles.length === 1) {
            const file = selectedFiles[0];
            if (file.type === "image") {
                const ratioSplit = file.ratio.split(":");
                const ratio = Number(ratioSplit[0]) / Number(ratioSplit[1]);

                if ((ratio < 0.8 || ratio > 1.91) && data.value !== "story") {
                    const error = {
                        instagram: {
                            medias: LanguageService.getLine("post.template.error.image.ratio"),
                        },
                    };
                    this.mediaError = true;
                    this.setErrorTitle.emit("Validation error(s)");
                    this.setErrorMessages.emit(error);
                } else {
                    this.mediaError = false;
                    this.setErrorTitle.emit(null);
                    this.setErrorMessages.emit(null);
                }
            }
        }
    }
}
