import { Helpers } from "~/src/app/services/helpers";
import { Icons } from "~/src/app/helpers/icons";
import { Token } from "~/src/app/services/token";
import { Category, Tag } from "~/src/app/modules/posts/template.interface";
import { FILE_TYPES } from "~/src/app/components/file/types/fileTypes";
import { CarouselItemResponseInterface } from "~/src/app/modules/posts/carousel/carousel.interface";
import { environment } from "~/src/environments/environment";
import { cloneDeep, has, includes } from "lodash";
import Utils from "~/src/app/core/utils";
import { BASE_DATETIME_FORMAT, GOOGLE_CLOUD_STORAGE_BASE_URI } from "~/src/app/configs/configs";
import { CarouselItem } from "~/src/app/modules/posts/carousel/carousel.component";

const MAX_FILE_NAME_LENGTH = 20;

export const OPENGRAPH_FILE_MIMETYPE = "og/image";

export interface RawFile {
    name: string;
    mediaID: number;
    url: string;
    thumbUrl?: string;
    mediaType?: string;
    mime: string;
    type: string;
    createDate: string;
    modifyDate?: string;
    uploader?: string;
    //user?: string;
    size?: string;
    resolution?: string;
    bytes?: number;
    ratio?: string;
    synced?: boolean;
    is360?: any;
    orientation?: string;
    instaOrientation?: string;
    previewFileUrl?: string;
    tags?: Tag[];
    categories?: Category[];
    socialChannel?: Array<string>;
    initialized?: boolean;
    entityID?: string;
    cacheID?: string;
    carouselItems?: any[];
    canvaId?: string;
    userID?: number;
    organizationID?: number;
    uploaderUserName?: string;
    previewTimeOffset?: number;
    organizationName?: string;
    mediaName?: string; // same as name, but used in viewallmedia
}

/**
 * File object format
 */
export interface SmdFileInterface {
    mediaID: number;
    name: string;
    type: string;
    url: string;
    thumbUrl?: string;
    thumb: string;
    bigThumb: string;
    shortName: string;
    selected: boolean;
    fileType: string;
    active: boolean;
    mime?: string;
    icon: string;
    isNew: boolean;
    //isDeleteable?: boolean;
    //isEditable?: boolean;
    allowedActions?: Array<string>;
    createDate?: string;
    modifyDate?: string;
    uploader?: string;
    //user?: string;
    modifyUserName?: string;
    size?: string;
    resolution?: string;
    galleryThumb?: string;
    ratio?: string;
    synced?: boolean;
    orientation?: string;
    instaOrientation?: string;
    is360?: any;
    isRaw?: boolean;
    tags?: Tag[];
    categories?: Category[];
    carouselItems?: CarouselItemResponseInterface[];
    socialChannels?: Array<string>;
    rawMedia?: RawFile;
    systemType?: string;
    systemTypeMessage?: string;
    partnerIDs?: any[];
    organizationIDs?: any[];
    disallowedPartnerIDs?: any[];
    status?: string;
    statusMessage?: string;
    socialType;
    initialized?: boolean;
    entityID?: string;
    cacheID?: string;
    notAllow?: boolean;
    editorMetaFile?: string;
    editorMeta?: object;
    file?: File;
    canvaId?: string;
    userID?: number;
    organizationID?: number;
    uploaderUserName?: string;
    organizationName?: string;
    previewTimeOffset?: number;
}

interface CreateMediaConfig {
    isSelected: boolean;
    isNew: boolean;
    type: string;
    mime: string;
    name: string;
}

export class SmdFile implements SmdFileInterface {
    active: boolean;
    fileType: string;
    mediaID: number;
    name: string;
    mediaName?: string; // same as name, but used in viewallmedia
    selected: boolean;
    private _shortName: string;
    type: string;
    isRaw: boolean;
    private _url: string;
    private _thumb: string;
    private _bigThumb: string;
    private _thumbUrl: string;
    icon: string;
    //isDeleteable: boolean;
    //isEditable: boolean;
    allowedActions: Array<string>;
    isNew: boolean;
    createDate: string;
    modifyDate: string;
    uploader: string;
    user: string;
    size: string;
    resolution: string;
    ratio: string;
    synced: boolean;
    orientation: string;
    instaOrientation: string;
    tags: Tag[];
    categories: Category[];
    carouselItems: CarouselItemResponseInterface[];
    galleryThumb: string;
    is360: any;
    socialType;
    socialChannels?: Array<string>;
    rawMedia;
    systemType?: string;
    systemTypeMessage?: string;
    partnerIDs: any[];
    organizationIDs: any[];
    disallowedPartnerIDs: any[];
    status: string;
    statusMessage: string;
    initialized: boolean;
    entityID: string;
    cacheID: string;
    notAllow = false;
    modifyUserName: string;
    editorMetaFile: string;
    canvaId?: string;
    userID?: number;
    organizationID?: number;
    uploaderUserName?: string;
    organizationName?: string;
    previewTimeOffset?: number;

    /**
     * @param {string} url
     * @param {CreateMediaConfig} config
     * @return {SmdFile}
     */
    static createMedia(url: string, config: CreateMediaConfig = null): SmdFile {
        config = {
            isSelected: true,
            isNew: true,
            type: FILE_TYPES.OG,
            mime: OPENGRAPH_FILE_MIMETYPE,
            name: "OpenGraph Image",
            ...(config || {}),
        };

        return new SmdFile(
            {
                name: config.name,
                mediaID: Date.now(),
                url: url,
                mime: config.mime,
                type: config.type,
                createDate: Utils.moment().format(BASE_DATETIME_FORMAT),
                selected: config.isSelected,
            },
            config.isNew
        );
    }

    /**
     * Set the properties
     *
     * @param {SmdFileInterface} file
     * @param {boolean} isNew
     */
    constructor(file: RawFile | SmdFile, isNew: boolean = false) {
        if (!!file.initialized) {
            return file as SmdFile;
        }

        if (!!file.entityID) {
            this.entityID = file.entityID;
        }

        if (!!file.cacheID) {
            this.cacheID = file.cacheID;
        }

        file = file as RawFile;

        this.initialized = true;

        this.rawMedia = cloneDeep(file);

        file.name = file.name || file.mediaName || "";
        // const fileName = file.name ? file.name.replace(/[()]/g, "") : "";
        const fileName = file.name ? file.name : "";

        this.mediaID =
            typeof file.mediaID === "number"
                ? file.mediaID
                : Number(file.mediaID);
        this.fileType = file.mime || file.type;
        this.type = Helpers.mimeTypeToObject(this.fileType).type;
        this.isRaw = Utils.isAbsoluteHttpUrl(file.url) && GOOGLE_CLOUD_STORAGE_BASE_URI === (file.url || "").substring(0, GOOGLE_CLOUD_STORAGE_BASE_URI.length);

        this.name = fileName;
        this.modifyUserName = file["modifyUserName"];

        if (file.type === FILE_TYPES.OG) {
            this.type = file.type;
            this.url =
                file.mediaType === FILE_TYPES.CAROUSEL
                    ? file.url
                    : `${file.url || ""}`;
            this.thumb = file["previewFileUrl"]
                ? `${file["previewFileUrl"]}`
                : (this.thumbUrl ? `${this.thumbUrl}` : `${this.url}`) || "";
            this.bigThumb = file["previewFileUrl"]
                ? `${file["previewFileUrl"]}`
                : (this.thumbUrl ? `${this.thumbUrl}` : `${this.url}`) || "";
        } else {
            this.url = file.url;
            this.thumbUrl = file.thumbUrl;

            this.thumb = file["previewFileUrl"]
                ? `${file["previewFileUrl"]}`
                : (this.thumbUrl ? `${this.thumbUrl}` : `${this.url}`) || "";
            this.bigThumb = file["previewFileUrl"]
                ? `${file["previewFileUrl"]}`
                : (this.thumbUrl ? `${this.thumbUrl}` : `${this.url}`) || "";
        }

        this.galleryThumb = this.thumb;

        this.createDate = file.createDate;
        this.modifyDate = file.modifyDate;
        this.uploader = file.uploader;
        //this.user = file.user;
        this.size = file.size;
        this.resolution = file.resolution || "---";
        this.ratio = file.ratio || "---";
        this.synced = file.synced;
        this.orientation = file.orientation || "---";
        this.instaOrientation = file.instaOrientation || "---";

        this.icon = Icons.getIcon(this.type);
        this.setShortName();

        this.tags = file.tags || [];
        this.categories = file.categories || [];

        this.selected = "selected" in file ? file["selected"] : false;
        this.active = true;
        this.isNew = isNew;
        this.socialChannels = file.socialChannel ? file.socialChannel : [];

        //this.isEditable = Utils.lodash.get(file, "isEditable", true);
        //this.isDeleteable = Utils.lodash.get(file, "isDeleteable", true);
        this.allowedActions = Utils.lodash.get(file, "allowedActions") || [];
        this.canvaId = Utils.lodash.get(file, "canvaId", null);

        this.userID = file.userID;
        this.organizationID = Utils.lodash.get(file, "organizationID", null);
        this.uploaderUserName = file.uploaderUserName;
        this.previewTimeOffset = file.previewTimeOffset;
        this.organizationName = file.organizationName;

        this.systemType = Utils.lodash.get(file, "systemType", "");
        this.systemTypeMessage = Utils.lodash.get(
            file,
            "systemTypeMessage",
            ""
        );

        this.status = Utils.lodash.get(file, "status", "");
        this.statusMessage = Utils.lodash.get(file, "statusMessage", "");

        this.partnerIDs = Utils.lodash
            .get(file, "partnerIDs", [])
            .map((id) => parseInt(id, null));
        this.organizationIDs = Utils.lodash
            .get(file, "organizationIDs", [])
            .map((id) => parseInt(id))
            .filter((id) => id);
        this.disallowedPartnerIDs = Utils.lodash
            .get(file, "disallowedPartnerIDs", [])
            .map((id) => parseInt(id, null));

        this.editorMetaFile = Utils.lodash.get(file, "editorMetaFile", null);

        if (file.is360) {
            this.is360 = file.is360;
        }

        if (file["carouselItems"]) {
            this.carouselItems = file["carouselItems"];
        }
    }

    addTokenIfTokenIsEmpty(url: string) {
        if (url && url.indexOf("token=") === -1 && !url.includes("base64")) {
            return url + "?token=" + Token.getToken();
        }
        return url;
    }

    /**
     * Get short name of file
     *
     * @returns {string}
     */
    get shortName(): string {
        return this._shortName;
    }

    /**
     * Set short name of file
     */
    setShortName(): void {
        if (this.name.length > MAX_FILE_NAME_LENGTH) {
            this._shortName = this.name.slice(0, MAX_FILE_NAME_LENGTH) + "...";
        } else {
            this._shortName = this.name;
        }
    }

    /**
     * Get file object
     *
     * @returns {SmdFileInterface}
     */
    getFile(): SmdFileInterface {
        return {
            mediaID: this.mediaID,
            name: this.name,
            url: this.url,
            thumb: this.thumb,
            bigThumb: this.bigThumb,
            fileType: this.fileType,
            type: this.type,
            icon: this.icon,
            shortName: this.shortName,
            selected: this.selected,
            active: this.active,
            isNew: this.isNew,
            createDate: this.createDate,
            modifyDate: this.modifyDate,
            uploader: this.uploader,
            //user: this.user,
            size: this.size,
            resolution: this.resolution,
            ratio: this.ratio,
            synced: this.synced,
            orientation: this.orientation,
            instaOrientation: this.instaOrientation,
            tags: this.tags,
            categories: this.categories,
            socialType: this.socialType,
        };
    }

    /**
     * Get raw media
     * @param file
     * @return {any}
     */
    public static getRawMedia(file: any) {
        const rawMedia = cloneDeep(file.rawMedia);

        if (!!rawMedia && rawMedia.rawMedia) {
            return SmdFile.getRawMedia(rawMedia);
        } else {
            if (rawMedia.carouselItems) {
                delete rawMedia.carouselItems;
            }
            return rawMedia;
        }
    }

    /**
     * Check two file equal
     *
     * @param {SmdFileInterface} file1
     * @param {SmdFileInterface} file2
     * @return {boolean}
     */
    public static isFilesEqual(
        file1: SmdFileInterface,
        file2: SmdFileInterface
    ): boolean {
        return file1.type === file2.type && file1.mediaID === file2.mediaID;
    }

    /**
     * Convert files to SmdFile in array
     *
     * @param {Array<SmdFileInterface>} files
     * @returns {Array<SmdFileInterface>}
     */
    public static initFileArray(files: RawFile[] | any[]): SmdFileInterface[] {
        const result: Array<SmdFileInterface> = [];

        for (const index in files) {
            const file = files[index];
            if (file !== "undefined") {
                // I know this is ugly solution but I need to fix the infinite loop on schedule list view
                const smdFile = new SmdFile(file);

                result.push(smdFile);
            }
        }

        return result;
    }

    /**
     * Enable break file selection?
     *
     * @param {SmdFileInterface} selectedFile
     * @param {Event} event
     * @return {boolean}
     */
    public static isBreakFileSelection(
        event: Event,
        selectedFile?: SmdFileInterface
    ): boolean {
        const disabledTags = ["smd-file", "a"];

        return selectedFile
            ? !selectedFile.active ||
                  disabledTags.indexOf(event.target["localName"]) > -1
            : disabledTags.indexOf(event.target["localName"]) > -1;
    }

    /**
     * Add domain prefix to media URl
     * @param url
     */
    static addDomainToMediaUrl(url: string) {
        return environment.apiUrl + url;
        // return  url;
    }

    /**
     * Media URL getter
     * @return {string}
     */
    get url(): string {
        return this._url;
    }

    /**
     * Media URL setter
     * @param {string} value
     */
    set url(value: string) {
        if ((value || "").includes("base64")) {
            this._url = value;

            return;
        }

        if (includes([FILE_TYPES.OG, FILE_TYPES.CAROUSEL], this.type) || this.isRaw) {
            this._url = value;
        } else {
            this._url = includes(value, environment.apiUrl)
                ? value
                : environment.apiUrl + value;
        }
    }

    get thumbUrl(): string {
        return this._thumbUrl;
    }

    set thumbUrl(value: string) {
        this._thumbUrl = value;
    }

    get thumb(): string {
        return this._thumb;
    }

    set thumb(value: string) {
        if ((value || "").includes("base64")) {
            this._thumb = value;

            return;
        }

        if (this.type === FILE_TYPES.OG || this.isRaw) {
            this._thumb = value;
        } else {
            if (!(this.url || "").includes("base64")) {
                value = value + "&thumb=250x250";

                if (!includes(value, environment.apiUrl)) {
                    value = environment.apiUrl + value;
                }
            }

            this._thumb = value;
        }
    }

    get bigThumb(): string {
        return this._bigThumb;
    }

    set bigThumb(value: string) {
        if ((value || "").includes("base64")) {
            this._bigThumb = value;

            return;
        }

        if (this.type === FILE_TYPES.OG || this.isRaw) {
            this._bigThumb = value;
        } else {
            if (!(this.url || "").includes("base64")) {
                value = value + "&thumb=365x365";

                if (!includes(value, environment.apiUrl)) {
                    value = environment.apiUrl + value;
                }
            }

            this._bigThumb = value;
        }
    }

    isActionAllowed(action: string): boolean {
        return this.allowedActions?.indexOf(action) > -1;
    }
}
