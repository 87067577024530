import { catchError, debounceTime } from "rxjs/operators";
import {
    Component,
    ElementRef,
    Inject,
    OnInit,
    ViewChild,
} from "@angular/core";
import {
    MAT_DIALOG_DATA,
    MatDialog,
    MatDialogRef,
} from "@angular/material/dialog";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { Observable, Subject, Subscription } from "rxjs";
import { Debounce, Helpers, MyErrorStateMatcher } from "../../services/helpers";
import { DialogLoaderComponent } from "../dialog-loader/dialog-loader.component";
import { MediaTagsService } from "../../modules/documents/media-tags.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CategoriesService } from "../../modules/posts/categories.service";
import { DialogFileUploaderComponent } from "../file-upload/dialog-file-uploader/dialog-file-uploader.component";
import { FormValidationService } from "../../services/form.validation.service";
import { DialogProgressComponent } from "../dialog-progress/dialog-progress.component";
import { DialogSuccessComponent } from "../dialog-success/dialog-success.component";
import { CreateCarouselComponent } from "./create-carousel/create-carousel.component";
import { SmdFileInterface } from "../../services/file.class";
import { LanguageService } from "../../services/language.service";
import { UserRolesService } from "../../services/user-roles.service";
import { Configs, FALLBACK_IMAGE } from "../../configs/configs";
import { FileBrowserAbstract } from "../../services/file-browser.abstract";
import { CarouselController } from "../../modules/posts/carousel/carousel.component";
import { FileBrowserService } from "../../services/file-browser.service";
import { DialogErrorComponent } from "../dialog-error/dialog-error.component";
import obj2fd from "obj2fd";
import { OpenModalService } from "~/src/app/modules/social-media-post/open-modal.service";
import { PartnerConfigService } from "~/src/app/shared/services/partner-config/partner-config.service";
import { PartnerPermissions } from "~/src/app/shared/services/partner-config/partner-config.options";
import Utils from "~/src/app/core/utils";
import { PartnersService } from "~/src/app/modules/administration/partners/partners.service";
import { Partner } from "~/src/app/shared/types/partners.model";
import { PostTemplateSystemTypesForCreateTemplate } from "~/src/app/modules/social-media-post/social-media-post.options";
import { CoreConfig } from "~/src/app/core/core.config";
import EditorControllerService from "~/src/app/components/image-editor/editor-controller.service";
import {
    DialogData,
    MediaRequestObject,
    ViewFileContainer,
    ViewFileObject,
} from "./upload-media.interface";
import { FILE_SIZE_LIMIT } from "./upload-media.config";
import { environment } from "~/src/environments/environment";
import { socialSiteAppearance } from "../../modules/social-media-post/social-media-platforms-config";
import { SOCIAL_MEDIA_TYPE_FACEBOOK, SOCIAL_MEDIA_TYPE_GMB, SOCIAL_MEDIA_TYPE_INSTAGRAM, SOCIAL_MEDIA_TYPE_LINKEDIN, SOCIAL_MEDIA_TYPE_TWITTER } from "../../core/constants";

declare var Canva: any;
interface IViewFileError {
    message: string;
    file: File;
}

@Component({
    selector: "smd-upload-media",
    templateUrl: "./upload-media.component.html",
    styleUrls: ["./upload-media.component.scss"],
})
export class UploadMediaComponent
    extends FileBrowserAbstract
    implements OnInit {
    @ViewChild(CreateCarouselComponent, { static: false })
    createCarouselComponent: CreateCarouselComponent;
    @ViewChild("videoElement", { read: ElementRef })
    videoElement: ElementRef<HTMLVideoElement>;
    @ViewChild("canvasElement", { read: ElementRef })
    canvasElement: ElementRef<HTMLCanvasElement>;

    // Data arrays
    fileList: File[] = [];
    viewFileList: ViewFileContainer[] = [];

    // Form
    @ViewChild("fileUploadInput") fileUploadInput: ElementRef<HTMLInputElement>;
    filesFormGroup: FormGroup = new FormGroup({
        files: new FormControl(""),
        filesNum: new FormControl("", [Validators.required, Validators.min(1)]),
        socialChannels: new FormControl([]),
    });
    errorMessages: object = {};
    editMediaErrorMessages: object = {};
    formMatcher = new MyErrorStateMatcher();
    _loader: MatDialogRef<DialogLoaderComponent>;
    _progress: MatDialogRef<DialogProgressComponent>;
    errorMessage: string;
    baseErrorMessages: string[] = [];
    hasMediaTag = false;
    hasMediaCategory = false;

    /**
     * Index of tabs
     *
     * @type {{UploadFile: number; CreateCarousel: number}}
     */
    tabIndex: {
        UploadFile: number;
        CreateCarousel: number;
    } = {
            UploadFile: 0,
            CreateCarousel: 1,
        };
    selectedTab: number;
    defaultTabIndex: number = this.tabIndex.UploadFile;
    selectedFileNum = 0;
    progressedFileNum = 0;
    filesIDCounter = 1;
    selectFilesCounter = 0;
    //canvaAPI = null;
    _fileSizeSum = 0;
    _uploadFilesNum = 0;
    _uploadProgressedFilesNum = 0;
    _formChange: Subscription;
    mediaQueue: MediaRequestObject[] = [];
    sendFile: Subject<any> = new Subject<any>();
    isAdmin = false;
    returnQueue: any;
    socialChannels = Configs.socials;
    isAdminMode = Utils.lodash.get(this.dialogData, "isAdminMode", false);
    isCanva = Utils.lodash.get(this.dialogData, "isCanva", false);
    canvaDialog = false;

    partners: Partner[] = [];
    platforms = [
        {
            id: SOCIAL_MEDIA_TYPE_FACEBOOK + "Post",
            name: "Facebook Post",
            socialType: SOCIAL_MEDIA_TYPE_FACEBOOK,
            iconClasses: "mdi mdi-facebook-box color-facebook",
        },
        {
            id: SOCIAL_MEDIA_TYPE_LINKEDIN + "Post",
            name: "LinkedIn Post",
            socialType: SOCIAL_MEDIA_TYPE_LINKEDIN,
            iconClasses: "mdi mdi-linkedin-box color-linkedin",
        },
        {
            id: SOCIAL_MEDIA_TYPE_TWITTER + "Post",
            name: "Twitter Post",
            socialType: SOCIAL_MEDIA_TYPE_TWITTER,
            iconClasses: "mdi mdi-twitter-box color-twitter",
        },
        {
            id: SOCIAL_MEDIA_TYPE_INSTAGRAM + "Post",
            name: "Instagram Post",
            socialType: SOCIAL_MEDIA_TYPE_INSTAGRAM,
            iconClasses: "mdi mdi-instagram color-instagram",
        },
        {
            id: SOCIAL_MEDIA_TYPE_INSTAGRAM + "Story",
            name: "Instagram Story",
            socialType: SOCIAL_MEDIA_TYPE_INSTAGRAM,
            iconClasses: "mdi mdi-instagram color-instagram",
        },
        {
            id: SOCIAL_MEDIA_TYPE_GMB + "Post",
            name: "Google My Business Post",
            shortName: "GMB Post",
            socialType: SOCIAL_MEDIA_TYPE_GMB,
            iconClasses: "mdi mdi-gmb color-gmb",
        },
    ];
    private _currentMedia: SmdFileInterface;
    private _currentMediaFile: File;
    private _currentMediaPreviewBase64: string;

    editMediaFormControlsName = {
        FileName: "name",
        Categories: "categories",
        Tags: "tags",
        Preview: "previewFile",
        Is360: "is360",
        PreviewTimeOffset: "previewTimeOffset",
        socialChannels: "socialChannels",
        Partners: "partnerIDs",
        SystemType: "systemType",
        Organization: "organizationIDs",
        CanvaId: "canvaId",
    };

    editMediaFormGroup: FormGroup = new FormGroup({
        [this.editMediaFormControlsName.FileName]: new FormControl(null, [
            Validators.required,
        ]),
        [this.editMediaFormControlsName.Categories]: new FormControl(null),
        [this.editMediaFormControlsName.Tags]: new FormControl(null),
        [this.editMediaFormControlsName.Preview]: new FormControl(null),
        [this.editMediaFormControlsName.Is360]: new FormControl(null),
        [this.editMediaFormControlsName.PreviewTimeOffset]: new FormControl(null),
        [this.editMediaFormControlsName.socialChannels]: new FormControl(null),
        [this.editMediaFormControlsName.Partners]: new FormControl([]),
        [this.editMediaFormControlsName.Organization]: new FormControl(
            this.dialogData && this.dialogData.media
                ? this.dialogData.media.organizationIDs
                : []
        ),
        [this.editMediaFormControlsName.SystemType]: new FormControl(
            Utils.lodash.get(this.dialogData, "media.systemType", null)
        ),
        [this.editMediaFormControlsName.CanvaId]: new FormControl(null),
    });

    videoCanPlay$ = new Subject();
    videoError$ = new Subject<any>();

    // image editor
    editableImageIndex = null;

    readonly availableFileTypes = [
        "image/*",
        "video/*",
        ".3g2",
        ".3gp",
        ".asf",
        ".m2ts",
        ".m4v",
        ".mkv",
        ".mod",
        ".mov",
        ".mp4",
        ".mpeg",
        ".mpg",
        ".mts",
    ].join(",");

    constructor(
        public _dialogRef: MatDialogRef<UploadMediaComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: DialogData,
        public dialog: MatDialog,
        private _mediaTagsService: MediaTagsService,
        private _categoriesService: CategoriesService,
        private _uRole: UserRolesService,
        public languageService: LanguageService,
        public service: FileBrowserService,
        public carouselController: CarouselController,
        private openModal: OpenModalService,
        private partnerConfig: PartnerConfigService,
        private partnerService: PartnersService
    ) {
        super(service, carouselController);

        this.hasMediaTag = this.partnerConfig.hasConfig(
            PartnerPermissions.MediaTag
        );
        this.hasMediaCategory = this.partnerConfig.hasConfig(
            PartnerPermissions.MediaCategory
        );
        this.selectedTab = this.tabIndex.UploadFile;

        this._uRole.currentUserCan("admin").then((admin) => {
            this.isAdmin = !!admin;
        });
    }

    ngOnInit() {
        this.platforms = this.platforms.filter(item => socialSiteAppearance?.[item.id]?.medialibrary !== false);
        this.socialChannels = this.socialChannels.filter(item => socialSiteAppearance?.[item.id]?.medialibrary !== false);

        this._dialogRef.updateSize("850px");

        if (this.dialogData && !this.isCanva) {
            if (this.dialogData.carousel) {
                this.initCarouselEdit();
            }

            if (this.dialogData.media) {
                this.initMediaEdit();
            }
        }

        if (this.isAdminMode) {
            this.partnerService.getPartners().then((response: any) => {
                this.partners = Helpers.orderBy(response.partners, "name");

                this.editMediaFormGroup
                    .get(this.editMediaFormControlsName.Partners)
                    .setValue(
                        Utils.lodash.get(this.currentMedia, "partnerIDs", [])
                    );
            });
        }

        if (this.isCanva) {
            this._dialogRef.updateSize("333px");
            this.canvaDialog = true;
            if (this.dialogData?.media?.canvaId) {
                this._dialogRef.updateSize("0px", "0px");
                this.canvaClick(null, this.dialogData.media.canvaId);
            }
        }
    }

    async canvaClick(platform: string, existingDesignId: string) {
        if (!globalThis.canvaAPI && !globalThis.canvaAPI?.createDesign) {
            await (async () => {
                if (Canva && Canva.DesignButton) {
                    globalThis.canvaAPI = await Canva.DesignButton.initialize({
                        apiKey: environment.production
                            ? Configs.keys.canva_prod
                            : Configs.keys.canva_dev,
                    });
                }
            })();
        }

        const type = (s) =>
        ({
            instagramStory: "InstagramStory",
            instagramPost: "InstagramPost",
            twitterPost: "TwitterPost",
            facebookPost: "FacebookPost",
        }[s.id] || "SocialMedia");

        const handlePublish = (opts) => {
            this.canvaDialog = false;
            this._dialogRef.updateSize("850px");
            this.selectFile(null, opts);
        };

        const handleCreateClose = () => {
            this.canvaDialog = this.canvaDialog || false;
        };

        const handleEditClose = () => {
            this._dialogRef.close(false);
        };

        if (!platform && existingDesignId) {
            globalThis.canvaAPI.createDesign({
                design: {
                    id: existingDesignId,
                },
                editor: {
                    fileType: "jpeg",
                    publishLabel: "Save to Esemdee",
                },
                onDesignPublish: handlePublish,
                onDesignClose: handleEditClose,
            });
        } else {
            globalThis.canvaAPI.createDesign({
                design: {
                    type: type(platform),
                },
                editor: {
                    fileType: "jpeg",
                    publishLabel: "Save to Esemdee",
                },
                onDesignPublish: handlePublish,
                onDesignClose: handleCreateClose,
            });
        }
    }

    initCarouselEdit() {
        this.defaultTabIndex = this.tabIndex.CreateCarousel;
        this.selectedTab = this.tabIndex.CreateCarousel;
        this.initCreateCarouselTab();
    }

    initMediaEdit() {
        const loader = this.openModal.loader(DialogLoaderComponent);

        this.currentMedia = this.dialogData.media;

        this.editMediaFormGroup
            .get(this.editMediaFormControlsName.FileName)
            .setValue(this.currentMedia.name);

        fetch(this.currentMedia.url)
            .then((res) => res.blob())
            .then((blob: Blob) => {
                this.currentMediaFile = new File(
                    [blob],
                    this.currentMedia.name
                );
                loader.close();
            })
            .catch(() => loader.close());

        if (
            this.currentMedia.categories &&
            this.currentMedia.categories.length > 0
        ) {
            this.editMediaFormGroup
                .get(this.editMediaFormControlsName.Categories)
                .setValue(this.currentMedia.categories[0].name);
        }

        if (this.currentMedia.tags && this.currentMedia.tags.length > 0) {
            this.editMediaFormGroup
                .get(this.editMediaFormControlsName.Tags)
                .setValue(this.currentMedia.tags);
        }

        if (
            this.currentMedia.socialChannels &&
            this.currentMedia.socialChannels.length > 0
        ) {
            this.editMediaFormGroup
                .get(this.editMediaFormControlsName.socialChannels)
                .setValue(this.currentMedia.socialChannels);
        }

        if (this.currentMedia.is360) {
            this.editMediaFormGroup
                .get(this.editMediaFormControlsName.Is360)
                .setValue(this.currentMedia.is360);
        }

        if (this.currentMedia.previewTimeOffset) {
            this.editMediaFormGroup
                .get(this.editMediaFormControlsName.PreviewTimeOffset)
                .setValue(this.currentMedia.previewTimeOffset);
        }

        if (this.currentMedia.canvaId) {
            this.editMediaFormGroup
                .get(this.editMediaFormControlsName.CanvaId)
                .setValue(this.currentMedia.canvaId);
        }

        if (this.isAdminMode) {
            this.setPartnersValidation(
                this.editMediaFormGroup,
                this.editMediaFormControlsName.SystemType,
                this.editMediaFormControlsName.Partners
            );
            this.changeSystemType(
                this.editMediaFormGroup,
                this.editMediaFormControlsName.SystemType,
                this.editMediaFormControlsName.Partners
            );
        }
    }

    /**
     * Save modified media
     */
    saveMedia() {
        if (this.editMediaFormValidation()) {
            const loader = this.openModal.loader(DialogLoaderComponent);

            loader.afterClosed().subscribe(({ isSuccess, response }) => {
                if (isSuccess) {
                    this.dialog
                        .open(DialogSuccessComponent, {
                            data: {
                                message: this.languageService.getLine(
                                    "edit.media.upload.success"
                                ),
                            },
                        })
                        .afterClosed()
                        .subscribe(() => {
                            this._dialogRef.close(true);
                        });
                } else {
                    this.dialog.open(DialogErrorComponent, {
                        data: {
                            message:
                                FormValidationService.readError(response)
                                    .message,
                        },
                    });
                    FormValidationService.setFormControlsIncorrect(
                        response,
                        this.editMediaFormGroup,
                        this.errorMessages
                    );
                    this.errorMessages =
                        FormValidationService.readError(response).formMessages;
                }
            });

            const data = this.editMediaFormGroup.getRawValue();

            for (const name in data) {
                const value = data[name];

                if (!value) {
                    delete data[name];
                } else if (value.length) {
                    if (
                        [this.editMediaFormControlsName.Tags].indexOf(name) > -1
                    ) {
                        data[name] = JSON.stringify(value);
                    }
                }
            }

            this.editItemWithPost(
                this.currentMedia.mediaID,
                obj2fd(data),
                (response) =>
                    loader.close({ isSuccess: true, response: response }),
                (error) => loader.close({ isSuccess: false, response: error })
            );
        }
    }

    /**
     * Init data for request and upload files
     */
    @Debounce()
    prepareData() {
        this.returnQueue = [];

        this._progress = this.dialog.open(DialogProgressComponent, {
            data: {
                title: LanguageService.getLine("documents.media.upload"),
            },
            disableClose: true,
        });

        for (const file of this.viewFileList) {
            const tags =
                this.filesFormGroup.controls[file.formControlsName.tags]
                    .value,
                categories =
                    this.filesFormGroup.controls[file.formControlsName.category]
                        .value,
                socialChannels: string[] =
                    this.filesFormGroup.controls[
                        file.formControlsName.socialChannels
                    ].value || [],
                partnerIDs = this.isAdminMode
                    ? this.filesFormGroup.controls[
                        file.formControlsName.partners
                    ].value
                    : null,
                systemType = this.isAdminMode
                    ? this.filesFormGroup.controls[
                        file.formControlsName.systemType
                    ].value
                    : null,
                organizationIDs = this.filesFormGroup.get(
                    file.formControlsName.organizationIDs
                ).value,
                canvaId =
                    this.filesFormGroup.controls[file.formControlsName.canvaId]
                        .value;

            this._uploadFilesNum++;

            const mediaRequestObject: MediaRequestObject = {
                files: [],
                socialChannels: [],
                organizationIDs: organizationIDs,
                name: this.filesFormGroup.controls[file.formControlsName.name]
                    .value,
                is360: file.is360 ? 1 : null,
                previewTimeOffset: file.previewTimeOffset || null,
                isVideo: file.file.type.indexOf("image") === -1 ? 1 : null,
                mediaIndex: file.viewFile.id,
            };

            if (canvaId) {
                mediaRequestObject.canvaId = canvaId;
            }

            if (tags) {
                mediaRequestObject.tags = JSON.stringify(tags);
            }

            if (categories) {
                mediaRequestObject.categories = JSON.stringify([categories]);
            }

            if (!!partnerIDs) {
                mediaRequestObject.partnerIDs = partnerIDs;
            }

            if (!!systemType) {
                mediaRequestObject.systemType = systemType;
            }

            if (mediaRequestObject.isVideo) {
                mediaRequestObject.files.push(file.previewFile);
            }

            mediaRequestObject.files.push({
                file: file.file,
                meta: file.viewFile.editorMeta,
            });

            socialChannels.forEach((socialChannel) => {
                mediaRequestObject.socialChannels.push(socialChannel);
            });

            this.mediaQueue.push(mediaRequestObject);
        }

        this.uploadFiles();
    }

    uploadFiles() {
        this.initFilesForm(false);

        const listener = this.sendFile.subscribe(() => {
            this.uploadFile(this.mediaQueue[0]);
            this.mediaQueue.splice(0, 1);
        });

        /**
         * Medias upload start
         */
        this.sendFile.next();
        listener.unsubscribe();
    }

    successCreateWithProgress(response: any, request: any): void {
        super.successCreateWithProgress(response, request);

        this.finishMediaUploadProgress(true, response, request);
    }

    failedCreateWithProgress(error: any, request: any): void {
        super.failedCreateWithProgress(error, request);
        this.finishMediaUploadProgress(false, error, request);
    }
    getFilenameFromURL(url) {
        const queryString = url.split('?')[1];
        const params = new URLSearchParams(queryString);
        const disposition = params.get('response-content-disposition');

        if (disposition) {
            const matches = /filename\*?=(?:UTF-\d['']*)?([^;]+)/.exec(disposition);
            if (matches && matches[1]) {
                let filenameWithExtension = decodeURIComponent(matches[1].trim());
                // Extract filename without extension
                let filenameWithoutExtension = filenameWithExtension.split('.').slice(0, -1).join('.');
                return filenameWithoutExtension;
            }
        }

        return null; // Return null if filename is not found
    }
    /**
     * Select new file
     *
     * @param event
     */
    async selectFile(event, canvaOpts = null) {
        var files;

        if (canvaOpts) {
            let response = await fetch(canvaOpts.exportUrl);

            let data = await response.blob();

            let metadata = {
                type: data.type,
            };

            let extension = data.type ? data.type.split("/").pop() : "jpeg";
            const canvafilename = this.getFilenameFromURL(canvaOpts.exportUrl);
            files = [
                new File(
                    [data],
                    canvafilename + "." + extension,
                    metadata
                ),
            ];
        } else {
            files = event.target.files;
        }
        this.baseErrorMessages = [];

        if (files.length === 0) {
            return false;
        }

        this.selectedFileNum = files.length;
        this._loader = this.openModal.loader(DialogLoaderComponent);

        let index = 0;
        for (const file of files) {
            index++;

            if (file.size >= FILE_SIZE_LIMIT) {
                this.baseErrorMessages.push(
                    LanguageService.getLine("media.error.size.large", {
                        fileName: file.name,
                        fileSize: Helpers.formatBytes(file.size, 2),
                        maxFileSize: Helpers.formatBytes(FILE_SIZE_LIMIT, 2),
                    })
                );

                if (index === files.length) {
                    this._loader.close();
                }

                continue;
            }

            if (this.fileList.indexOf(file) === -1) {
                if (
                    file.type.indexOf("image") > -1 ||
                    file.type.indexOf("video") > -1
                ) {
                    try {
                        await this.renderViewFileObject(file)
                            .toPromise()
                            .then((viewFile) => {
                                const nameControlName = `fileName${viewFile.id}`,
                                    tagsControlName = `tags${viewFile.id}`,
                                    socialChannelsName = `socialChannels${viewFile.id}`,
                                    categoryControlName = `category${viewFile.id}`,
                                    partnersControlName = `partners${viewFile.id}`,
                                    systemTypeControlName = `systemType${viewFile.id}`,
                                    organizationControlName = `organizationIDs${viewFile.id}`,
                                    canvaIdControlName = `canvaId${viewFile.id}`;

                                const viewFileContainer: ViewFileContainer = {
                                    file: file,
                                    viewFile: viewFile,
                                    formControlsName: {
                                        name: nameControlName,
                                        tags: tagsControlName,
                                        category: categoryControlName,
                                        socialChannels: socialChannelsName,
                                        organizationIDs:
                                            organizationControlName,
                                        canvaId: canvaIdControlName,
                                    },
                                    is360: false,
                                    previewFile: Helpers.dataURLtoFile(
                                        viewFile.url,
                                        `preview-${viewFile.name.slice(
                                            0,
                                            -4
                                        )}.jpeg`
                                    ),
                                };

                                if (this.isAdminMode) {
                                    viewFileContainer.formControlsName = {
                                        ...viewFileContainer.formControlsName,
                                        partners: partnersControlName,
                                        systemType: systemTypeControlName,
                                    };
                                }

                                this.viewFileList.push(viewFileContainer);
                                this.updateFileSizeSum();

                                this.setFilesNumControlValue();

                                let fileName: string | null;
                                if (canvaOpts) {
                                    fileName = canvaOpts.designTitle.substring(
                                        0,
                                        32
                                    ).trim();
                                    if (fileName == 'Untitled Design') {
                                        fileName = null;
                                    }
                                }
                                if (!fileName) {
                                    fileName = viewFile.name;
                                    if (fileName.includes('.')) {
                                        let fileNameParts = fileName.split('.');
                                        fileName = fileNameParts.slice(0, -1).join('.');
                                    }
                                }
                                this.filesFormGroup.addControl(
                                    nameControlName,
                                    new FormControl(
                                        fileName,
                                        [Validators.required]
                                    )
                                );
                                this.filesFormGroup.addControl(
                                    tagsControlName,
                                    new FormControl(null)
                                );
                                this.filesFormGroup.addControl(
                                    categoryControlName,
                                    new FormControl(null)
                                );
                                this.filesFormGroup.addControl(
                                    socialChannelsName,
                                    new FormControl(null)
                                );
                                this.filesFormGroup.addControl(
                                    organizationControlName,
                                    new FormControl([])
                                );
                                this.filesFormGroup.addControl(
                                    canvaIdControlName,
                                    new FormControl(
                                        canvaOpts?.designId
                                            ? canvaOpts.designId
                                            : null
                                    )
                                );

                                if (this.isAdminMode) {
                                    this.filesFormGroup.addControl(
                                        systemTypeControlName,
                                        new FormControl(null, [
                                            Validators.required,
                                        ])
                                    );
                                    this.filesFormGroup.addControl(
                                        partnersControlName,
                                        new FormControl({
                                            value: [],
                                            disabled: true,
                                        })
                                    );

                                    this.changeSystemType(
                                        this.filesFormGroup,
                                        systemTypeControlName,
                                        partnersControlName
                                    );
                                }

                                this.finishFileSelectProgress();
                            })
                            .catch((err: IViewFileError) => {
                                this.baseErrorMessages.push(err.message);
                                this.finishFileSelectProgress();
                            });
                    } catch (e) {
                        console.log({ e });
                    }
                } else {
                    return false;
                }

                this.fileList.push(file);
            }
        }
    }

    /**
     * Remove file from selected files list
     *
     * @param {File} file
     * @param {ViewFileObject} viewFile
     */
    removeFile(file: ViewFileContainer, event?: MouseEvent) {
        const index = this.fileList.indexOf(file.file),
            viewIndex = this.viewFileList.findIndex((value) => {
                return value.viewFile === file.viewFile;
            });

        if (index > -1) {
            this.fileList.splice(index, 1);
        }

        if (viewIndex > -1) {
            this.viewFileList.splice(viewIndex, 1);
            this.setFilesNumControlValue();

            if (event) {
                this.initFilesForm(false);
            }

            this.filesFormGroup.removeControl(file.formControlsName.name);
            this.filesFormGroup.removeControl(file.formControlsName.tags);
            this.filesFormGroup.removeControl(file.formControlsName.category);
            this.filesFormGroup.removeControl(
                file.formControlsName.socialChannels
            );
            this.filesFormGroup.removeControl(file.formControlsName.canvaId);
            this.filesFormGroup.removeControl(file.formControlsName.partners);
            this.filesFormGroup.removeControl(file.formControlsName.systemType);

            if (event) {
                this.initFilesForm();
            }
        }

        this.updateFileSizeSum();

        if (!this.fileList.length) {
            this.resetAlerts();
        }
    }

    private updateFileSizeSum() {
        let sum: number = 0;

        Utils.lodash.each(this.viewFileList, (file) => {
            sum += file.viewFile.sizeInInt;
        });

        this._fileSizeSum = sum;
    }

    /**
     * Render view file object
     *
     * @param {File} file
     * @return {Observable<ViewFileObject>}
     */
    renderViewFileObject(file: File): Observable<ViewFileObject> {
        return new Observable<ViewFileObject>((observer) => {
            const viewFile: ViewFileObject = {
                id: this.filesIDCounter,
                name: file.name,
                sizeInInt: file.size,
                size: Helpers.formatBytes(file.size, 2),
                url: "",
            };

            this.filesIDCounter++;

            if (file.type.indexOf("image") > -1) {
                this.getImagePreviewSrc(file).subscribe((src: string) => {
                    viewFile.url = src;
                    viewFile.editorMeta = null;

                    observer.next(viewFile);
                    observer.complete();
                });
            } else if (file.type.indexOf("video") > -1) {
                this.getVideoPreview(file)
                    .pipe(
                        catchError((error, caught) => {
                            observer.error(error);
                            observer.complete();
                            return caught;
                        })
                    )
                    .subscribe((src: string) => {
                        viewFile.url = src;

                        observer.next(viewFile);
                        observer.complete();
                    });
            }
        });
    }

    /**
     * Get preview from selected file
     *
     * @param {File} file
     * @return {Observable<string>}
     */
    getImagePreviewSrc(file: File): Observable<string> {
        return new Observable<string>((observer) => {
            const reader = new FileReader();

            reader.onload = (e) => {
                const src = e.target["result"];

                observer.next(src.toString());
                observer.complete();
            };

            reader.readAsDataURL(file);
        });
    }

    /**
     * Get video preview src
     *
     * @param {File} file
     * @return {Observable<string>}
     */
    getVideoPreview(file: File): Observable<string> {
        return new Observable<string>((observe) => {
            const ctx = this.canvasElement.nativeElement.getContext("2d");

            const subscription = this.videoCanPlay$.subscribe(() => {
                this.canvasElement.nativeElement.width =
                    this.videoElement.nativeElement.videoWidth;
                this.canvasElement.nativeElement.height =
                    this.videoElement.nativeElement.videoHeight;

                setTimeout(() => {
                    ctx.drawImage(
                        this.videoElement.nativeElement,
                        0,
                        0,
                        this.videoElement.nativeElement.videoWidth,
                        this.videoElement.nativeElement.videoHeight
                    );

                    subscription.unsubscribe();
                    errorSubscription?.unsubscribe();

                    observe.next(this.canvasElement.nativeElement.toDataURL());
                    observe.complete();
                }, 1500);
            });
            const errorSubscription = this.videoError$.subscribe((data) => {
                subscription?.unsubscribe();
                errorSubscription?.unsubscribe();
                observe.error({
                    file,
                    message: LanguageService.getLine(
                        "upload.media.format.error",
                        {
                            name: file.name,
                        }
                    ),
                } as IViewFileError);
                observe.complete();
            });

            const fileBlob = URL.createObjectURL(file);

            this.videoElement.nativeElement.setAttribute("src", fileBlob);

            this.videoElement.nativeElement.load();
        });
    }
    // getVideoPreview(file: File): Observable<string> {
    //     return new Observable<string>((observe) => {
    //         const ctx = this.canvasElement.nativeElement.getContext("2d");

    //         const subscription = this.videoCanPlay$.subscribe(() => {
    //             this.canvasElement.nativeElement.width =
    //                 this.videoElement.nativeElement.videoWidth;
    //             this.canvasElement.nativeElement.height =
    //                 this.videoElement.nativeElement.videoHeight;

    //             setTimeout(() => {
    //                 ctx.drawImage(
    //                     this.videoElement.nativeElement,
    //                     0,
    //                     0,
    //                     this.videoElement.nativeElement.videoWidth,
    //                     this.videoElement.nativeElement.videoHeight
    //                 );

    //                 subscription.unsubscribe();
    //                 errorSubscription?.unsubscribe();
    //                 subscription.unsubscribe(); errorSubscription?.unsubscribe(); const canvasc = document.createElement('canvas');
    //                 const originalWidth = this.videoElement.nativeElement.videoWidth;
    //                  const originalHeight = this.videoElement.nativeElement.videoHeight;
    //                   const targetWidth = 365;
    //                    const targetHeight = (originalHeight / originalWidth) * targetWidth;
    //                     canvasc.width = targetWidth;
    //                      canvasc.height = targetHeight;
    //                       const ctxc = canvasc.getContext('2d');
    //                        ctxc.drawImage(this.videoElement.nativeElement, 0, 0, originalWidth, originalHeight, 0, 0, targetWidth, targetHeight); 
    //                        // observe.next(this.canvasElement.nativeElement.toDataURL());
    //                         observe.next(canvasc.toDataURL());
    //                 // observe.next(this.canvasElement.nativeElement.toDataURL());
    //                 observe.complete();
    //             }, 1500);
    //         });
    //         const errorSubscription = this.videoError$.subscribe((data) => {
    //             subscription?.unsubscribe();
    //             errorSubscription?.unsubscribe();
    //             observe.error({
    //                 file,
    //                 message: LanguageService.getLine(
    //                     "upload.media.format.error",
    //                     {
    //                         name: file.name,
    //                     }
    //                 ),
    //             } as IViewFileError);
    //             observe.complete();
    //         });

    //         const fileBlob = URL.createObjectURL(file);

    //         this.videoElement.nativeElement.setAttribute("src", fileBlob);

    //         this.videoElement.nativeElement.load();
    //     });
    // }

    setVideoPreview(file: ViewFileContainer) {
        const data = {
            file: this.currentMedia ? this.currentMediaFile : file.file,
            src: this.currentMedia ? this.currentMedia.url : file.viewFile.url,
            name: this.currentMedia
                ? this.currentMedia.name
                : file.viewFile.name,
        };

        if (this.currentMedia) {
            if (this.currentMedia.is360) {
                data["is360"] = true;
            }

            if (this.currentMedia.previewTimeOffset) {
                data["previewTimeOffset"] = this.currentMedia.previewTimeOffset;
            }
        } else {
            data["previewImageData"] = file.viewFile.url;
        }

        this.dialog
            .open(DialogFileUploaderComponent, {
                width: "850px",
                data: data,
            })
            .afterClosed()
            .subscribe(
                (result: {
                    previewFile: File;
                    previewImageData: string;
                    is360?: boolean;
                    previewTimeOffset?: number;
                }) => {
                    if (typeof result === "object") {
                        file.is360 = result.is360;
                        file.previewTimeOffset = result.previewTimeOffset;

                        this.editMediaFormGroup
                            .get(this.editMediaFormControlsName.Preview)
                            .setValue(result.previewFile);
                        this.editMediaFormGroup
                            .get(this.editMediaFormControlsName.Is360)
                            .setValue(result.is360);
                        this.editMediaFormGroup
                            .get(this.editMediaFormControlsName.PreviewTimeOffset)
                            .setValue(result.previewTimeOffset);

                        if (this.currentMedia) {
                            this.currentMedia.isRaw = true;
                            this.currentMedia.thumb = result.previewImageData;
                            this.currentMediaPreviewBase64 =
                                result.previewImageData;
                        } else {
                            file.previewFile = result.previewFile;
                            file.viewFile.url = result.previewImageData;
                        }
                    }
                }
            );
    }

    editImage(index: number) {
        this.editableImageIndex = index;
        EditorControllerService.open({
            media: this.viewFileList[index].viewFile,
            callback: this.saveEditedImage.bind(this),
        });
    }

    private saveEditedImage(response) {
        if (
            this.editableImageIndex === null &&
            this.editableImageIndex > this.viewFileList
        ) {
            return;
        }

        response.sizeInInt = response.file.size;
        response.size = Helpers.formatBytes(response.file.size, 2);
        this.updateFileSizeSum();

        this.viewFileList[this.editableImageIndex].file = response.file;
        this.viewFileList[this.editableImageIndex].viewFile = response;

        this.editableImageIndex = null;
    }

    getMediaIDFromResponse(response: any) {
        const itemWithMediaIndex = response.body.data.files.find(function (
            item
        ) {
            return item.mediaIndex !== null;
        });
        return itemWithMediaIndex["mediaIndex"] || null;
    }

    /**
     * Finish files select progress, close loader
     */
    finishFileSelectProgress() {
        this.progressedFileNum++;

        if (this.selectedFileNum === this.progressedFileNum) {
            this.selectedFileNum = 0;
            this.progressedFileNum = 0;

            this._loader.close();

            this.initFilesForm();
        }
    }

    finishMediaUploadProgress(success: boolean, res: any, req: any) {
        this._uploadProgressedFilesNum++;
        this._progress.componentInstance.progressValue =
            this.uploadProgressValue;

        if (success) {
            const viewFileID = Number(this.getMediaIDFromResponse(res)),
                file = this.viewFileList.find((value) => {
                    return value.viewFile.id === viewFileID;
                });

            if (file) {
                this.removeFile(file);
            } else {
                console.error("Hmmmm... not compatible with legacy code.");
                return;
            }

            if (res && res.body && res.body.data && res.body.data.files) {
                res.body.data.files.map((item) => {
                    if (item.type !== "preview") {
                        this.returnQueue.push(item.mediaID);
                    }
                });
            }
        }

        if (this._uploadFilesNum === this._uploadProgressedFilesNum) {
            this._uploadFilesNum = 0;
            this._uploadProgressedFilesNum = 0;

            if (!res || !res.body || !res.body.data || !res.body.data.files) {
                let messages =
                    FormValidationService.readError(res)["formMessages"];
                messages = Object.values(messages).concat(" ");
                this.errorMessage =
                    messages ||
                    LanguageService.getLine("upload.media.base.error");
                this.initFilesForm();
            } else {
                this.errorMessage = null;

                this.dialog
                    .open(DialogSuccessComponent, {
                        data: {
                            message: LanguageService.getLine(
                                "upload.media.upload.success"
                            ),
                        },
                    })
                    .afterClosed()
                    .subscribe(() => {
                        this._dialogRef.close({
                            success: true,
                            response: this.returnQueue,
                            request: req,
                        });
                    });
            }

            this._progress.close();
        }

        if (this.mediaQueue.length > 0) {
            this.uploadFiles();
        }
    }

    /**
     * Files form validation
     *
     * @return {boolean}
     */
    filesFormValidation(): boolean {
        if (this.filesFormGroup.invalid) {
            this.errorMessages = {};

            this.errorMessages = FormValidationService.getMessages(
                this.filesFormGroup.controls
            );

            return false;
        } else {
            return true;
        }
    }

    editMediaFormValidation(): boolean {
        this.editMediaErrorMessages = {};

        if (this.editMediaFormGroup.valid) {
            return true;
        } else {
            this.editMediaErrorMessages = FormValidationService.getMessages(
                this.editMediaFormGroup.controls
            );

            for (const key in this.editMediaFormControlsName) {
                const controlName = this.editMediaFormControlsName[key];

                if (controlName in this.editMediaErrorMessages) {
                    this.editMediaFormGroup.get(controlName).markAsTouched();
                }
            }

            return false;
        }
    }

    @Debounce()
    initFilesForm(enable: boolean = true) {
        if (enable) {
            this._formChange = this.filesFormGroup.valueChanges.subscribe(
                () => {
                    this.filesFormValidation();
                }
            );
        } else {
            this._formChange?.unsubscribe();
        }
    }

    fileFormSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
    }

    triggerFileInputClick() {
        this.fileUploadInput.nativeElement.click();

        if (this.selectFilesCounter !== 0) {
            this.initFilesForm(false);
        }

        this.selectFilesCounter++;
    }

    changeTab(event: MatTabChangeEvent) {
        for (const tabName in this.tabIndex) {
            const index = this.tabIndex[tabName];

            if (event.index === index) {
                this.selectedTab = index;
            }
        }

        if (this.onCreateCarouselTab) {
            this.initCreateCarouselTab();
        }

        if (this.onUploadFileTab) {
            this.initUploadFileTab();
        }
    }

    resetAlerts() {
        this.errorMessage = undefined;
    }

    initCreateCarouselTab() {
        this._dialogRef.updateSize("1535px");
    }

    initUploadFileTab() {
        this._dialogRef.updateSize("850px");
    }

    setFilesNumControlValue() {
        this.filesFormGroup.controls["filesNum"].setValue(
            this.viewFileList.length
        );
    }

    chipsValueChange(controlName: string, value, fallbackValue: any) {
        this.filesFormGroup
            .get(controlName)
            .setValue(value ? value : fallbackValue);
    }

    get systemTypes() {
        return PostTemplateSystemTypesForCreateTemplate;
    }

    get onUploadFileTab() {
        return this.selectedTab === this.tabIndex.UploadFile;
    }

    get onCreateCarouselTab() {
        return this.selectedTab === this.tabIndex.CreateCarousel;
    }

    get uploadProgressValue() {
        return Math.round(
            (this._uploadProgressedFilesNum / this._uploadFilesNum) * 100
        );
    }

    /**
     * Get total file size
     *
     * @return {string}
     */
    get totalFileSize() {
        return Helpers.formatBytes(this._fileSizeSum, 2);
    }

    get currentMedia(): SmdFileInterface {
        return this._currentMedia;
    }

    set currentMedia(value: SmdFileInterface) {
        this._currentMedia = value;
    }

    get currentMediaFile(): File {
        return this._currentMediaFile;
    }

    set currentMediaFile(value: File) {
        this._currentMediaFile = value;
    }

    get currentMediaPreviewBase64(): string {
        return this._currentMediaPreviewBase64;
    }

    set currentMediaPreviewBase64(value: string) {
        this._currentMediaPreviewBase64 = value;
    }

    get fallbackImage() {
        return FALLBACK_IMAGE;
    }

    /**
     * Change system type value
     * @param systemTypeControlName
     * @param parentsControlName
     */
    private changeSystemType(
        formGroup: FormGroup,
        systemTypeControlName: string,
        parentsControlName: string
    ) {
        formGroup
            .get(systemTypeControlName)
            .valueChanges.pipe(debounceTime(100))
            .subscribe((value) => {
                this.setPartnersValidation(
                    formGroup,
                    systemTypeControlName,
                    parentsControlName
                );
            });
    }

    private setPartnersValidation(
        formGroup: FormGroup,
        systemTypeControlName: string,
        parentsControlName: string
    ) {
        if (
            formGroup.get(systemTypeControlName).value ===
            CoreConfig.getSystemTypes().Branded
        ) {
            formGroup
                .get(parentsControlName)
                .setValidators([Validators.required]);
            formGroup.get(parentsControlName).enable();
        } else {
            formGroup.get(parentsControlName).setValidators([]);
            formGroup.get(parentsControlName).disable();
        }

        formGroup
            .get(parentsControlName)
            .updateValueAndValidity({ emitEvent: true });
    }
}
