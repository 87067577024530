import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatCheckbox } from '@angular/material/checkbox';
import { ComponentAbstract } from '../../../services/component.abstract';
import { FileUploadService } from '../file-upload.service';
import { Debounce, Helpers } from '../../../services/helpers';
import { DialogLoaderComponent } from '../../dialog-loader/dialog-loader.component';
import { LanguageService } from '../../../services/language.service';

interface DialogData {
    file: File;
    previewImageData: string;
    is360?: boolean;
    previewTimeOffset?: number | string; // string for undefined, since there was a bug in the past that caused it to get saved like that
    src?: string;
    name?: string;
}

@Component({
    selector: 'smd-dialog-file-uploader',
    templateUrl: './dialog-file-uploader.component.html',
    styleUrls: ['./dialog-file-uploader.component.scss']
})
export class DialogFileUploaderComponent extends ComponentAbstract implements OnInit {

    _loader: MatDialogRef<DialogLoaderComponent>;

    @ViewChild('video', { static: true }) videoTag: ElementRef<HTMLVideoElement>;

    @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement>;

    @ViewChild('is360', { static: true }) is360: MatCheckbox;

    _ctx: CanvasRenderingContext2D;

    file: File;

    progress = 0;

    private _videoLength = 0;

    private _currentTime = 0;

    previewImage: File;
    previewImageData: string;

    renderPreviewButtonDisabled = false;

    constructor(
        public dialogRef: MatDialogRef<DialogFileUploaderComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        public _dialog: MatDialog,
        public service: FileUploadService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.file = this.data.file;

        if (this.data.previewImageData) {
            this.previewImageData = this.data.previewImageData;
            this.previewImage = Helpers.dataURLtoFile(this.previewImageData, `preview-${this.data.name.slice(0, -4)}.jpeg`);
        }

        if (this.data.previewTimeOffset) {
            if (this.data.previewTimeOffset === 'undefined') {
                this.data.previewTimeOffset = 0;
            }

            this.currentTime = this.data.previewTimeOffset as number;
        }

        this.videoTag.nativeElement.setAttribute('crossOrigin', 'anonymous');

        this.videoTag.nativeElement.setAttribute('src', URL.createObjectURL(this.file));

        this.videoTag.nativeElement.load();

        this._ctx = this.canvas.nativeElement.getContext('2d');
    }

    uploadFile() {
        this.saveFile();
    }

    /**
     * Loaded video meta data
     *
     * @param {Event} event
     */
    loadedMetaData(event: Event) {
        this.canvas.nativeElement.width = this.videoTag.nativeElement.videoWidth;
        this.canvas.nativeElement.height = this.videoTag.nativeElement.videoHeight;

        this.videoLength = this.videoTag.nativeElement.duration;
    }

    saveFile() {
        const data = {
            previewFile: this.previewImage,
            previewImageData: this.previewImageData
        };

        if (this.is360.checked) {
            data['is360'] = 1;
        }

        if (this.currentTime) {
            data['previewTimeOffset'] = this.currentTime;
        }

        this.dialogRef.close(data);
    }
    renderPreviewImage() {
        this._ctx.drawImage(this.videoTag.nativeElement, 0, 0, this.videoTag.nativeElement.videoWidth, this.videoTag.nativeElement.videoHeight);

        this.previewImageData = this.canvas.nativeElement.toDataURL();
        this.previewImage = Helpers.dataURLtoFile(this.canvas.nativeElement.toDataURL(), `preview-${this.data.name.slice(0, -4)}.png`);
    }
    // renderPreviewImage() {
    //     this._ctx.drawImage(this.videoTag.nativeElement, 0, 0, this.videoTag.nativeElement.videoWidth, this.videoTag.nativeElement.videoHeight);

    //     // this.previewImageData = this.canvas.nativeElement.toDataURL();
    //     // this.previewImage = Helpers.dataURLtoFile(this.canvas.nativeElement.toDataURL(), `preview-${this.data.name.slice(0, -4)}.png`);
    //     const canvasc = document.createElement('canvas');
    //     const originalWidth = this.videoTag.nativeElement.videoWidth;
    //     const originalHeight = this.videoTag.nativeElement.videoHeight;
    //     const targetWidth = 365;
    //     const targetHeight = (originalHeight / originalWidth) * targetWidth;
    //    canvasc.width = targetWidth;canvasc.height = targetHeight;
    //     const ctxc = canvasc.getContext('2d');ctxc.drawImage(this.canvas.nativeElement, 0, 0, originalWidth, originalHeight, 0, 0, targetWidth, targetHeight);
    //     this.previewImageData = canvasc.toDataURL();
    //     this.previewImage = Helpers.dataURLtoFile(this.previewImageData, `preview-${this.data.name.slice(0, -4)}.jpeg`);
    // }

    @Debounce()
    timeChangeNumberInput(event) {
        this.currentTime = event.target.value;
    }

    setVideoTime(time) {
        this.currentTime = time;
    }

    canPlay() {
        this.renderPreviewButtonDisabled = false;
    }

    get currentTime(): number {
        return this._currentTime;
    }

    set currentTime(value: number) {
        this._currentTime = Number(value);

        this.renderPreviewButtonDisabled = true;
        this.videoTag.nativeElement.currentTime = this.currentTime;
    }

    set videoLength(value: number) {
        this._videoLength = Math.floor(value);
    }

    get videoLength(): number {
        return this._videoLength;
    }

    get languageService() {
        return LanguageService;
    }
}
