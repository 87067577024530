import {ModelAbstract} from '../../services/model.abstract';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Token} from '../../services/token';
import {Helpers} from '../../services/helpers';
import {LoggedUser} from '../../services/logged-user';
import {MatDialog} from '@angular/material/dialog';
import {Subject} from '~/node_modules/rxjs';

@Injectable({providedIn: 'root'})
export class SocialSiteService extends ModelAbstract {
    public socialSiteDripdownChangeSubject: Subject<any> = new Subject<any>();
    public socialSites;
    public lastUsedFilters;

    constructor(
        public http: HttpClient,
        public dialog: MatDialog
    ) {
        super(http, dialog);

        this.apiLink = '/api/organization/social-sites/';

        this.socialSiteDripdownChangeSubject.subscribe(() => {
            // do nothing
        });
    }

    getAll(filters: any = {}): Promise<any> {
        const options = Helpers.getBaseHttpHeaders(Token.getToken());

        this.lastUsedFilters = filters;
        if (!filters['organizationID']) {
                    filters['organizationID'] = [];
                    LoggedUser.getUser().organizations.map(function (item) {
                        filters['organizationID'].push(item.organizationID);
                    });
                }
                options['params'] = this.getParams(filters);
        return super.getAll(filters).then(response => {
            this.socialSites = response['socialSites'];
                    return Promise.resolve(response);
            
        });
    }

    // getAll(filters: any = {}): Promise<any> {
    //     const options = Helpers.getBaseHttpHeaders(Token.getToken());

    //     this.lastUsedFilters = filters;

    //     if (!filters['organizationID']) {
    //         filters['organizationID'] = [];
    //         LoggedUser.getUser().organizations.map(function (item) {
    //             filters['organizationID'].push(item.organizationID);
    //         });
    //     }

    //     options['params'] = this.getParams(filters);

    //     return this.http.get(this.apiLink, options).toPromise().then(res => {
    //         this.socialSites = res['socialSites'];
    //         return Promise.resolve(res);
    //     });
    // }
}
