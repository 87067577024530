import {MetricConfigs} from '~/src/app/modules/analytics/widget/metric-configs/metric.config';
import {BaseChartConfig} from '~/src/app/modules/analytics/widget/metric-configs/_base-chart-config';
import {WidgetMetricOptionType} from '~/src/app/modules/analytics/widget/widget.interfaces';
import {
    Country,
    Industry,
    JobFunction
} from '~/src/app/modules/social-media-post/news-feed-targeting-linkedin/news-feed-targeting-linkedin.interfaces';
import Utils from '~/src/app/core/utils';
import {MetricConfigHelper} from '~/src/app/modules/analytics/widget/metric-configs/metric-config.helper';
import {StaffCountRanges} from '~/src/app/modules/social-media-post/news-feed-targeting-linkedin/news-feed-targeting-linkedin.constants';

export const WIDGET_METRIC_OPTION_LINKEDIN_PAGE_VIEWS_OVERVIEW: WidgetMetricOptionType = {
    id: 'linkedin-page-views-overview',
    name: 'Page Views Overview',
    resource: 'analytics.analytics.pageViews'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_PAGE_VIEWS_BY_CATEGORIES: WidgetMetricOptionType = {
    id: 'linkedin-page-views-by-categories',
    name: 'Page Views by Categories',
    resource: 'analytics.analytics.pageViews'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_PAGE_VIEWS_BY_COUNTRY: WidgetMetricOptionType = {
    id: 'linkedin-page-views-by-country',
    name: 'Page Views by Country',
    resource: 'analytics.analytics.pageViews'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_PAGE_VIEWS_BY_FUNCTION: WidgetMetricOptionType = {
    id: 'linkedin-page-views-by-function',
    name: 'Page Views by Function',
    resource: 'analytics.analytics.pageViews'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_PAGE_VIEWS_BY_INDUSTRY: WidgetMetricOptionType = {
    id: 'linkedin-page-views-by-industry',
    name: 'Page Views by Industry',
    resource: 'analytics.analytics.pageViews'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_PAGE_VIEWS_BY_REGION: WidgetMetricOptionType = {
    id: 'linkedin-page-views-by-region',
    name: 'Page Views by Region',
    resource: 'analytics.analytics.pageViews'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_PAGE_VIEWS_BY_SENIORITY: WidgetMetricOptionType = {
    id: 'linkedin-page-views-by-seniority',
    name: 'Page Views by Seniority',
    resource: 'analytics.analytics.pageViews'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_PAGE_VIEWS_BY_STAFF_COUNT_RANGE: WidgetMetricOptionType = {
    id: 'linkedin-page-views-by-staff-count-range',
    name: 'Page Views by Staff Count Range',
    resource: 'analytics.analytics.pageViews'
};

export const WIDGET_METRIC_OPTION_LINKEDIN_PAGE_VIEWS_BY_PLATFORM: WidgetMetricOptionType = {
    id: 'linkedin-page-views-by-platform',
    name: 'Page Views by Platform',
    resource: 'analytics.analytics.pageViews'
};

// METRIC CONFIGS

export const MetricLinkedInPageViewsOverviewConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_PAGE_VIEWS_OVERVIEW.id,
    apiURL: '/page-views',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Page Views Overview',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'overview'
    }
};

export const MetricLinkedInPageViewsByCategoriesConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_PAGE_VIEWS_BY_CATEGORIES.id,
    apiURL: '/page-views',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Page Views by Categories',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'category'
    }
};

export const MetricLinkedInPageViewsByCountryConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_PAGE_VIEWS_BY_COUNTRY.id,
    apiURL: '/page-views',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Page Views by Country (Top 25)',
    extensionType: 'country',
    hydrateResponse: (response: any) => {
        const pageStatisticsByGeoCountry = response.analytics.pageStatisticsByGeoCountry;

        response.analytics = {
            pageStatisticsByGeoCountry
        };

        return response;
    },
    getDatasets: (analytics: { pageStatisticsByGeoCountry: any }, extensionData: Country[]) => {
        return MetricConfigHelper.generateChartDataForByCharts(
            analytics,
            extensionData,
            'pageStatisticsByGeoCountry',
            'analytics.metrics.linkedin.pageViewsByCategories.'
        );
    },
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: null,
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        type: 'linear'
                    }]
                },
            }
        }
    ),
    defaultFilters: {
        type: 'category'
    }
};

export const MetricLinkedInPageViewsByFunctionConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_PAGE_VIEWS_BY_FUNCTION.id,
    apiURL: '/page-views',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Page Views by Function (Top 25)',
    extensionType: 'function',
    hydrateResponse: (response: any) => {
        const pageStatisticsByFunction = response.analytics.pageStatisticsByFunction;

        response.analytics = {
            pageStatisticsByFunction
        };

        return response;
    },
    getDatasets: (analytics: { pageStatisticsByFunction: any }, extensionData: JobFunction[]) => {
        return MetricConfigHelper.generateChartDataForByCharts(
            analytics,
            extensionData,
            'pageStatisticsByFunction',
            'analytics.metrics.linkedin.pageViewsByCategories.'
        );
    },
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: null,
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        type: 'linear'
                    }]
                },
            }
        }
    ),
    defaultFilters: {
        type: 'category'
    }
};

export const MetricLinkedInPageViewsByIndustryConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_PAGE_VIEWS_BY_INDUSTRY.id,
    apiURL: '/page-views',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Page Views by Industry (Top 25)',
    extensionType: 'industry',
    hydrateResponse: (response: any) => {
        const pageStatisticsByIndustry = response.analytics.pageStatisticsByIndustry;

        response.analytics = {
            pageStatisticsByIndustry
        };

        return response;
    },
    getDatasets: (analytics: { pageStatisticsByIndustry: any }, extensionData: Industry[]) => {
        return MetricConfigHelper.generateChartDataForByCharts(
            analytics,
            extensionData,
            'pageStatisticsByIndustry',
            'analytics.metrics.linkedin.pageViewsByCategories.'
        );
    },
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: null,
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        type: 'linear'
                    }]
                },
            }
        }
    ),
    defaultFilters: {
        type: 'category'
    }
};

export const MetricLinkedInPageViewsByRegionConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_PAGE_VIEWS_BY_REGION.id,
    apiURL: '/page-views',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Page Views by Region (Top 25)',
    extensionType: 'region',
    hydrateResponse: (response: any) => {
        const pageStatisticsByGeo = response.analytics.pageStatisticsByGeo;

        response.analytics = {
            pageStatisticsByGeo
        };

        return response;
    },
    getDatasets: (analytics: { pageStatisticsByGeo: any }, extensionData: Industry[]) => {
        return MetricConfigHelper.generateChartDataForByCharts(
            analytics,
            extensionData,
            'pageStatisticsByGeo',
            'analytics.metrics.linkedin.pageViewsByCategories.'
        );
    },
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: null,
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        type: 'linear'
                    }]
                },
            }
        }
    ),
    defaultFilters: {
        type: 'category'
    }
};

export const MetricLinkedInPageViewsBySeniorityConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_PAGE_VIEWS_BY_SENIORITY.id,
    apiURL: '/page-views',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Page Views by Seniority',
    extensionType: 'seniority',
    hydrateResponse: (response: any) => {
        const pageStatisticsBySeniority = response.analytics.pageStatisticsBySeniority;

        response.analytics = {
            pageStatisticsBySeniority
        };

        return response;
    },
    getDatasets: (analytics: { pageStatisticsBySeniority: any }, extensionData: Industry[]) => {
        return MetricConfigHelper.generateChartDataForByCharts(
            analytics,
            extensionData,
            'pageStatisticsBySeniority',
            'analytics.metrics.linkedin.pageViewsByCategories.'
        );
    },
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: null,
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        type: 'linear'
                    }]
                },
            }
        }
    ),
    defaultFilters: {
        type: 'category'
    }
};

export const MetricLinkedInPageViewsByStaffCountRangeConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_PAGE_VIEWS_BY_STAFF_COUNT_RANGE.id,
    apiURL: '/page-views',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Page Views by Staff Count Range',
    getDatasets: (analytics: { pageStatisticsByStaffCountRange: any }) => {
        return MetricConfigHelper.generateChartDataForByCharts(
            analytics,
            (Utils.lodash.cloneDeep(StaffCountRanges) as any[]).map(item => {
                item.id = item.value;

                delete item.value;

                return item;
            }),
            'pageStatisticsByStaffCountRange',
            'analytics.metrics.linkedin.pageViewsByCategories.'
        );
    },
    chartConfig: Utils.lodash.merge(
        Utils.lodash.cloneDeep(BaseChartConfig),
        {
            type: 'bar',
            options: {
                scales: {
                    xAxes: [{
                        type: 'category',
                        time: null,
                        stacked: true,
                    }],
                    yAxes: [{
                        stacked: true,
                        type: 'linear'
                    }]
                },
            }
        }
    ),
    defaultFilters: {
        type: 'category'
    }
};

export const MetricLinkedInPageViewsByPlatformConfig: MetricConfigs = {
    metricID: WIDGET_METRIC_OPTION_LINKEDIN_PAGE_VIEWS_BY_PLATFORM.id,
    apiURL: '/page-views',
    socialType: 'linkedIn',
    contentType: 'chart',
    dataName: 'Page Views by Platform',
    chartConfig: {
        ...BaseChartConfig
    },
    defaultFilters: {
        type: 'byPlatform'
    }
};
