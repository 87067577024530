import { Injectable } from "@angular/core";
import { BackendService } from "~/src/app/core/backend.service";
import { LocalStorage } from "~/src/app/core/local-storage";
import Utils from "~/src/app/core/utils";
import { HttpClient } from "@angular/common/http";

export const TWITTER_OAUTH_CALLBACK_KEY = "__twitterOauthCallback";
export const CONSUMER_KEY = "u1bC7FZegqGkhnlqsyTgcBS9N";
export const OAUTH_CALLBACK = "/oauth/twitter";
export const TWITTER_AUTHORIZE_URL = "https://api.x.com/oauth/authorize";
const STORE_KEY = "twitterAuth";

export interface StoreData {
    oauth_token?: string;
    oauth_verifier?: string;
    oauth_token_secret?: string;
    successAuth?: boolean;
}

export interface ExchangeTokenResponse {
    twitterToken: {
        oauth_token: string;
        oauth_token_secret: string;
        user_id: string;
        screen_name: string;
    };
}

export interface AuthenticateCallbackData extends ExchangeTokenResponse {
    successAuth: boolean;
    message: string;
    [key: string]: any;
}

@Injectable({ providedIn: "root" })
export class TwitterAPI {
    oauthToken: string = null;
    oauthTokenSecret: string = null;
    oauthVerifier: string = null;

    constructor(private backend: BackendService, private http: HttpClient) {}

    /**
     * @param {(data: AuthenticateCallbackData) => void} callback
     * @return {this}
     */
    authenticate(callback: (data: AuthenticateCallbackData) => void) {
        window[TWITTER_OAUTH_CALLBACK_KEY] = (data) => {
            callback(data);
        };

        const queryStr = "?init=true";

        // open new window (not tab)
        window.open(
            OAUTH_CALLBACK + queryStr,
            null,
            "location=yes,height=570,width=520,scrollbars=no,status=no"
        );

        return this;
    }

    /**
     * @description step 1
     * @link https://developer.twitter.com/en/docs/basics/authentication/oauth-1-0a/obtaining-user-access-tokens
     * @return {Promise<*>}
     */
    requestToken() {
        return this.backend
            .get("/social/init-twitter")
            .then((response: any) => {
                const data = response.twitterInit;

                this.oauthToken = data.oauth_token;
                this.oauthTokenSecret = data.oauth_token_secret;

                this.storeData({
                    oauth_token: this.oauthToken,
                    oauth_token_secret: this.oauthTokenSecret,
                });

                return Promise.resolve(response);
            });
    }

    /**
     * @description step 2
     * @link https://developer.twitter.com/en/docs/basics/authentication/oauth-1-0a/obtaining-user-access-tokens
     */
    authorize() {
        const params = {
            oauth_token: this.oauthToken,
            force_login: true,
        };

        window.location.href =
            TWITTER_AUTHORIZE_URL + "?" + Utils.queryString.stringify(params);
    }

    /**
     * @description step 3
     * @link https://developer.twitter.com/en/docs/basics/authentication/oauth-1-0a/obtaining-user-access-tokens
     * @return {Promise<ExchangeTokenResponse>}
     */
    getAccessToken(): Promise<ExchangeTokenResponse> {
        return this.backend.post("/social/exchange-access-token", {
            oauthToken: this.oauthToken,
            oauthVerifier: this.oauthVerifier,
        });
    }

    /**
     * Store token in local storage
     *
     * @return {this}
     */
    storeData(data: StoreData = {}) {
        data = {
            ...(this.getData() || {}),
            ...data,
        };

        this._setClassVariables(data);

        LocalStorage.set(STORE_KEY, data);

        return this;
    }

    /**
     * @return {StoreData}
     */
    getData(): StoreData {
        const data = LocalStorage.get(STORE_KEY) || {};

        this._setClassVariables(data);

        return data;
    }

    /**
     * @param {StoreData} data
     * @private
     */
    _setClassVariables(data: StoreData): void {
        if (data.oauth_token) {
            this.oauthToken = data.oauth_token;
        }

        if (data.oauth_verifier) {
            this.oauthVerifier = data.oauth_verifier;
        }

        if (data.oauth_token_secret) {
            this.oauthTokenSecret = data.oauth_token_secret;
        }
    }
}
